import { Box, Typography } from "@mui/material";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { IInvoiceProps } from "../../types";

export const VBillEmailFrom: FunctionComponent<IInvoiceProps> = ({ invoice }) => {
  const { emailDate, emailSubject, initialSenderEmail, initialSenderName } = invoice;

  return (
    <Box sx={{ marginBottom: "20px" }}>
      {(initialSenderName || initialSenderEmail || emailDate || emailSubject) && (
        <>
          <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
            RECEIVED FROM:
          </Typography>

          <Typography sx={{ wordWrap: "break-word" }}>
            {initialSenderName ?? "-"}, {initialSenderEmail ?? "-"} on {getVBillFormattedDate(emailDate)}, with subject:
            "{emailSubject ?? "-"}"
          </Typography>
        </>
      )}
    </Box>
  );
};
