import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { IInvoiceProps } from "../../types";

export const VBillToDetails: FunctionComponent<IInvoiceProps> = ({ invoice }) => {
  if (!invoice?.company) {
    return null;
  }

  const { company } = invoice;

  return (
    <Box sx={{ marginBottom: "20px" }}>
      <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
        Bill to:
      </Typography>
      {company?.orgName && <Typography>Organization: {company.orgName}</Typography>}
      {company?.name && <Typography>Company: {company.name}</Typography>}
    </Box>
  );
};
