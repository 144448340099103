import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { FormInputDate } from "../Forms/FormInputDate/FormInputDate";
import { FormInputText } from "../Forms/FormInputText/FormInputText";

interface IVBillPaymentProps {
  insideForm?: boolean;
}

export const VBillPayment: FunctionComponent<IVBillPaymentProps> = ({ insideForm }) => {
  return (
    <Box
      sx={{
        width: !insideForm ? "calc(33.3% - 27.5px)" : "100%",
        marginBottom: !insideForm ? 0 : "20px",
      }}
    >
      <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
        PAYMENT:
      </Typography>
      <Box
        sx={{
          display: "flex",
          ...(!insideForm ? { flexWrap: "wrap" } : { gap: "20px" }),
        }}
      >
        <Box sx={{ width: insideForm ? "calc(33.3% - 13.5px)" : "100%" }}>
          <FormInputText
            name="invoiceNumber"
            label="Bill#"
            rules={{ required: "Bill# is required" }}
            insideForm={insideForm}
            pushedTop
          />
        </Box>

        <Box sx={{ width: insideForm ? "calc(33.3% - 13.5px)" : "100%" }}>
          <FormInputDate
            name="invoiceDate"
            label="Date Issued:"
            rules={{
              required: "Date Issued is required",
              // validate: (value) => isValid(new Date(value)) || "Invalid Date",
            }}
            insideForm={insideForm}
            pushedTop
          />
        </Box>

        <Box sx={{ width: insideForm ? "calc(33.3% - 13.5px)" : "100%" }}>
          <FormInputDate
            name="invoiceDateDue"
            label="Bill Due:"
            rules={{
              required: "Bill Due is required",
              // validate: (value) => isValid(new Date(value)) || "Invalid Date",
            }}
            insideForm={insideForm}
            pushedTop
          />
        </Box>
      </Box>
    </Box>
  );
};
