import { Box } from "@mui/material";
import { IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { FunctionComponent } from "react";
import { FileItem } from "./FileItem/FileItem";

export type TVBillFileInfo = NonNullable<IVBillVBillInvoiceQuery["invoice"]["files"]>[number];

interface IFilesSelectorProps {
  files: IVBillVBillInvoiceQuery["invoice"]["files"];
  selectedFile: TVBillFileInfo;
  onChangeFileBtnClick: (file: TVBillFileInfo) => void;
  reloadData?: () => any;
  canMoveAttachmentsToNewBill: boolean;
}

export const FilesSelector: FunctionComponent<IFilesSelectorProps> = ({
  files,
  selectedFile,
  onChangeFileBtnClick,
  reloadData,
  canMoveAttachmentsToNewBill,
}) => {
  if (!files) {
    return null;
  }

  const allowMoveToNewBill = canMoveAttachmentsToNewBill && files.filter((file) => !file.movedToInvoice).length > 1;

  return (
    <Box sx={{ marginBottom: "20px", display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {files.map((file) => (
        <FileItem
          key={file.id}
          file={file}
          selected={file.id === selectedFile?.id}
          onChangeFileBtnClick={onChangeFileBtnClick}
          allowMoveToNewBill={allowMoveToNewBill}
          reloadData={reloadData}
        />
      ))}
    </Box>
  );
};
