import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { Collapse, IconButton, Menu, MenuItem, SxProps, Theme, Tooltip } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { Decimal } from "common/helpers/decimal";
import {
  IVBillAddEditBatchSubmitPayload,
  VBillAddEditBatchDialog,
} from "components/pages/common/VBill/VBillAddEditBatchDialog/VBillAddEditBatchDialog";
import { MoveInsideAnoterBatchDialog } from "components/pages/VBillBatchDetails/common/BatchLineMenu/MoveInsideAnoterBatchDialog/MoveInsideAnoterBatchDialog";
import { BatchMembersDialog } from "components/pages/VBillBatchDetails/common/BatchMembersDialog/BatchMembersDialog";
import { BundleFilters } from "components/pages/VBillBatchDetails/common/BundleFilters/BundleFilters";
import { ConfirmDeleteBatchDialog } from "components/pages/VBillBatchDetails/common/ConfirmDeleteBatchDialog/ConfirmDeleteBatchDialog";
import {
  IVBillBillsFiltersInput,
  IVBillVBillGetBatchListQuery,
  IVBillVBillGetBillsQuery,
} from "generated/graphql.vbill";
import { getSdk, IVBillVBillGetBatchMembersQuery } from "generated/sdk.vbill";
import { pick } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ConfirmBatchActionDialog } from "../ConfirmBatchActionDialog/ConfirmBatchActionDialog";

const {
  VBillGetBatchMembers,
  VBillAddMembersToBatch,
  VBillRemoveMembersFromBatch,
  VBillGetBills,
  VBillUpdateBatch,
  VBillRemoveInvoicesFromBatch,
} = getSdk(graphqlVBillClient);

interface IBatchLineMenuProps {
  batch?: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
  showFiltersOption?: boolean;
  onAddEditBatchSubmit?: () => void;
  onFiltersChange?: (batchFilters: IVBillBillsFiltersInput) => Promise<void>;
  onConfirmDeleteBatchSubmit?: () => void;
  onMembersChange?: () => void;
  onRemoveBillsSubmit?: () => void;
  menuIconSx?: SxProps<Theme>;
}

export const BatchLineMenu = ({
  batch,
  showFiltersOption,
  onAddEditBatchSubmit,
  onFiltersChange,
  onConfirmDeleteBatchSubmit,
  onMembersChange,
  onRemoveBillsSubmit,
  menuIconSx,
}: IBatchLineMenuProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [moveBatchSubmenuOpen, setMoveBatchSubmenuOpen] = useState(false);
  const [isMembersDialogOpen, setIsMembersDialogOpen] = useState(false);
  const [isEditBatchDialogOpen, setIsEditBatchDialogOpen] = useState(false);
  const [isMoveInsideAnotherBatchDialogOpen, setIsMoveInsideAnotherBatchDialogOpen] = useState(false);
  const [isMoveAtTopLevelBatchDialogOpen, setIsMoveAtTopLevelBatchDialogOpen] = useState(false);
  const [isDeleteBatchDialogOpen, setIsDeleteBatchDialogOpen] = useState(false);
  const [isRemoveBillsDialogOpen, setIsRemoveBillsDialogOpen] = useState(false);
  const [batchMembers, setBatchMembers] = useState<
    NonNullable<IVBillVBillGetBatchMembersQuery["getBatchMembers"]["data"]>["items"]
  >([]);
  const [batchSelectedVBills, setBatchSelectedVBills] = useState<IVBillVBillGetBillsQuery["getBills"]["items"]>([]);
  const history = useHistory();
  const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);

  const isMoreMenuOpen = Boolean(menuAnchorEl);

  useEffect(() => {
    if (isMoreMenuOpen) {
      VBillGetBatchMembers({ batchId: batch?.id ?? 0 }).then((resp) => {
        if (resp.getBatchMembers.data?.items) {
          setBatchMembers(resp.getBatchMembers.data.items);
        }
      });
    }
  }, [batch?.id, isMoreMenuOpen]);

  useEffect(() => {
    if (isMoreMenuOpen) {
      VBillGetBills({ pagination: { page: 1 }, filters: { organizationId, batch_id_in: [batch?.id ?? 0] } }).then(
        (resp) => {
          if (resp.getBills.items?.length) {
            setBatchSelectedVBills(resp.getBills.items);
          }
        },
      );
    }
  }, [batch?.id, isMoreMenuOpen, organizationId]);

  const batchMemberIds = useMemo(() => (batchMembers ?? []).map(({ coreOrgUserId }) => coreOrgUserId), [batchMembers]);

  const handleSaveMembers = async (selectedMemberIds: string[]) => {
    if (!batch) {
      return;
    }

    const addMembers = selectedMemberIds.filter(
      (selectedMemberId) => !batchMemberIds.find((memberId) => memberId === selectedMemberId),
    );
    const removeMembers = batchMemberIds.filter(
      (memberId) => !selectedMemberIds.find((selectedMemberId) => selectedMemberId === memberId),
    );

    if (addMembers.length) {
      await VBillAddMembersToBatch({ batchId: batch.id, memberIds: addMembers });
    }

    if (removeMembers.length) {
      await VBillRemoveMembersFromBatch({ batchId: batch.id, memberIds: removeMembers });
    }

    if (addMembers.length || removeMembers.length) {
      const getMembersResp = await VBillGetBatchMembers({ batchId: batch.id });

      if (getMembersResp?.getBatchMembers.data?.items) {
        setBatchMembers(getMembersResp.getBatchMembers.data.items);
      }

      onMembersChange?.();
    }

    setIsMembersDialogOpen(false);
  };

  const handleConfirmMoveBatchAsTopLvlSubmit = async () => {
    if (!batch) {
      return;
    }

    await VBillUpdateBatch({
      batchId: Number(batch.id),
      batchData: {
        ...pick(batch, ["batchDateDue", "batchFilters", "budget", "name", "organizationId"]),
        parentBatchId: null,
      },
    });
    setIsMoveAtTopLevelBatchDialogOpen(false);
    history.push(`/org/${organizationId}/vbillbatchledger`);
  };

  const handleConfirmRemoveBillsSubmit = async () => {
    if (!batch) {
      return;
    }

    await VBillRemoveInvoicesFromBatch({
      batchId: Number(batch.id),
      invoiceIds: (batchSelectedVBills ?? []).map(({ id }) => id),
    });
    setIsRemoveBillsDialogOpen(false);
  };

  const handleAddEditBatchSubmit = async ({ name, budget }: IVBillAddEditBatchSubmitPayload) => {
    if (!batch) {
      return;
    }

    await VBillUpdateBatch({
      batchId: batch.id,
      batchData: {
        organizationId,
        name: name ?? "",
        budget: new Decimal(budget ?? 0).toFixed(2),
        batchFilters: { ...batch.batchFilters },
      },
    });
    setIsEditBatchDialogOpen(false);
    onAddEditBatchSubmit?.();
  };

  const handleRemoveBillsCloseDialog = () => {
    setIsRemoveBillsDialogOpen(false);
    onRemoveBillsSubmit?.();
  };

  return (
    <>
      <Tooltip title="Menu" arrow>
        <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)} sx={{ padding: "4px" }}>
          <MenuIcon sx={{ fontSize: "24px", ...menuIconSx }} />
        </IconButton>
      </Tooltip>

      <Menu
        id="batch-line-item-menu"
        anchorEl={menuAnchorEl}
        open={isMoreMenuOpen}
        onClose={() => {
          setMenuAnchorEl(null);
          setMoveBatchSubmenuOpen(false);
        }}
        MenuListProps={{
          "aria-labelledby": "batch-line-item-menu-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setMoveBatchSubmenuOpen(false);
            setMenuAnchorEl(null);
            setIsEditBatchDialogOpen(true);
          }}
        >
          Edit Batch
        </MenuItem>

        {showFiltersOption && (
          <MenuItem
            onClick={() => {
              setMoveBatchSubmenuOpen(false);
              setMenuAnchorEl(null);
              setIsFiltersDialogOpen(true);
            }}
          >
            Edit Filters
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setMoveBatchSubmenuOpen(false);
            setMenuAnchorEl(null);
            setIsMembersDialogOpen(true);
          }}
        >
          Edit Members
        </MenuItem>

        {batchSelectedVBills.length > 0 && (
          <MenuItem
            onClick={() => {
              setMoveBatchSubmenuOpen(false);
              setMenuAnchorEl(null);
              setIsRemoveBillsDialogOpen(true);
            }}
          >
            Remove all added bills
          </MenuItem>
        )}

        <MenuItem
          onClick={() => setMoveBatchSubmenuOpen((prev) => !prev)}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          Move Batch
          {moveBatchSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>

        <Collapse in={moveBatchSubmenuOpen} sx={{ paddingLeft: "10px" }}>
          <MenuItem
            onClick={() => {
              setMoveBatchSubmenuOpen(false);
              setMenuAnchorEl(null);
              setIsMoveAtTopLevelBatchDialogOpen(true);
            }}
          >
            As top level
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMoveBatchSubmenuOpen(false);
              setMenuAnchorEl(null);
              setIsMoveInsideAnotherBatchDialogOpen(true);
            }}
          >
            Inside another batch
          </MenuItem>
        </Collapse>

        <MenuItem
          onClick={() => {
            setMoveBatchSubmenuOpen(false);
            setMenuAnchorEl(null);
            setIsDeleteBatchDialogOpen(true);
          }}
        >
          Delete Batch
        </MenuItem>
      </Menu>

      {isMembersDialogOpen && (
        <BatchMembersDialog
          suggestionsComponent="dialog"
          selectedMembers={batchMemberIds}
          onMembersSave={handleSaveMembers}
          onDialogClose={() => setIsMembersDialogOpen(false)}
        />
      )}

      {isEditBatchDialogOpen && (
        <VBillAddEditBatchDialog
          onSubmit={handleAddEditBatchSubmit}
          batchData={batch}
          onDialogClose={() => setIsEditBatchDialogOpen(false)}
          showBudgetPicker
          showNamePicker
          actionsBtnSmallHeight
        />
      )}

      {isMoveInsideAnotherBatchDialogOpen && batch && (
        <MoveInsideAnoterBatchDialog batch={batch} onCloseDialog={() => setIsMoveInsideAnotherBatchDialogOpen(false)} />
      )}

      {isMoveAtTopLevelBatchDialogOpen && (
        <ConfirmBatchActionDialog
          title="Move batch"
          text="Are you sure you want to move this batch as top level?"
          confirmBtnText="Move batch"
          onCloseDialog={() => setIsMoveAtTopLevelBatchDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={handleConfirmMoveBatchAsTopLvlSubmit}
        />
      )}

      {isDeleteBatchDialogOpen && batch && (
        <ConfirmDeleteBatchDialog
          batchId={batch.id}
          onCloseDialog={() => setIsDeleteBatchDialogOpen(false)}
          onConfirmDeleteBatchSubmit={onConfirmDeleteBatchSubmit}
        />
      )}

      {showFiltersOption && onFiltersChange && batch && isFiltersDialogOpen && (
        <BundleFilters
          onFiltersChange={onFiltersChange}
          filters={batch.batchFilters}
          onCloseDialog={() => setIsFiltersDialogOpen(false)}
        />
      )}

      {isRemoveBillsDialogOpen && (
        <ConfirmBatchActionDialog
          title="Remove added bills"
          text="Are you sure you want to remove added bills?"
          confirmBtnText="Remove bills"
          onCloseDialog={() => setIsRemoveBillsDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={handleConfirmRemoveBillsSubmit}
        />
      )}
    </>
  );
};
