import { Box, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useStore } from "storeContainer";
import { VBillEmailFrom } from "../common/VBillEmailFrom/VBillEmailFrom";
import { VBillFrom } from "../common/VBillFrom/VBillFrom";
import { VBillPayment } from "../common/VBillPayment/VBillPayment";
import { VBillToDetails } from "../common/VBillToDetails/VBillToDetails";
import { IncomingLineItems } from "./IncomingLineItems/IncomingLineItems";

export const VBillIncoming: FunctionComponent = observer(() => {
  const vBillStore = useStore("VBillStore");

  if (!vBillStore.invoice.data) {
    return null;
  }

  const invoiceData = vBillStore.invoice.data;

  return (
    <>
      <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <Box sx={{ width: "calc(50% - 20.5px)" }}>
          <VBillEmailFrom invoice={invoiceData} />
          <VBillToDetails invoice={invoiceData} />
        </Box>

        <Divider flexItem orientation="vertical" />

        <VBillFrom insideForm />
      </Box>

      <VBillPayment insideForm />

      <IncomingLineItems />
    </>
  );
});
