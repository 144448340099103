import { Box, InputProps, SxProps, TextField, Theme, Typography } from "@mui/material";
import { IVReimbursementFormFields } from "components/pages/VReimbursement/types";
import { FunctionComponent } from "react";
import { ControllerProps, useController } from "react-hook-form";

interface IFormInputTextProps {
  name: keyof IVReimbursementFormFields;
  sx?: SxProps<Theme>;
  InputProps?: Partial<InputProps>;
  rules?: ControllerProps<IVReimbursementFormFields>["rules"];
  label: string;
}

export const FormInputText: FunctionComponent<IFormInputTextProps> = ({ name, sx, InputProps, rules, label }) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<IVReimbursementFormFields>({ name, rules });

  return (
    <Box sx={{ marginBottom: "15px", ...sx }}>
      <Typography sx={{ marginBottom: "5px" }}>{label}</Typography>

      <TextField
        error={!!error}
        onChange={(e) => onChange(e.target.value)}
        value={value ?? ""}
        sx={{ width: "100%" }}
        InputProps={{ sx: { height: "32px" }, ...InputProps }}
        // label={label}
        helperText={error?.message}
      />
    </Box>
  );
};
