import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Divider, IconButton, InputAdornment, Popover, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { processJsDateToIsoOnMidnight } from "common/helpers/dates";
import { isValid } from "date-fns";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { useEffect } from "react";
import { useSetState } from "react-use";

export type DateValues = Pick<IVBillBillsFiltersInput, "date" | "date_to">;

interface IDateFilterPopoverProps {
  onDateChange: (values: DateValues) => void;
  date: DateValues;
  popoverId?: string;
  popoverAnchorEl: null | HTMLElement;
  onClosePopover: () => void;
  actionsBtnSmallHeight?: boolean;
}

export const DateFilterPopover = ({
  popoverId,
  popoverAnchorEl,
  onClosePopover,
  actionsBtnSmallHeight,
  onDateChange,
  date,
}: IDateFilterPopoverProps) => {
  const [localMuiDates, setLocalMuiDates] = useSetState<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null,
  });

  useEffect(() => {
    setLocalMuiDates({
      start: isNil(date.date) ? null : new Date(date.date ?? ""),
      end: isNil(date.date_to) ? null : new Date(date.date_to ?? ""),
    });
  }, [date.date, date.date_to, setLocalMuiDates]);

  const handleSaveDialog = async () => {
    onDateChange({
      date:
        localMuiDates.start && isValid(localMuiDates.start)
          ? processJsDateToIsoOnMidnight(localMuiDates.start.toString())
          : undefined,
      date_to:
        localMuiDates.end && isValid(localMuiDates.end)
          ? processJsDateToIsoOnMidnight(localMuiDates.end.toString())
          : undefined,
    });
    onClosePopover();
  };

  const saveBtnDisabled =
    (isNil(localMuiDates.start) ? false : !isValid(localMuiDates.start)) ||
    (isNil(localMuiDates.end) ? false : !isValid(localMuiDates.end));

  return (
    <Popover
      transitionDuration={0}
      id={popoverId}
      open
      anchorEl={popoverAnchorEl}
      onClose={onClosePopover}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Box sx={{ padding: "20px 10px 10px" }}>
        {/* <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Select VBill date</Typography> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveDialog();
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start date:"
                value={localMuiDates.start}
                onChange={(date) => setLocalMuiDates({ start: date })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ width: "100%" }}
                    {...(isValid(localMuiDates.start) && {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Clear" arrow>
                              <IconButton
                                onClick={() => setLocalMuiDates({ start: null })}
                                sx={{ marginRight: "-12px" }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      },
                    })}
                  />
                )}
              />

              <DatePicker
                label="End date:"
                value={localMuiDates.end}
                onChange={(date) => setLocalMuiDates({ end: date })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{ width: "100%" }}
                    {...(isValid(localMuiDates.end) && {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Clear" arrow>
                              <IconButton onClick={() => setLocalMuiDates({ end: null })} sx={{ marginRight: "-12px" }}>
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      },
                    })}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Divider sx={{ margin: "20px 0" }} />

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Button
              disabled={saveBtnDisabled}
              variant="contained"
              type="submit"
              sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClosePopover}
              sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Popover>
  );
};

export const getSODAsUTCDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
};
