import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  IconButton,
  InputAdornment,
  InputProps,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { processJsDateToIsoOnMidnight } from "common/helpers/dates";
import { IVReimbursementFormFields } from "components/pages/VReimbursement/types";
import { isValid } from "date-fns";
import { isNil } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { ControllerProps, useController, useFormContext } from "react-hook-form";

interface IFormInputDateProps {
  name: keyof IVReimbursementFormFields;
  sx?: SxProps<Theme>;
  InputProps?: Partial<InputProps>; // TODO:
  rules?: ControllerProps<IVReimbursementFormFields>["rules"];
  label: string;
}

export const FormInputDate: FunctionComponent<IFormInputDateProps> = ({ name, sx, InputProps, rules, label }) => {
  const formMethods = useFormContext<IVReimbursementFormFields>();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<IVReimbursementFormFields>({
    name,
    rules,
  });
  const [localMuiDate, setLocalMuiDate] = useState<Date | null>(null);
  const [localMuiTextFieldValue, setLocalMuiTextFieldValue] = useState("");

  useEffect(() => {
    const muiDate = (value as string | undefined)?.length === 0 ? null : new Date(value as string);

    setLocalMuiDate(muiDate);
  }, [value]);

  useEffect(() => {
    if (isNil(localMuiDate)) {
      onChange("");

      return;
    }

    if (isValid(localMuiDate) && localMuiTextFieldValue.length === 10) {
      const fieldValue = processJsDateToIsoOnMidnight(localMuiDate.toString());

      onChange(fieldValue);
      formMethods.clearErrors(name);

      return;
    }

    formMethods.setError(name, { type: "custom", message: "Invalid Date" });
  }, [localMuiDate, localMuiTextFieldValue]);

  const hasFieldError = !!error ? true : isValid(localMuiDate);

  return (
    <Box sx={{ marginBottom: "15px", ...sx }}>
      <Typography sx={{ marginBottom: "5px" }}>{label}</Typography>

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={localMuiDate}
          onChange={setLocalMuiDate}
          renderInput={(params) => {
            setLocalMuiTextFieldValue((params?.inputProps?.value as string) ?? "");

            return (
              <TextField
                {...params}
                sx={{ width: "100%" }}
                helperText={error?.message}
                FormHelperTextProps={{ sx: { margin: 0 } }}
                InputProps={{
                  sx: { height: "32px" },
                  ...(!hasFieldError && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Clear" arrow>
                          <IconButton onClick={() => setLocalMuiDate(null)} sx={{ marginRight: "-12px" }}>
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }),
                  ...params.InputProps,
                  ...InputProps,
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
