import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getSODAsUTCIsoString } from "common/helpers/utils";
import { CloseIcon } from "components/common/icons";
import { addDays, addMonths, addWeeks, addYears, differenceInDays, formatDistanceStrict } from "date-fns";
import { IVBillBatchDateUnitType, IVBillBillsFiltersOutput, IVBillTemporality } from "generated/sdk.vbill";
import { useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useSetState } from "react-use";
import { getVBillFormattedDate } from "../utils";

export const displayBatchDateUnitList: IVBillBatchDateUnitType[] = [
  // enum list with order
  IVBillBatchDateUnitType.Day,
  IVBillBatchDateUnitType.Week,
  IVBillBatchDateUnitType.Month,
  IVBillBatchDateUnitType.Year,
];

export interface IVBillRelativeDatesSelectorProps {
  title?: string;
  noCurrentDateText?: string;
  onCloseDialog: () => void;
  dates?: Pick<IVBillBillsFiltersOutput, "relativeReferenceDate" | "relativeDateDueFrom" | "relativeDateDueTo"> | null;
  onDatesSubmit: (dates: IVBillRelativeDatesSelectorProps["dates"]) => Promise<void>;
  enableCurrentDateSelector?: boolean;
  actionsBtnSmallHeight?: boolean;
}

export const VBillRelativeDatesSelector = ({
  title,
  noCurrentDateText,
  onCloseDialog,
  dates,
  onDatesSubmit,
  enableCurrentDateSelector,
  actionsBtnSmallHeight,
}: IVBillRelativeDatesSelectorProps) => {
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const [selectedDateUnitType, setSelectedDateUnitType] = useState<IVBillBatchDateUnitType>(
    IVBillBatchDateUnitType.Day,
  );

  const [localRelativeReferenceDate, setLocalRelativeReferenceDate] = useState<string | null>(null); // will use relativeReferenceDate.customDate
  const [fromValues, setFromValues] = useSetState<{
    value: string | null;
    temporality: IVBillTemporality;
    computedOutputDate: string | null /* date string */;
  }>({
    value: null,
    temporality: IVBillTemporality.Before,
    computedOutputDate: null,
  });
  const [toValues, setToValues] = useSetState<{
    value: string | null;
    temporality: IVBillTemporality;
    computedOutputDate: string | null /* date string */;
  }>({
    value: null,
    temporality: IVBillTemporality.After,
    computedOutputDate: null,
  });

  useEffect(() => {
    setFromValues({ value: null, temporality: IVBillTemporality.Before, computedOutputDate: null });
    setToValues({ value: null, temporality: IVBillTemporality.After, computedOutputDate: null });
  }, [selectedDateUnitType, setFromValues, setToValues]);

  useEffect(() => {
    const newLocalRelativeReferenceDate = new Date(
      dates?.relativeReferenceDate?.customDate ? dates.relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const newSelectedDateUnitType = dates?.relativeDateDueFrom?.unit
      ? dates.relativeDateDueFrom.unit
      : IVBillBatchDateUnitType.Day;

    setLocalRelativeReferenceDate(newLocalRelativeReferenceDate);
    setSelectedDateUnitType(newSelectedDateUnitType);

    if (dates?.relativeDateDueFrom) {
      const value = `${dates?.relativeDateDueFrom.value}`;
      const temporality = dates?.relativeDateDueFrom.temporality
        ? dates.relativeDateDueFrom.temporality
        : IVBillTemporality.Before;

      setFromValues({
        value,
        computedOutputDate: relatedDatesGetRelativeDate(
          newLocalRelativeReferenceDate,
          value,
          temporality,
          newSelectedDateUnitType,
        ),
        temporality,
      });
    }

    if (dates?.relativeDateDueTo) {
      const value = `${dates?.relativeDateDueTo.value}`;
      const temporality = dates?.relativeDateDueTo.temporality
        ? dates.relativeDateDueTo.temporality
        : IVBillTemporality.Before;

      setToValues({
        value,
        computedOutputDate: relatedDatesGetRelativeDate(
          newLocalRelativeReferenceDate,
          value,
          temporality,
          newSelectedDateUnitType,
        ),
        temporality,
      });
    }
  }, [
    dates?.relativeDateDueFrom,
    dates?.relativeDateDueTo,
    dates?.relativeReferenceDate?.customDate,
    setFromValues,
    setToValues,
  ]);

  useEffect(() => {
    if (localRelativeReferenceDate && fromValues.value?.length) {
      setFromValues({
        computedOutputDate: relatedDatesGetRelativeDate(
          localRelativeReferenceDate,
          fromValues.value,
          fromValues.temporality,
          selectedDateUnitType,
        ),
      });
    }
  }, [fromValues.temporality, fromValues.value, localRelativeReferenceDate, selectedDateUnitType, setFromValues]);

  useEffect(() => {
    if (localRelativeReferenceDate && toValues.value?.length) {
      setToValues({
        computedOutputDate: relatedDatesGetRelativeDate(
          localRelativeReferenceDate,
          toValues.value,
          toValues.temporality,
          selectedDateUnitType,
        ),
      });
    }
  }, [localRelativeReferenceDate, selectedDateUnitType, setToValues, toValues.temporality, toValues.value]);

  const formattedToValueAsDate = useMemo(
    () => (toValues.computedOutputDate ? getVBillFormattedDate(toValues.computedOutputDate) : undefined),
    [toValues.computedOutputDate],
  );
  const formattedToValueAsTimeAgo = useMemo(
    () =>
      localRelativeReferenceDate?.length && toValues.value?.length && toValues.computedOutputDate
        ? formatDistanceStrict(new Date(localRelativeReferenceDate), new Date(toValues.computedOutputDate), {
            unit: "day",
            roundingMethod: "floor",
          })
        : undefined,
    [localRelativeReferenceDate, toValues.computedOutputDate, toValues.value?.length],
  );
  const formattedFromValueAsDate = useMemo(
    () => (fromValues.computedOutputDate ? getVBillFormattedDate(fromValues.computedOutputDate) : undefined),
    [fromValues.computedOutputDate],
  );
  const formattedFromValueAsTimeAgo = useMemo(
    () =>
      localRelativeReferenceDate?.length && fromValues.value?.length && fromValues.computedOutputDate
        ? formatDistanceStrict(new Date(localRelativeReferenceDate), new Date(fromValues.computedOutputDate), {
            unit: "day",
            roundingMethod: "floor",
          })
        : undefined,
    [localRelativeReferenceDate, fromValues.computedOutputDate, fromValues.value?.length],
  );
  const formattedDaysBetween = useMemo(
    () =>
      fromValues.computedOutputDate && toValues.computedOutputDate
        ? formatDistanceStrict(new Date(toValues.computedOutputDate), new Date(fromValues.computedOutputDate), {
            unit: "day",
            roundingMethod: "floor",
          })
        : undefined,
    [fromValues.computedOutputDate, toValues.computedOutputDate],
  );
  const saveBtnEnabled = useMemo(
    () => localRelativeReferenceDate && (fromValues.value?.length || toValues.value?.length),
    [fromValues.value?.length, localRelativeReferenceDate, toValues.value?.length],
  );

  const handleRelativeDatesSubmit = async () => {
    if (!localRelativeReferenceDate) {
      return;
    }
    setIsSaveBtnLoading(true);
    await onDatesSubmit({
      relativeReferenceDate: { customDate: getSODAsUTCIsoString(new Date(localRelativeReferenceDate)) },
      relativeDateDueFrom: fromValues.value?.length
        ? {
            temporality: fromValues.temporality,
            unit: selectedDateUnitType,
            value: Number(fromValues.value),
          }
        : undefined,

      relativeDateDueTo: toValues.value?.length
        ? {
            temporality: toValues.temporality,
            unit: selectedDateUnitType,
            value: Number(toValues.value),
          }
        : undefined,
    });
    setIsSaveBtnLoading(false);
    onCloseDialog();
  };

  const hadleFromDatePickerChange = (date: string | null) => {
    if (!date || !localRelativeReferenceDate) {
      return setFromValues({
        value: null,
        temporality: IVBillTemporality.Before,
        computedOutputDate: null,
      });
    }

    setFromValues({
      value: `${Math.abs(differenceInDays(new Date(date), new Date(localRelativeReferenceDate)))}`,
      computedOutputDate: new Date(date).toString(),
      temporality:
        new Date(date).getTime() < new Date(localRelativeReferenceDate).getTime()
          ? IVBillTemporality.Before
          : IVBillTemporality.After,
    });

    setSelectedDateUnitType(IVBillBatchDateUnitType.Day);
  };

  const hadleToDatePickerChange = (date: string | null) => {
    if (!date || !localRelativeReferenceDate) {
      return setToValues({
        value: null,
        temporality: IVBillTemporality.After,
        computedOutputDate: null,
      });
    }

    setToValues({
      value: `${Math.abs(differenceInDays(new Date(date), new Date(localRelativeReferenceDate)))}`,
      temporality:
        new Date(date).getTime() < new Date(localRelativeReferenceDate).getTime()
          ? IVBillTemporality.Before
          : IVBillTemporality.After,
      computedOutputDate: new Date(date).toString(),
    });

    setSelectedDateUnitType(IVBillBatchDateUnitType.Day);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleRelativeDatesSubmit();
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>{title}</Typography>

          {enableCurrentDateSelector ? (
            <DatePicker
              label="Pick relative date"
              value={localRelativeReferenceDate}
              onChange={(date) => setLocalRelativeReferenceDate(new Date(date ?? new Date()).toString())}
              renderInput={(params) => <TextField {...params} size="small" sx={{ width: "150px", height: "32px" }} />}
            />
          ) : (
            <strong>{getVBillFormattedDate(localRelativeReferenceDate)}</strong>
          )}
        </Box>

        {localRelativeReferenceDate ? (
          <>
            <Divider sx={{ margin: "20px 0" }} />

            <Box sx={{ display: "flex", gap: "10px" }}>
              {displayBatchDateUnitList.map((range: IVBillBatchDateUnitType) => (
                <Button
                  variant={range === selectedDateUnitType ? "contained" : "outlined"}
                  key={range}
                  sx={{ height: "30px", borderRadius: "15px", textTransform: "uppercase" }}
                  onClick={() => setSelectedDateUnitType(range)}
                >
                  {`${range}s`}
                </Button>
              ))}
            </Box>

            <Divider sx={{ margin: "20px 0" }} />

            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                  <Typography sx={{ fontSize: "17px" }}>From:</Typography>
                  {fromValues.value?.length ? (
                    <Typography sx={{ fontSize: "14px" }}>
                      <strong>{formattedFromValueAsDate}</strong> (
                      {fromValues.temporality === IVBillTemporality.Before && "-"}
                      {formattedFromValueAsTimeAgo})
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "20px" }}>
                  <NumericFormat
                    allowNegative={false}
                    placeholder="Value:"
                    value={fromValues.value ?? ""}
                    onValueChange={(values) => setFromValues({ value: values.value })}
                    decimalScale={0}
                    customInput={TextField}
                    sx={{ width: "80px" }}
                    InputProps={{ sx: { width: "80px", height: "32px" } }}
                  />

                  <Select
                    value={fromValues.temporality}
                    onChange={(e) => setFromValues({ temporality: e.target.value as IVBillTemporality })}
                    sx={{ height: "32px", width: "182px", textTransform: "capitalize" }}
                  >
                    {Object.values(IVBillTemporality).map((time) => (
                      <MenuItem key={time} value={time} sx={{ textTransform: "capitalize" }}>
                        {time} {getVBillFormattedDate(localRelativeReferenceDate)}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip arrow title="Reset">
                    <IconButton
                      onClick={() =>
                        setFromValues({
                          value: null,
                          temporality: IVBillTemporality.Before,
                          computedOutputDate: null,
                        })
                      }
                    >
                      <CloseIcon sx={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {selectedDateUnitType === IVBillBatchDateUnitType.Day && (
                  <DatePicker
                    label="Pick from date"
                    value={fromValues.computedOutputDate ?? null}
                    onChange={hadleFromDatePickerChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ width: "150px", height: "32px" }}
                        {...(fromValues.computedOutputDate && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Clear" arrow>
                                  <IconButton
                                    onClick={() => hadleFromDatePickerChange(null)}
                                    sx={{ marginRight: "-12px" }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        })}
                      />
                    )}
                  />
                )}
              </Box>

              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
                  <Typography sx={{ fontSize: "17px" }}>To:</Typography>
                  {toValues.value?.length ? (
                    <Typography sx={{ fontSize: "14px" }}>
                      <strong>{formattedToValueAsDate}</strong> (
                      {toValues.temporality === IVBillTemporality.Before && "-"}
                      {formattedToValueAsTimeAgo})
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box sx={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "20px" }}>
                  <NumericFormat
                    allowNegative={false}
                    placeholder="Value:"
                    value={toValues.value ?? ""}
                    onValueChange={(values) => setToValues({ value: values.value })}
                    decimalScale={0}
                    customInput={TextField}
                    sx={{ width: "80px" }}
                    InputProps={{ sx: { width: "80px", height: "32px" } }}
                  />

                  <Select
                    value={toValues.temporality}
                    onChange={(e) => setToValues({ temporality: e.target.value as IVBillTemporality })}
                    sx={{ height: "32px", width: "162px", textTransform: "capitalize" }}
                  >
                    {Object.values(IVBillTemporality).map((time) => (
                      <MenuItem key={time} value={time} sx={{ textTransform: "capitalize" }}>
                        {time}{" "}
                        {localRelativeReferenceDate?.length ? getVBillFormattedDate(localRelativeReferenceDate) : ""}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip arrow title="Reset">
                    <IconButton
                      onClick={() =>
                        setToValues({
                          value: null,
                          temporality: IVBillTemporality.After,
                          computedOutputDate: null,
                        })
                      }
                    >
                      <CloseIcon sx={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {selectedDateUnitType === IVBillBatchDateUnitType.Day && (
                  <DatePicker
                    label="Pick to date"
                    value={toValues.computedOutputDate ?? null}
                    onChange={hadleToDatePickerChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ width: "150px", height: "32px" }}
                        {...(toValues.computedOutputDate && {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Clear" arrow>
                                  <IconButton
                                    onClick={() => hadleToDatePickerChange(null)}
                                    sx={{ marginRight: "-12px" }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          },
                        })}
                      />
                    )}
                  />
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Typography sx={{ textDecoration: "underline" }}>{noCurrentDateText}!</Typography>
        )}

        {formattedDaysBetween && (
          <>
            <Divider sx={{ margin: "20px 0" }} />
            <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
              <strong>{formattedDaysBetween}</strong> between.
            </Typography>
          </>
        )}

        <Divider sx={{ margin: "20px 0" }} />

        <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
            disabled={!saveBtnEnabled}
            loading={isSaveBtnLoading}
          >
            Save
          </LoadingButton>
          <Button
            variant="outlined"
            onClick={onCloseDialog}
            sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export const relatedDatesGetRelativeDate = (
  startDate: string,
  inputValue: string,
  temporality: IVBillTemporality,
  unitType: IVBillBatchDateUnitType,
) => {
  const timePresetInputValue = Number(`${temporality === IVBillTemporality.Before ? "-" : "+"}${inputValue}`);

  if (unitType === IVBillBatchDateUnitType.Day) {
    return addDays(new Date(startDate), timePresetInputValue).toString();
  }

  if (unitType === IVBillBatchDateUnitType.Week) {
    return addWeeks(new Date(startDate), timePresetInputValue).toString();
  }

  if (unitType === IVBillBatchDateUnitType.Month) {
    return addMonths(new Date(startDate), timePresetInputValue).toString();
  }

  // if (unitType === "quarters") {
  //   return addQuarters(new Date(startDate), timePresetInputValue);
  // }

  if (unitType === IVBillBatchDateUnitType.Year) {
    return addYears(new Date(startDate), timePresetInputValue).toString();
  }

  return addDays(new Date(startDate), timePresetInputValue).toString();
};
