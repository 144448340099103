import { TableCell, TableRow, Typography } from "@mui/material";
import { FormInputNumber } from "components/pages/VBill/common/Forms/FormInputNumber/FormInputNumber";
import { FormInputText } from "components/pages/VBill/common/Forms/FormInputText/FormInputText";
import { TVBillFormFields } from "components/pages/VBill/types";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { FunctionComponent, useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { LineItemMappingFieldSelector } from "./LineItemMappingFieldSelector/LineItemMappingFieldSelector";

const defaultTableCellSx = {
  padding: "8px 5px",
};

interface INewLineItemProps {
  index: number;
}

export const NewLineItem: FunctionComponent<INewLineItemProps> = observer(({ index }) => {
  const vBillStore = useStore("VBillStore");
  const { invoice, settings } = vBillStore;
  const settingsData = settings.data;

  const [glAccMapping, addMappings] = useMemo(() => {
    const lineAdditionalMappings = (settingsData?.vBill.additionalMappings.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return partition(lineAdditionalMappings, (map) => map.isGlAccount);
  }, [settingsData?.vBill.additionalMappings.line]);

  const invoiceData = invoice.data;

  return (
    <TableRow sx={{ cursor: "default" }}>
      <TableCell sx={{ ...defaultTableCellSx, width: "40px" }}>#{index + 1}</TableCell>
      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "200px" }}>
        <FormInputText
          name={`lineItems.${index}.description` as unknown as keyof TVBillFormFields}
          label="Description:"
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Gl Account:</Typography>

        {glAccMapping.length
          ? glAccMapping.map((additionalMapping) => (
              <LineItemMappingFieldSelector
                key={additionalMapping.storeDataType}
                formFieldName={`lineItems.${index}.${additionalMapping.storeDataType}`}
                additionalMappings={additionalMapping}
                invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                invoiceCompanyId={invoiceData?.companyId ?? ""}
              />
            ))
          : "-"}
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Additional Fields:</Typography>

        {addMappings.length
          ? addMappings.map((additionalMapping) => (
              <LineItemMappingFieldSelector
                key={additionalMapping.storeDataType}
                formFieldName={`lineItems.${index}.${additionalMapping.storeDataType}`}
                additionalMappings={additionalMapping}
                invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
                invoiceCompanyId={invoiceData?.companyId ?? ""}
              />
            ))
          : "-"}
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "50px" }}>
        <FormInputNumber name={`lineItems.${index}.qty` as unknown as keyof TVBillFormFields} label="Qty:" />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "70px" }}>
        <FormInputNumber name={`lineItems.${index}.rate` as unknown as keyof TVBillFormFields} label="Rate:" />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "100px" }}>
        <FormInputNumber
          name={`lineItems.${index}.amount` as unknown as keyof TVBillFormFields}
          label="Total:"
          priceField
        />
      </TableCell>
    </TableRow>
  );
});
