import { Box, Button, Divider, Popover } from "@mui/material";
import { useEffect } from "react";
import { useSetState } from "react-use";
import { VBillPriceAmount } from "../../../VBillPriceAmount/VBillPriceAmount";
import { AmountValues } from "../VBillAmountFilter";

interface IAmountFilterPopoverProps {
  amount: AmountValues;
  onAmountChange: (values: AmountValues) => void;
  popoverId?: string;
  popoverAnchorEl: null | HTMLElement;
  onClosePopover: () => void;
  actionsBtnSmallHeight?: boolean;
}

export const AmountFilterPopover = ({
  popoverId,
  popoverAnchorEl,
  onClosePopover,
  actionsBtnSmallHeight,
  amount,
  onAmountChange,
}: IAmountFilterPopoverProps) => {
  const [localAmount, setLocalAmount] = useSetState<AmountValues>();

  useEffect(() => {
    setLocalAmount({ amount_from: amount.amount_from, amount_to: amount.amount_to });
  }, [amount.amount_from, amount.amount_to, setLocalAmount]);

  const handleSaveDialog = () => {
    onAmountChange({
      amount_from: localAmount.amount_from,
      amount_to: localAmount.amount_to,
    });
    onClosePopover();
  };

  return (
    <Popover
      transitionDuration={0}
      id={popoverId}
      open
      anchorEl={popoverAnchorEl}
      onClose={onClosePopover}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Box sx={{ padding: "20px 10px 10px" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveDialog();
          }}
        >
          {/* <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Select amount</Typography> */}

          <Box sx={{ display: "flex", gap: "15px" }}>
            <VBillPriceAmount
              sx={{ width: "100px" }}
              value={`${localAmount?.amount_from ? localAmount.amount_from : ""}`}
              setValue={(value) => setLocalAmount({ amount_from: value })}
              placeholder="From:"
            />

            <VBillPriceAmount
              sx={{ width: "100px" }}
              value={`${localAmount?.amount_to ? localAmount.amount_to : ""}`}
              setValue={(value) => setLocalAmount({ amount_to: value })}
              placeholder="To:"
            />
          </Box>

          <Divider sx={{ margin: "20px 0" }} />

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Button variant="contained" type="submit" sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}>
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={onClosePopover}
              sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Popover>
  );
};
