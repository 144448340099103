import { Box, Skeleton } from "@mui/material";
import { VBillRequestErrorToast } from "components/pages/common/VBill/VBillRequestErrorToast/VBillRequestErrorToast";
import { VBillRouteGuardRedirect } from "components/pages/common/VBill/VBillRouteGuardRedirect/VBillRouteGuardRedirect";
import { isNil } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { VBillItemsTotal } from "../common/VBillItemsTotal/VBillItemsTotal";
import { useCurrentVBillRoute } from "../hooks";
import { TVBillFormFields } from "../types";
import {
  getVBillFormIncomingDefaultValues,
  getVBillFormNewDefaultValues,
  getVBillRouteRedirectByStatus,
} from "../utils";
import { VBillSidebar } from "../VBillSidebar/VBillSidebar";
import { VBillToolbar } from "./VBillToolbar/VBillToolbar";

export const VBillLayout: FunctionComponent<RouteConfigComponentProps> = observer(({ route }) => {
  const { vbillId, organizationId } = useParams<{ vbillId: string; organizationId: string }>();
  const prevVbillId = usePrevious(vbillId);
  const vBillStore = useStore("VBillStore");
  const organizationStore = useStore("OrganizationStore");
  const formMethods = useForm<TVBillFormFields>({ mode: "onChange" });
  const currentVBillRoute = useCurrentVBillRoute();
  const hasVBillRoute = useMemo(() => Object.values(currentVBillRoute).some((isRoute) => isRoute), [currentVBillRoute]);
  const history = useHistory();
  const location = useLocation();
  const [shouldTriggerForm, setShouldTriggerForm] = useState(false);

  const loadInvoiceAndRedirect = useCallback(async () => {
    const invoiceData =
      vbillId !== prevVbillId
        ? (await vBillStore.loadInvoice({ invoiceId: Number(vbillId) }))?.data
        : vBillStore.invoice.data;

    if (!hasVBillRoute) {
      const vBillRouteRedirectByStatusUrl = getVBillRouteRedirectByStatus(
        organizationId,
        vbillId,
        location.search,
        invoiceData?.status,
      );

      history.push(vBillRouteRedirectByStatusUrl);
    }
  }, [vbillId, prevVbillId, hasVBillRoute, vBillStore, organizationId, location.search, history]);

  useEffect(() => {
    loadInvoiceAndRedirect();
  }, [loadInvoiceAndRedirect]);

  useEffect(() => {
    if (!vBillStore.invoice.data?.companyId) {
      return;
    }

    vBillStore.loadSettings({ companyId: vBillStore.invoice.data.companyId, invoiceId: Number(vbillId) }, true);
  }, [vBillStore, vBillStore.invoice.data?.companyId, vbillId]);

  useEffect(() => {
    return () => {
      vBillStore.invoice.flush();
      vBillStore.settings.flush();
      vBillStore.setInvoiceLineItemsExpanded(false);
      vBillStore.setInvoiceLineItemsItemized(false);
    };
  }, [vbillId, vBillStore]);

  const { isIncomingRoute, isNewRoute, isSummaryRoute } = currentVBillRoute;

  useEffect(() => {
    setShouldTriggerForm(false);
  }, [isIncomingRoute, isNewRoute, isSummaryRoute]);

  // https://github.com/react-hook-form/react-hook-form/issues/7753 :(
  useEffect(() => {
    const invoiceData = toJS(vBillStore.invoice.data);

    if (isIncomingRoute) {
      const suggestedMainFile = invoiceData?.files?.find((file) => `${file.id}` === invoiceData?.suggestedMainFileId);
      const suggestedMainFileData = suggestedMainFile
        ? { name: suggestedMainFile.name, id: suggestedMainFile.id }
        : undefined;
      const currentMainFile = invoiceData?.mainFile
        ? invoiceData.mainFile
        : suggestedMainFileData
        ? suggestedMainFileData
        : invoiceData?.files?.[0]
          ? {
              id: invoiceData.files[0].id,
              name: invoiceData.files[0].name,
            }
          : undefined;
      const vBillFormDefaultValues = getVBillFormIncomingDefaultValues(invoiceData, currentMainFile);
      formMethods.reset(vBillFormDefaultValues);
      setShouldTriggerForm(true);
    }

    if (isNewRoute) {
      const vBillFormDefaultValues = getVBillFormNewDefaultValues(invoiceData);
      formMethods.reset(vBillFormDefaultValues);
      setShouldTriggerForm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vBillStore.invoice.data, isIncomingRoute, isNewRoute, isSummaryRoute]);

  // https://github.com/orgs/react-hook-form/discussions/11422
  useEffect(() => {
    if (isIncomingRoute && shouldTriggerForm && vBillStore.invoice.data) {
      const timeoutId = setTimeout(() => {
        formMethods.trigger();
        clearTimeout(timeoutId);
      }, 500);

      return;
    }

    if (isNewRoute && shouldTriggerForm && vBillStore.invoice.data && isNil(vBillStore.invoice.data.companyId)) {
      const timeoutId = setTimeout(() => {
        formMethods.trigger();
        clearTimeout(timeoutId);
      }, 500);

      return;
    }

    if (
      isNewRoute &&
      shouldTriggerForm &&
      vBillStore.invoice.data &&
      !isNil(vBillStore.invoice.data.companyId) &&
      vBillStore.settings.data
    ) {
      const timeoutId = setTimeout(() => {
        formMethods.trigger();
        clearTimeout(timeoutId);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isIncomingRoute,
    isNewRoute,
    shouldTriggerForm,
    organizationStore.organizationVBillEnabledCompanies.data,
    vBillStore.invoice.data,
    vBillStore.settings.data,
  ]);

  const invoiceData = vBillStore.invoice.data;

  return (
    <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
      <VBillRouteGuardRedirect route="CODING_DETAILS" />

      <FormProvider {...formMethods}>
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "0%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundColor:
                vBillStore.invoice.isLoading || vBillStore.settings.isLoading ? "transparent" : COLORS.white,
              maxHeight: "calc(100vh - 91px)",
              overflowY: "auto",
              overflowX: "hidden",
              paddingLeft: "20px",
            }}
            id="vbill-scrollable-left-pannel"
          >
            <Box>
              {vBillStore.invoice.isLoading || vBillStore.settings.isLoading ? (
                <Skeleton variant="rounded" sx={{ margin: "30px", height: "600px" }} />
              ) : (
                <>
                  {invoiceData && <VBillToolbar invoice={invoiceData} />}

                  <Box sx={{ padding: "10px" }}>{renderRoutes(route?.routes)}</Box>

                  {invoiceData && <VBillItemsTotal invoice={invoiceData} />}
                </>
              )}
            </Box>
          </Box>
        </Box>

        <VBillSidebar invoice={invoiceData} />
      </FormProvider>

      <VBillRequestErrorToast />
    </Box>
  );
});
