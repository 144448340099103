import { Box, Typography } from "@mui/material";
import { IInvoiceProps } from "components/pages/VBill/types";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
} from "generated/sdk.vbill";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { InvoiceMappingFieldSelector } from "./InvoiceMappingFieldSelector/InvoiceMappingFieldSelector";

interface IInvoiceMappingFieldProps extends IInvoiceProps {
  additionalMapping: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
}

export const InvoiceMappingField: FunctionComponent<IInvoiceMappingFieldProps> = ({
  additionalMapping: { showAs, label, storeDataType },
  additionalMapping,
  invoice,
}) => {
  if (showAs === IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowAs.Select) {
    return (
      <Box>
        <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
          {label ?? storeDataType}:
        </Typography>

        <InvoiceMappingFieldSelector
          additionalMappings={additionalMapping}
          formFieldName={storeDataType}
          invoiceCompanyId={invoice?.companyId ?? ""}
          invoiceCoreOrgId={invoice?.coreOrgId ?? ""}
        />
      </Box>
    );
  }

  return null;
};
