import { Box, FormControl, FormHelperText, InputProps, SxProps, TextField, Theme, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { FunctionComponent, useMemo } from "react";
import { ControllerProps, useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { COLORS } from "themes/default";

interface IFormInputNumberProps {
  name: keyof TVBillFormFields | keyof TVBillFormLineFields;
  sx?: SxProps<Theme>;
  rules?: ControllerProps<TVBillFormFields>["rules"];
  insideForm?: boolean;
  priceField?: boolean;
  label?: string;
  pushedTop?: boolean;
  InputProps?: Partial<InputProps>;
}

export const FormInputNumber: FunctionComponent<IFormInputNumberProps> = ({
  name,
  sx,
  rules,
  insideForm,
  priceField,
  label,
  pushedTop,
  InputProps,
}) => {
  const {
    field: { value, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController<TVBillFormFields | TVBillFormLineFields>({ name, rules: rules as any });

  const outsideFormTextValue = useMemo(() => {
    if (!insideForm) {
      if (priceField) {
        return formatCurrency(Number(value ?? 0));
      }

      return `${value}`.length ? value : "-";
    }
  }, [value, insideForm, priceField]);

  return (
    <Box sx={{ ...sx, ...(pushedTop ? { marginBottom: "10px" } : {}) }}>
      {label && <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>{label}</Typography>}

      {insideForm ? (
        <NumericFormat
          allowNegative={false}
          value={(value as string) ?? ""}
          prefix={priceField ? "$" : undefined}
          onValueChange={(values) => formFieldOnChange(values.value)}
          thousandSeparator={priceField}
          decimalScale={2}
          customInput={TextField}
          size="small"
          error={Boolean(error)}
          sx={{ width: "100%", height: "37px" }}
          InputProps={InputProps}
        />
      ) : (
        <Typography sx={{ wordWrap: "break-word" }}>{outsideFormTextValue}</Typography>
      )}

      {error && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};
