import { IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { TrashIcon } from "components/common/icons";
import { FormInputNumber } from "components/pages/VBill/common/Forms/FormInputNumber/FormInputNumber";
import { FormInputText } from "components/pages/VBill/common/Forms/FormInputText/FormInputText";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { FunctionComponent, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

const defaultTableCellSx = {
  padding: "8px 5px",
};

interface IIncomingLineItemProps {
  index: number;
  onRemoveBtnClick: () => void;
  invoiceLineItemsItemized: boolean;
}

export const IncomingLineItem: FunctionComponent<IIncomingLineItemProps> = ({
  index,
  onRemoveBtnClick,
  invoiceLineItemsItemized,
}) => {
  const formMethods = useFormContext<TVBillFormFields>();
  const lineItemHighlightedFieldValue: TVBillFormLineFields["highlighted"] = useWatch({
    name: `lineItems.${index}.highlighted`,
  });

  useEffect(() => {
    if (lineItemHighlightedFieldValue) {
      const timeoutId = setTimeout(() => {
        formMethods.setValue(`lineItems.${index}.highlighted`, false);
        clearTimeout(timeoutId);
      }, 3000);
    }
  }, [formMethods, index, lineItemHighlightedFieldValue]);

  return (
    <TableRow
      id={`vbill-line-item-${index}`}
      sx={{ ...(lineItemHighlightedFieldValue ? { backgroundColor: "#fff4ea" } : {}), cursor: "default" }}
    >
      {invoiceLineItemsItemized && <TableCell sx={{ ...defaultTableCellSx, width: "40px" }}>#{index + 1}</TableCell>}
      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top" }}>
        <FormInputText
          name={`lineItems.${index}.description` as unknown as keyof TVBillFormFields}
          label="Description:"
          rules={{ required: "Description is required" }}
          insideForm
          multiline
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "70px" }}>
        <FormInputNumber name={`lineItems.${index}.qty` as unknown as keyof TVBillFormFields} label="Qty:" insideForm />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "70px" }}>
        <FormInputNumber
          name={`lineItems.${index}.rate` as unknown as keyof TVBillFormFields}
          label="Rate:"
          insideForm
        />
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "110px" }}>
        <FormInputNumber
          name={`lineItems.${index}.amount` as unknown as keyof TVBillFormFields}
          label="Total:"
          priceField
          rules={{
            required: "Amount is required",
            // TODO: v2
            // validate: {
            //   required: (value: string) => !isNil(value) && !!(value ?? "").length,
            //   totalAmountByQtyAndRate: (value) => {
            //     const currentLineItemValues = getVBillFormValues("lineItems")?.[index];

            //     return (
            //       Number(currentLineItemValues?.qty ?? 0) * Number(currentLineItemValues?.rate ?? 0) ===
            //       Number(value ?? 0)
            //     );
            //   },
            // },
          }}
          insideForm
        />
      </TableCell>

      {invoiceLineItemsItemized && (
        <TableCell sx={{ ...defaultTableCellSx, width: "50px" }}>
          <Tooltip title="Delete line" placement="top-start" arrow>
            <IconButton onClick={onRemoveBtnClick} aria-label="delete">
              <TrashIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};
