import { Box, Button, Divider, Popover, Slider, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getSODAsUTCIsoString } from "common/helpers/utils";
import { DueDateOverBudgetButton } from "components/pages/VBillBatchDetails/common/DueDateOverBudget/DueDateOverBudgetButton";
import { addDays, differenceInDays, format } from "date-fns";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useSetState } from "react-use";

const dueDateMarks = [
  {
    value: 0,
    label: "Today",
  },
];
export type DueDateValues = Pick<IVBillBillsFiltersInput, "date_due_from" | "date_due_to">;

interface IDueDateFilterPopoverProps {
  dueDate: DueDateValues;
  onDueDateChange: (values: DueDateValues) => void;
  actionsBtnSmallHeight?: boolean;
  showDueDateSimulator?: boolean;
  popoverId?: string;
  popoverAnchorEl: null | HTMLElement;
  onClosePopover: () => void;
}

export const DueDateFilterPopover = ({
  dueDate: { date_due_from, date_due_to },
  onDueDateChange,
  actionsBtnSmallHeight,
  showDueDateSimulator,
  popoverId,
  popoverAnchorEl,
  onClosePopover,
}: IDueDateFilterPopoverProps) => {
  const [localSliderDueDate, setLocalSliderDueDate] = useState<number[]>([0, 10]);
  const [localPickerDueDate, setLocalPickerDueDate] = useSetState<DueDateValues>();
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  useEffect(() => {
    if (!date_due_from?.length || !date_due_to?.length) {
      return;
    }

    setLocalSliderDueDate([
      differenceInDays(new Date(date_due_from), new Date()),
      differenceInDays(new Date(date_due_to), new Date()),
    ]);
    setLocalPickerDueDate({ date_due_from, date_due_to });
  }, [date_due_from, date_due_to, setLocalPickerDueDate]);

  const handleSaveDialog = async () => {
    const dueDate = isPickerVisible
      ? {
          date_due_from: getSODAsUTCIsoString(new Date(localPickerDueDate.date_due_from ?? new Date())),
          date_due_to: getSODAsUTCIsoString(new Date(localPickerDueDate.date_due_to ?? new Date())),
        }
      : {
          date_due_from: getSODAsUTCIsoString(addDays(new Date(), localSliderDueDate[0] ?? 0)),
          date_due_to: getSODAsUTCIsoString(addDays(new Date(), localSliderDueDate[1] ?? 0)),
        };

    onDueDateChange(dueDate);
    onClosePopover();
    setIsPickerVisible(false);
  };

  const handleCancelDialog = () => {
    onClosePopover();
    setIsPickerVisible(false);
  };

  return (
    <Popover
      transitionDuration={0}
      id={popoverId}
      open
      anchorEl={popoverAnchorEl}
      onClose={onClosePopover}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Box sx={{ padding: "40px 40px 10px", width: "400px" }}>
        {/* <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Select VBill due date</Typography> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveDialog();
          }}
        >
          {isPickerVisible ? (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={localPickerDueDate.date_due_from}
                  onChange={(date) => {
                    setLocalPickerDueDate({ date_due_from: date });
                  }}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ width: "100%" }} />}
                />

                <DatePicker
                  value={localPickerDueDate.date_due_to}
                  onChange={(date) => {
                    setLocalPickerDueDate({ date_due_to: date });
                  }}
                  renderInput={(params) => <TextField {...params} size="small" sx={{ width: "100%" }} />}
                />
              </LocalizationProvider>
            </Box>
          ) : (
            <>
              <Slider
                getAriaLabel={() => "Due date"}
                value={localSliderDueDate}
                onChange={(_e: Event, newRange: number | number[]) => {
                  if (isArray(newRange)) {
                    setLocalSliderDueDate(newRange);
                  }
                }}
                valueLabelDisplay="auto"
                step={1}
                marks={dueDateMarks}
                min={-45}
                max={45}
                valueLabelFormat={(num) =>
                  num ? `${num > 0 ? "" : ""}${num} days: ${formatSliderDate(num)}` : "Today"
                }
              />

              <Button
                sx={{ ":hover": { textDecoration: "underline" }, height: "30px" }}
                onClick={() => setIsPickerVisible(true)}
              >
                Custom
              </Button>

              {showDueDateSimulator && <DueDateOverBudgetButton btnText="Due date simulator" leftBudgetAmount={0} />}
            </>
          )}

          <Divider sx={{ margin: "20px 0" }} />

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Button variant="contained" type="submit" sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}>
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancelDialog}
              sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Popover>
  );
};

const formatSliderDate = (value: number) => format(addDays(new Date(), value), "d MMM");
