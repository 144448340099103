import { TableHead, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { vBillLedgerTHeadSx } from "components/pages/common/VBill/styles";
import { IVBillSortOrderType } from "generated/sdk.vbill";
import React from "react";
import { useTableHeadCells } from "./useTableHeadCells";

export const CodingHeader = () => {
  const tableHeadCells = useTableHeadCells();

  return (
    <TableHead>
      <TableRow sx={{ cursor: "default" }}>
        {tableHeadCells.map((headerCell) =>
          headerCell.sortable ? (
            <TableCell
              key={headerCell.id}
              sx={{ ...vBillLedgerTHeadSx, ...(headerCell.id === "amount_sort" ? { textAlign: "right" } : {}) }}
              sortDirection={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
            >
              <TableSortLabel
                active={headerCell.value === IVBillSortOrderType.Asc}
                direction={headerCell.value === IVBillSortOrderType.Asc ? "asc" : "desc"}
                sx={{ ...(headerCell.id === "amount_sort" ? { flexDirection: "row-reverse" } : {}) }}
                onClick={() =>
                  headerCell.onChange?.(
                    headerCell.value === IVBillSortOrderType.Asc ? IVBillSortOrderType.Desc : IVBillSortOrderType.Asc,
                  )
                }
              >
                {headerCell.label}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell sx={vBillLedgerTHeadSx} key={headerCell.id}>
              {headerCell.label}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};
