import { Box, Typography } from "@mui/material";
import { IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn } from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { SelectAllMappingFieldSelector } from "./SelectAllMappingFieldSelector/SelectAllMappingFieldSelector";

export const NewSelectMappings = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { invoice, settings } = vBillStore;
  const settingsData = settings.data;
  const invoiceData = invoice.data;

  const [glAccMapping, addMappings] = useMemo(() => {
    const lineAdditionalMappings = (settingsData?.vBill.additionalMappings.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return partition(lineAdditionalMappings, (map) => map.isGlAccount);
  }, [settingsData?.vBill.additionalMappings.line]);

  if (!settingsData?.vBill.additionalMappings.line?.length) {
    return null;
  }

  return (
    <Box sx={{ marginBottom: "15px" }}>
      <Typography sx={{ marginBottom: "10px", fontWeight: "500", fontSize: "15px" }}>
        Select mappings for all line items:
      </Typography>

      <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {glAccMapping.map((additionalMapping) => (
          <SelectAllMappingFieldSelector
            invoiceCompanyId={invoiceData?.companyId ?? ""}
            invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
            key={additionalMapping.storeDataType}
            additionalMappings={additionalMapping}
          />
        ))}

        {addMappings.map((additionalMapping) => (
          <SelectAllMappingFieldSelector
            invoiceCompanyId={invoiceData?.companyId ?? ""}
            invoiceCoreOrgId={invoiceData?.coreOrgId ?? ""}
            key={additionalMapping.storeDataType}
            additionalMappings={additionalMapping}
          />
        ))}
      </Box>
    </Box>
  );
});
