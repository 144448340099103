import { Box, Tooltip, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { FunctionComponent, useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useCurrentVBillRoute } from "../../hooks";
import { IInvoiceProps } from "../../types";
import { VBillItemsTotalController } from "./VBillItemsTotalController/VBillItemsTotalController";

export const VBillItemsTotal: FunctionComponent<IInvoiceProps> = observer(({ invoice }) => {
  const organizationStore = useStore("OrganizationStore");
  const { isIncomingRoute } = useCurrentVBillRoute();
  const showPayments = useMemo(
    () =>
      invoice?.status === IVBillInvoiceIncomingStatus.Approved &&
      !!invoice?.amounts &&
      !!organizationStore.organizationVBillPayLedgerEnabled,
    [invoice?.amounts, invoice?.status, organizationStore.organizationVBillPayLedgerEnabled],
  );

  if (!invoice) {
    return null;
  }

  const { amounts, amount } = invoice;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: COLORS.white,
        // zIndex: 1202, // 1200 has Drawer
        borderTop: "1px solid #DFE7F2",
        padding: isIncomingRoute ? "15px 10px" : "20px 10px",
      }}
    >
      {showPayments ? (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center", width: "100%" }}
          >
            <Tooltip arrow title="Invoice Amount" placement="top">
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: "16px" }}>Amount</Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: 600, marginTop: "5px" }}>
                  {formatCurrency(Number(amounts?.amount ?? 0))}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip arrow title="Amount in payments" placement="top">
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: "16px" }}>Paid</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    marginTop: "5px",
                    color: Number(amounts?.amountInPayments ?? 0) > 0 ? COLORS.success : COLORS.black,
                  }}
                >
                  {formatCurrency(Number(amounts?.amountInPayments ?? 0))}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip arrow title="Free amount" placement="top">
              <Box sx={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: "16px" }}>Ballance</Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    marginTop: "5px",
                    color: Number(amounts?.amountFreeToPay ?? 0) > 0 ? COLORS.orange : COLORS.black,
                  }}
                >
                  {formatCurrency(Number(amounts?.amountFreeToPay ?? 0))}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ paddingLeft: "0" }}>
            <Typography sx={{ fontSize: "22px" }}>Total Due:</Typography>
          </Box>
          <Box>
            {isIncomingRoute ? (
              <VBillItemsTotalController />
            ) : (
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "600",
                  textAlign: "right",
                  color: COLORS.textGray,
                }}
              >
                {formatCurrency(Number(amount ?? 0))}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
});
