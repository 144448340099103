import LinkIcon from "@mui/icons-material/Link";
import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { Decimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { DeleteIcon } from "components/common/icons";
import {
  friendlyBatchStatusNames,
  getBatchStatusStyleColor,
  getVBillFormattedDate,
} from "components/pages/common/VBill/utils";
import {
  IVBillAddEditBatchSubmitPayload,
  VBillAddEditBatchDialog,
} from "components/pages/common/VBill/VBillAddEditBatchDialog/VBillAddEditBatchDialog";
import { relatedDatesGetRelativeDate } from "components/pages/common/VBill/VBillRelativeDatesSelector/VBillRelativeDatesSelector";
import { ConfirmBatchActionDialog } from "components/pages/VBillBatchDetails/common/ConfirmBatchActionDialog/ConfirmBatchActionDialog";
import {
  getSdk,
  IVBillBatchDateUnitType,
  IVBillBatchStatus,
  IVBillTemporality,
  IVBillVBillGetBatchListQuery,
} from "generated/sdk.vbill";
import { groupBy, isNil } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useBatchLedgerFilters, useVBillBatchLedgerChildRoute } from "../hooks";
import { BatchRowLink } from "./BatchRowLink/BatchRowLink";
import { BillsAmount } from "./BillsAmount/BillsAmount";
import { PaymentGroup } from "./PaymentGroup/PaymentGroup";

const { VBillUpdateBatch, VBillDeleteBatch } = getSdk(graphqlVBillClient);

const defaultTableCellSx = {
  padding: "0",
};

interface IBatchRowProps {
  batch: NonNullable<IVBillVBillGetBatchListQuery["getBatchList"]["data"]>["items"][number];
}

export const BatchRow = observer(function BatchRow({ batch }: IBatchRowProps) {
  const [isEditBatchModalOpen, setIsEditBatchModalOpen] = useState(false);
  const [isDeleteBatchDialogOpen, setIsDeleteBatchDialogOpen] = useState(false);
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillBatchLedgerStore = useStore("VBillBatchLedgerStore");
  const organizationStore = useStore("OrganizationStore");

  const [batchLedgerFilters] = useBatchLedgerFilters();
  const { isAllChildRoute } = useVBillBatchLedgerChildRoute();

  const { companiesTotal, companyNames } = useMemo(() => {
    const orgCompanies = (batch?.batchFilters?.companyIds ?? []).map(
      (compId) =>
        (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
          ({ companyId }) => companyId === compId,
        )?.name,
    );

    return { companiesTotal: orgCompanies.length, companyNames: orgCompanies };
  }, [batch?.batchFilters?.companyIds, organizationStore.organizationVBillEnabledCompanies.data?.items]);
  const formattedDateDueFromDate = useMemo(() => {
    if (!batch?.batchFilters?.relativeReferenceDate || !batch?.batchFilters?.relativeDateDueFrom) {
      return "-";
    }

    const { relativeReferenceDate, relativeDateDueFrom } = batch.batchFilters;
    const newLocalRelativeReferenceDate = new Date(
      relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const value = `${relativeDateDueFrom.value}`;
    const temporality = relativeDateDueFrom.temporality ? relativeDateDueFrom.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueFrom?.unit ? relativeDateDueFrom.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    return getVBillFormattedDate(relativeDateValue);
  }, [batch?.batchFilters]);
  const formattedDateDueToDate = useMemo(() => {
    if (!batch?.batchFilters?.relativeReferenceDate || !batch?.batchFilters?.relativeDateDueTo) {
      return "-";
    }

    const { relativeReferenceDate, relativeDateDueTo } = batch.batchFilters;
    const newLocalRelativeReferenceDate = new Date(
      relativeReferenceDate?.customDate ? relativeReferenceDate.customDate : new Date() /* today */,
    ).toString();
    const value = `${relativeDateDueTo.value}`;
    const temporality = relativeDateDueTo.temporality ? relativeDateDueTo.temporality : IVBillTemporality.Before;
    const newSelectedDateUnitType = relativeDateDueTo?.unit ? relativeDateDueTo.unit : IVBillBatchDateUnitType.Day;
    const relativeDateValue = relatedDatesGetRelativeDate(
      newLocalRelativeReferenceDate,
      value,
      temporality,
      newSelectedDateUnitType,
    );

    return getVBillFormattedDate(relativeDateValue);
  }, [batch?.batchFilters]);
  const paymentsInfo = useMemo(() => {
    const paymentsByStatus = groupBy(batch.batchPayments, (payment) => payment.paymentStatus);

    return Object.values(paymentsByStatus);
  }, [batch.batchPayments]);
  const batchStatusName = useMemo(() => friendlyBatchStatusNames[batch.status], [batch.status]);
  const batchStatusColor = useMemo(() => getBatchStatusStyleColor(batch.status), [batch.status]);

  const handleAddEditBatchSubmit = async ({ name, budget }: IVBillAddEditBatchSubmitPayload) => {
    if (!batch) {
      return;
    }

    await VBillUpdateBatch({
      batchId: batch.id,
      batchData: {
        organizationId,
        name: name ?? "",
        budget: new Decimal(budget ?? 0).toFixed(2),
        batchFilters: { ...batch.batchFilters },
      },
    });
    setIsEditBatchModalOpen(false);
    vBillBatchLedgerStore.loadBatchLedgerList(
      { filters: { ...batchLedgerFilters.filters, organizationId }, pagination: batchLedgerFilters.pagination },
      true,
    );
  };

  const handleConfirmDeleteBatchSubmit = async () => {
    if (!batch) {
      return;
    }

    await VBillDeleteBatch({ batchId: batch.id });
    vBillBatchLedgerStore.loadBatchLedgerList(
      { filters: { ...batchLedgerFilters.filters, organizationId }, pagination: batchLedgerFilters.pagination },
      true,
    );
  };

  // const batchPathWithoutOwner = batch.batchPath.filter((batchId) => batchId !== batch.id);

  return (
    <>
      <TableRow sx={{ cursor: "default" }}>
        <TableCell sx={defaultTableCellSx}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ color: COLORS.newSecondaryColor }}>
            #{batch.id}
          </BatchRowLink>
        </TableCell>
        <TableCell sx={defaultTableCellSx}>
          <BatchRowLink hasAccess={true} batchId={batch.id}>
            {batch.name}
          </BatchRowLink>
        </TableCell>

        <TableCell sx={{ ...defaultTableCellSx, width: "200px" }}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ wordBreak: "break-word", fontSize: "12px" }}>
            {companiesTotal ? (
              companiesTotal > 3 ? (
                <Tooltip
                  arrow
                  title={
                    <>
                      {companyNames.map((companyName) => (
                        <div key={companyName}>{companyName}</div>
                      ))}
                    </>
                  }
                >
                  <div>{companyNames.slice(0, 3).join(", ")} ...</div>
                </Tooltip>
              ) : (
                companyNames.join(", ")
              )
            ) : (
              "-"
            )}
          </BatchRowLink>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ textAlign: "right", whiteSpace: "nowrap" }}>
            {!isNil(batch.invoicesAmount) ? <BillsAmount batch={batch} /> : "-"}
          </BatchRowLink>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ textAlign: "right", whiteSpace: "nowrap" }}>
            {!isNil(batch.budget) ? formatCurrency(Number(batch.budget)) : "-"}
          </BatchRowLink>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ fontSize: "12px" }}>
            {paymentsInfo.length
              ? paymentsInfo.map((payments) => <PaymentGroup key={payments[0].paymentStatus} payments={payments} />)
              : "-"}
          </BatchRowLink>
        </TableCell>

        <TableCell sx={{ ...defaultTableCellSx, width: "200px" }}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ wordBreak: "break-word", fontSize: "12px" }}>
            {batch.createdByName},{" "}
            <Box component="span" sx={{ color: COLORS.textGray }}>
              {batch.createdByEmail}
            </Box>
          </BatchRowLink>
        </TableCell>

        <TableCell sx={defaultTableCellSx}>
          <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ fontSize: "12px" }}>
            <Box sx={{ whiteSpace: "nowrap" }}>
              Batch date:{" "}
              <Box component="span" sx={{ color: COLORS.textGray }}>
                {batch.batchDate ? getVBillFormattedDate(batch.batchDate) : "-"}
              </Box>
            </Box>
            <Box>
              Bills due date range:{" "}
              {!batch?.batchFilters?.relativeReferenceDate && !batch?.batchFilters?.relativeDateDueFrom && "-"}
            </Box>
            {(batch?.batchFilters?.relativeReferenceDate || batch?.batchFilters?.relativeDateDueFrom) && (
              <Box sx={{ color: COLORS.textGray }}>
                {formattedDateDueFromDate} to {formattedDateDueToDate}
              </Box>
            )}
          </BatchRowLink>
        </TableCell>

        {isAllChildRoute && (
          <TableCell sx={defaultTableCellSx}>
            <BatchRowLink hasAccess={true} batchId={batch.id} sx={{ color: batchStatusColor }}>
              {batchStatusName}
            </BatchRowLink>
          </TableCell>
        )}

        <TableCell
          sx={{ ...defaultTableCellSx, ...(batch.status !== IVBillBatchStatus.Open ? { textAlign: "center" } : {}) }}
        >
          {batch.status === IVBillBatchStatus.Open ? (
            <Box sx={{ display: "flex" }}>
              <Box component="a" href={`/org/${organizationId}/vbillbatch/${batch.id}`} target="_blank">
                <Tooltip title="Open batch in a new tab">
                  <IconButton>
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {/* 
              <Tooltip title="Edit batch" arrow>
                <IconButton
                  // disabled={batch.state === CheckBatchStateEnum.Closed}
                  onClick={() => setIsEditBatchModalOpen(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip> */}

              <Tooltip title="Delete batch" arrow>
                <IconButton onClick={() => setIsDeleteBatchDialogOpen(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            "-"
          )}
        </TableCell>
      </TableRow>

      {isEditBatchModalOpen && (
        <VBillAddEditBatchDialog
          showBudgetPicker
          showNamePicker
          batchData={batch}
          onDialogClose={() => setIsEditBatchModalOpen(false)}
          onSubmit={handleAddEditBatchSubmit}
        />
      )}

      {isDeleteBatchDialogOpen && (
        <ConfirmBatchActionDialog
          title={`Delete batch`}
          text={`Are you sure you want to delete this batch?`}
          confirmBtnText={`Delete batch`}
          onCloseDialog={() => setIsDeleteBatchDialogOpen(false)}
          actionsBtnSmallHeight
          onConfirmSubmit={handleConfirmDeleteBatchSubmit}
        />
      )}
    </>
  );
});
