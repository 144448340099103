import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { CopyOutlineIcon } from "components/common/icons";
import { FunctionComponent } from "react";

interface IWidgetInputProps {
  inputText: string;
  onInputChange: (text: string) => void;
  onCopyBtnClick: () => void;
  inputError?: string;
}

export const WidgetInput: FunctionComponent<IWidgetInputProps> = ({
  inputText,
  onInputChange,
  inputError,
  onCopyBtnClick,
}) => (
  <>
    <Typography>OCR text</Typography>
    <TextField
      error={!!inputError}
      helperText={inputError}
      value={inputText}
      onChange={(e) => onInputChange(e.target.value)}
      size="small"
      sx={{ margin: "10px 0" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onCopyBtnClick}>
              <CopyOutlineIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </>
);
