import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillCompanyListFilter } from "components/pages/common/VBill/FilterComponents/VBillCompanyListFilter/VBillCompanyListFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillDueDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDueDateFilter/VBillDueDateFilter";
import { VBillActiveFilters } from "components/pages/common/VBill/VBillActiveFilters/VBillActiveFilters";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";

interface IBundleFiltersProps {
  onCloseDialog: () => void;
  onFiltersChange: (values: IVBillBillsFiltersInput) => void;
  filters?: IVBillBillsFiltersInput | null;
}

export const BundleFilters = observer(function BundleFilters({
  onCloseDialog,
  onFiltersChange,
  filters,
}: IBundleFiltersProps) {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [localFilters, setLocalFilters] = useSetState<IVBillBillsFiltersInput>();

  useEffect(() => {
    setLocalFilters(filters ?? {});
  }, [filters, setLocalFilters]);

  // TODO:
  const [vendorsMapping, restOfMappings] = useMemo(
    () =>
      partition(
        vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.data?.invoice ?? [],
        (map) => map.isContact,
      ),
    [vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.data?.invoice],
  );

  return (
    <Dialog
      onClose={onCloseDialog}
      open
      PaperProps={{ sx: { overflow: "visible", padding: "15px", width: 700, maxWidth: 700, position: "relative" } }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Batch Filters</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px", alignItems: "center", marginBottom: "15px" }}>
        <VBillCompanyListFilter
          smallHeight
          actionsBtnSmallHeight
          companies={localFilters.companyIds ?? []}
          onCompaniesChange={async (values) => setLocalFilters({ companyIds: values })}
        />
        <VBillAmountFilter
          amount={{
            amount_from: localFilters?.amount_from,
            amount_to: localFilters?.amount_to,
          }}
          onAmountChange={({ amount_from, amount_to }) => setLocalFilters({ amount_from, amount_to })}
          smallHeight
          actionsBtnSmallHeight
        />
        <VBillDateFilter
          date={{
            date: localFilters?.date,
            date_to: localFilters?.date_to,
          }}
          onDateChange={({ date, date_to }) => setLocalFilters({ date, date_to })}
        />
        <VBillDueDateFilter
          smallHeight
          actionsBtnSmallHeight
          dueDate={{
            date_due_from: localFilters?.date_due_from,
            date_due_to: localFilters?.date_due_to,
          }}
          onDueDateChange={({ date_due_from, date_due_to }) => setLocalFilters({ date_due_from, date_due_to })}
        />
        {/* 
        {vendorsMapping.map(({ label, storeDataType }) => (
          <AdditionalMappingsFilter
            searchPlaceholder={`Search ${label ?? ""}`}
            inputLabel={label ?? ""}
            storeType={storeDataType as unknown as IVBillIStoreIntDataType}
            contact_external_ref_ids={localFilters?.contact_external_ref_ids ?? []}
            onContact_external_ref_idsChange={(values) => setLocalFilters({ contact_external_ref_ids: values })}
          />
        ))}

        {restOfMappings.map(({ label, storeDataType }) => (
          <AdditionalMappingsFilter
            searchPlaceholder={`Search ${label ?? ""}`}
            inputLabel={label ?? ""}
            storeType={storeDataType as unknown as IVBillIStoreIntDataType}
            contact_external_ref_ids={localFilters?.contact_external_ref_ids ?? []}
            onContact_external_ref_idsChange={(values) => setLocalFilters({ contact_external_ref_ids: values })}
          />
        ))} */}
      </Box>

      <VBillActiveFilters
        chipSmallHeight
        filters={localFilters}
        onFiltersChange={(values) => {
          setLocalFilters((prev) => ({
            ...(Object.keys(values).length ? { ...prev, ...values } : undefined),
          }));
        }}
      />

      <Divider sx={{ marginTop: 4, marginBottom: 2 }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <Button
          variant="contained"
          onClick={() => {
            onFiltersChange(localFilters);
            onCloseDialog();
          }}
          sx={{ height: "30px" }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={onCloseDialog} sx={{ height: "30px" }}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
});
