import { Box, InputProps, SxProps, TextField, Theme, Typography } from "@mui/material";
import { IVReimbursementFormFields } from "components/pages/VReimbursement/types";
import { FunctionComponent } from "react";
import { ControllerProps, useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface IFormInputNumberProps {
  name: keyof IVReimbursementFormFields;
  sx?: SxProps<Theme>;
  InputProps?: Partial<InputProps>;
  rules?: ControllerProps<IVReimbursementFormFields>["rules"];
  label: string;
  priceField?: boolean;
}

export const FormInputNumber: FunctionComponent<IFormInputNumberProps> = ({
  name,
  sx,
  InputProps,
  rules,
  label,
  priceField,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<IVReimbursementFormFields>({ name, rules });

  return (
    <Box sx={{ marginBottom: "15px", ...sx }}>
      <Typography sx={{ marginBottom: "5px" }}>{label}</Typography>

      <NumericFormat
        allowNegative={false}
        value={(value as string) ?? ""}
        prefix={priceField ? "$" : undefined}
        onValueChange={(values) => onChange(values.value)}
        thousandSeparator={priceField}
        decimalScale={2}
        customInput={TextField}
        error={!!error}
        sx={{ width: "100%" }}
        InputProps={{ sx: { height: "32px" }, ...InputProps }}
        helperText={error?.message}
      />
    </Box>
  );
};
