import { Box, Divider, Typography } from "@mui/material";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";

interface ISummaryInvoiceDetailsProps {
  vBillData: IVBillVBillInvoiceQuery["invoice"];
}

export const SummaryInvoiceDetails = observer(function SummaryInvoiceDetails({
  vBillData,
}: ISummaryInvoiceDetailsProps) {
  const organizationStore = useStore("OrganizationStore");
  const allCompanies = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).map(({ name, companyId }) => ({
        name,
        id: companyId,
      })),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );

  const invoiceCompanyName = useMemo(
    () => allCompanies.find(({ id }) => id === vBillData.companyId)?.name,
    [allCompanies, vBillData.companyId],
  );

  const invoiceData = vBillData;
  const {
    senderName,
    senderAddress,
    senderEmail,
    senderPhone,
    emailDate,
    emailSubject,
    initialSenderEmail,
    initialSenderName,
    company,
    invoiceNumber,
    invoiceDate,
    invoiceDateDue,
  } = invoiceData;

  return (
    <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
      <Box sx={{ width: "calc(33.3% - 27.5px)" }}>
        <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
          FROM:
        </Typography>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Sender Name:</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{senderName ?? "-"}</Typography>
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Sender Address:</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{senderAddress ?? "-"}</Typography>
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Sender Email:</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{senderEmail ?? "-"}</Typography>
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Sender Phone:</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{senderPhone ?? "-"}</Typography>
        </Box>
      </Box>

      <Divider flexItem orientation="vertical" />

      <Box sx={{ width: "calc(33.3% - 27.5px)" }}>
        {(initialSenderName || initialSenderEmail || emailDate || emailSubject) && (
          <Box sx={{ marginBottom: "20px" }}>
            <>
              <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
                RECEIVED FROM:
              </Typography>

              <Typography sx={{ wordWrap: "break-word" }}>
                {initialSenderName ?? "-"}, {initialSenderEmail ?? "-"} on {getVBillFormattedDate(emailDate)}, with
                subject: "{emailSubject ?? "-"}"
              </Typography>
            </>
          </Box>
        )}
        <Box sx={{ marginBottom: "20px" }}>
          <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
            Bill to:
          </Typography>
          {company?.orgName && <Typography>Organization: {company.orgName}</Typography>}
          {company?.name && <Typography>Company: {company.name}</Typography>}
        </Box>
        <Box>
          <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
            Company:
          </Typography>

          <Typography sx={{ wordWrap: "break-word" }}>{invoiceCompanyName ?? "-"}</Typography>
        </Box>
      </Box>

      <Divider flexItem orientation="vertical" />

      <Box sx={{ width: "calc(33.3% - 27.5px)" }}>
        <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
          PAYMENT:
        </Typography>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bill#</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{invoiceNumber ?? "-"}</Typography>
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Date Issued:</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{getVBillFormattedDate(invoiceDate)}</Typography>
        </Box>
        <Box sx={{ marginBottom: "10px" }}>
          <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>Bill Due:</Typography>
          <Typography sx={{ wordWrap: "break-word" }}>{getVBillFormattedDate(invoiceDateDue)}</Typography>
        </Box>
      </Box>
    </Box>
  );
});
