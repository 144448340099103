import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "storeContainer";
import { SummaryInvoiceDetails } from "./SummaryInvoiceDetails/SummaryInvoiceDetails";
import { SummaryInvoiceMappings } from "./SummaryInvoiceMappings/SummaryInvoiceMappings";
import { SummaryLineItems } from "./SummaryLineItems/SummaryLineItems";

export const VBillSummary = observer(function VBillSummary() {
  const vBillStore = useStore("VBillStore");

  useEffect(() => {
    vBillStore.setInvoiceLineItemsExpanded(false);
  }, [vBillStore]);

  const { invoiceLineItemsExpanded } = vBillStore;

  if (!vBillStore.invoice.data) {
    return null;
  }

  const vBillData = vBillStore.invoice.data;
  const vBillAdditionalMappingsSettings = vBillStore.settings.data?.vBill.additionalMappings;

  return (
    <>
      <SummaryInvoiceDetails vBillData={vBillData} />

      <SummaryInvoiceMappings vBillData={vBillData} vBillAdditionalMappingsSettings={vBillAdditionalMappingsSettings} />

      <SummaryLineItems
        vBillData={vBillData}
        vBillAdditionalMappingsSettings={vBillAdditionalMappingsSettings}
        lineItemsExpanded={invoiceLineItemsExpanded}
        onLineItemsExpandedChange={vBillStore.setInvoiceLineItemsExpanded}
      />
    </>
  );
});
