import { graphqlVBillClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillVBillCopyInvoiceMutationVariables,
  IVBillVBillInvoiceQuery,
  IVBillVBillInvoiceQueryVariables,
  IVBillVBillPushInvoiceMutationVariables,
  IVBillVBillSettingsQuery,
  IVBillVBillSettingsQueryVariables,
  IVBillVBillStartApprovalForInvoiceMutationVariables,
  IVBillVBillSuggestionsQuery,
  IVBillVBillSuggestionsQueryVariables,
  IVBillVBillUpdateInvoiceAndMappingsMutationVariables,
  IVBillVBillUpdateInvoiceCompanyMutationVariables,
} from "generated/sdk.vbill";
import { action, makeObservable, observable, runInAction } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const {
  VBillInvoice,
  VBillUpdateInvoiceAndMappings,
  VBillUpdateInvoiceCompany,
  VBillSettings,
  VBillSuggestions,
  VBillStartApprovalForInvoice,
  VBillCopyInvoice,
  VBillPushInvoice,
} = getSdk(graphqlVBillClient);

export class VBillStore extends StoreBase {
  constructor() {
    super();
    makeObservable(this, {
      invoiceLineItemsExpanded: observable,
      setInvoiceLineItemsExpanded: action,
      invoiceLineItemsItemized: observable,
      setInvoiceLineItemsItemized: action,

      showVBillRequestErrorToast: observable,
      setShowVBillRequestErrorToast: action,
    });
  }

  showVBillRequestErrorToast: boolean = false;

  invoice = createObservableContainer<NonNullable<IVBillVBillInvoiceQuery["invoice"]>>();
  invoiceLineItemsExpanded = false;
  invoiceLineItemsItemized = false;
  settings = createObservableContainer<NonNullable<IVBillVBillSettingsQuery["settings"]>>();
  suggestions = createObservableContainer<NonNullable<IVBillVBillSuggestionsQuery["suggestions"]>>();

  setInvoiceLineItemsExpanded = (value: boolean) => {
    this.invoiceLineItemsExpanded = value;
  };

  setInvoiceLineItemsItemized = (value: boolean) => {
    this.invoiceLineItemsItemized = value;
  };

  async loadInvoice(data: IVBillVBillInvoiceQueryVariables, force?: boolean) {
    return this._preGqlReqWithErrorToast(() =>
      this.invoice.cachedLoad(async () => (await VBillInvoice(data)).invoice, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  loadSettings(data: IVBillVBillSettingsQueryVariables, force?: boolean) {
    this._preGqlReqWithErrorToast(() =>
      this.settings.cachedLoad(async () => (await VBillSettings(data)).settings, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  loadSuggestions(data: IVBillVBillSuggestionsQueryVariables, force?: boolean) {
    this._preGqlReqWithErrorToast(() =>
      this.suggestions.cachedLoad(async () => (await VBillSuggestions(data)).suggestions, [data], {
        forceUpdate: force,
        catchError: false,
      }),
    );
  }

  async updateInvoiceAndMappings(variables: IVBillVBillUpdateInvoiceAndMappingsMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillUpdateInvoiceAndMappings(variables));

    if (resp?.updateInvoiceAndMappings) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.updateInvoiceAndMappings;
      });

      return resp.updateInvoiceAndMappings;
    }
  }

  async copyInvoice(variables: IVBillVBillCopyInvoiceMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillCopyInvoice(variables));

    if (resp?.copyInvoice) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.copyInvoice;
      });
    }
  }

  async pushInvoice(variables: IVBillVBillPushInvoiceMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillPushInvoice(variables));

    if (resp?.pushInvoice) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.pushInvoice;
      });
    }
  }

  async updateInvoiceCompany(variables: IVBillVBillUpdateInvoiceCompanyMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillUpdateInvoiceCompany(variables));

    if (resp?.updateInvoiceCompany) {
      runInAction(() => {
        // FIXME: just one in V1
        this.invoice._dataObsevable = resp.updateInvoiceCompany[0];
      });

      return resp.updateInvoiceCompany;
    }
  }

  async startApprovalForInvoice(variables: IVBillVBillStartApprovalForInvoiceMutationVariables) {
    const resp = await this._preGqlReqWithErrorToast(() => VBillStartApprovalForInvoice(variables));

    if (resp?.startApprovalForInvoice) {
      runInAction(() => {
        this.invoice._dataObsevable = resp.startApprovalForInvoice;
      });

      return resp.startApprovalForInvoice;
    }
  }

  setShowVBillRequestErrorToast = (value: boolean) => {
    this.showVBillRequestErrorToast = value;
  };

  _preGqlReqWithErrorToast = async <T>(promiseFunc: () => Promise<T>) => {
    try {
      return await promiseFunc();
    } catch (_e) {
      this.setShowVBillRequestErrorToast(true);

      return Promise.resolve(undefined);
    }
  };
}
