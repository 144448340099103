import { Box, Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { useState } from "react";
import { IVBillRelativeDatesSelectorProps } from "../../VBillRelativeDatesSelector/VBillRelativeDatesSelector";
import { VBillRelativeDatesSelectorDialog } from "../../VBillRelativeDatesSelector/VBillRelativeDatesSelectorDialog";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

interface IVBillRelativeDueDateFilterProps extends Pick<IVBillRelativeDatesSelectorProps, "dates" | "onDatesSubmit"> {
  dialogTitle: string;
  dialogNoRelativeDateText?: string;
  dialogEnableRelativeDateSelector?: boolean;
  btnSmallHeight?: boolean;
  sx?: SxProps<Theme>;
  btnText?: string;
  dialogActionsBtnSmallHeight?: boolean;
}

export const VBillRelativeDueDateFilter = ({
  dates,
  onDatesSubmit,
  dialogTitle,
  dialogNoRelativeDateText,
  dialogEnableRelativeDateSelector,
  btnSmallHeight,
  sx,
  btnText,
  dialogActionsBtnSmallHeight,
}: IVBillRelativeDueDateFilterProps) => {
  const [isRelativeDueDateDialogOpen, setIsRelativeDueDateDialogOpen] = useState(false);

  return (
    <Box sx={sx}>
      {/* <InputLabel variant="standard">Date due</InputLabel> */}
      <Button
        onClick={() => setIsRelativeDueDateDialogOpen(true)}
        endIcon={
          isRelativeDueDateDialogOpen ? (
            <ExpandLessIcon sx={expandIconStyles} />
          ) : (
            <ExpandMoreIcon sx={expandIconStyles} />
          )
        }
        variant="outlined"
        sx={{ ...(btnSmallHeight ? { height: "30px" } : {}) }}
      >
        {btnText ?? "Due Date"}
      </Button>

      {isRelativeDueDateDialogOpen && (
        <VBillRelativeDatesSelectorDialog
          dates={dates}
          onDatesSubmit={onDatesSubmit}
          onCloseDialog={() => setIsRelativeDueDateDialogOpen(false)}
          enableCurrentDateSelector={dialogEnableRelativeDateSelector}
          title={dialogTitle}
          noCurrentDateText={dialogNoRelativeDateText}
          actionsBtnSmallHeight={dialogActionsBtnSmallHeight}
        />
      )}
    </Box>
  );
};
