import { Popover } from "@mui/material";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { emptyOCRFormLineFields } from "components/pages/VBill/utils";
import { isValid } from "date-fns";
import { FunctionComponent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useStore } from "storeContainer";
import styles from "./imageMenuWidget.module.scss";
import { WidgetForm } from "./WidgetForm/WidgetForm";
import { WidgetInput } from "./WidgetInput/WidgetInput";

interface IImageMenuWidgetProps {
  imageText: string;
}

export const ImageMenuWidget: FunctionComponent<IImageMenuWidgetProps> = ({ imageText }) => {
  const vBillStore = useStore("VBillStore");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [widgetInputText, setWidgetInputText] = useState(imageText);
  const [widgetInputError, setWidgetInputError] = useState<string>();
  const formMethods = useFormContext<TVBillFormFields>();

  const handleAddLineFieldBtnClick = (index: number, fieldName: keyof TVBillFormLineFields) => {
    const vBillFormLineItems = formMethods.getValues().lineItems;

    if (!vBillFormLineItems?.length) {
      return;
    }

    // trust me, it's there
    const lineElementToScroll = document.getElementById(`vbill-line-item-${index}`) as HTMLDivElement;
    const scrollableLeftPannel = document.getElementById("vbill-scrollable-left-pannel") as HTMLDivElement;
    const halfWindowHeight = window.innerHeight / 2;

    if (fieldName === "amount" || fieldName === "qty" || fieldName === "rate") {
      const amountAsNumber = Number(widgetInputText);

      if (isNaN(amountAsNumber)) {
        setWidgetInputError("Invalid Number");
      } else {
        vBillStore.setInvoiceLineItemsExpanded(true);
        formMethods.setValue(
          `lineItems.${index}`,
          {
            ...vBillFormLineItems[index],
            [fieldName]: amountAsNumber,
            highlighted: true,
          },
          { shouldValidate: true },
        );
        scrollableLeftPannel.scrollTo({
          behavior: "smooth",
          top: lineElementToScroll.getBoundingClientRect().top + scrollableLeftPannel.scrollTop - halfWindowHeight,
        });
        setPopoverAnchorEl(null);
      }
    } else {
      setWidgetInputError(undefined);
      vBillStore.setInvoiceLineItemsExpanded(true);
      formMethods.setValue(
        `lineItems.${index}`,
        {
          ...vBillFormLineItems[index],
          [fieldName]: widgetInputText,
          highlighted: true,
        },
        { shouldValidate: true },
      );
      scrollableLeftPannel.scrollTo({
        behavior: "smooth",
        top: lineElementToScroll.getBoundingClientRect().top + scrollableLeftPannel.scrollTop - halfWindowHeight,
      });
      setPopoverAnchorEl(null);
    }
  };

  const handleAddNewLineBtnClick = () => {
    const formValues = {
      ...formMethods.getValues(),
      lineItems: [...formMethods.getValues().lineItems, emptyOCRFormLineFields],
    };

    formMethods.reset(formValues);
  };

  const handleAddInvoiceFieldBtnClick = (fieldName: keyof TVBillFormFields) => {
    if (fieldName === "invoiceDate" || fieldName === "invoiceDateDue") {
      const isNotValidDate = !isValid(new Date(widgetInputText));

      if (isNotValidDate) {
        setWidgetInputError("Invalid Date");
      } else {
        formMethods.setValue(fieldName, widgetInputText, { shouldValidate: true });
        setPopoverAnchorEl(null);
      }
    } else {
      formMethods.setValue(fieldName, widgetInputText, { shouldValidate: true });
      setPopoverAnchorEl(null);
    }
  };

  const handleWidgetInputChange = (value: string) => {
    if (widgetInputError) {
      setWidgetInputError(undefined);
    }

    setWidgetInputText(value);
  };

  const handleWidgetCopyBtnClick = async () => {
    try {
      await navigator.clipboard.writeText(widgetInputText);
    } catch (err) {
      window.alert("Copy Text to Clipboard not working in dev. mode, you need https.");
    }
  };

  return (
    <>
      <button onClick={(event) => setPopoverAnchorEl(event.currentTarget)} className={styles.imageMenuBtn} />

      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <div className={styles.popoverInner}>
          <WidgetInput
            inputText={widgetInputText}
            onInputChange={handleWidgetInputChange}
            inputError={widgetInputError}
            onCopyBtnClick={handleWidgetCopyBtnClick}
          />

          <WidgetForm
            onAddInvoiceFieldBtnClick={handleAddInvoiceFieldBtnClick}
            onAddLineFieldBtnClick={handleAddLineFieldBtnClick}
            onAddNewLineBtnClick={handleAddNewLineBtnClick}
          />
        </div>
      </Popover>
    </>
  );
};
