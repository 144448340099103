import { Box, FormControl, FormHelperText, TextField } from "@mui/material";
import { TVBillFormFields } from "components/pages/VBill/types";
import { useController } from "react-hook-form";
import { NumericFormat } from "react-number-format";

export const VBillItemsTotalController = () => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<TVBillFormFields>({ name: "amount", rules: { required: "Total is required." } });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
      <Box sx={{ width: "120px" }}>
        <NumericFormat
          allowNegative={false}
          value={value as string}
          prefix="$"
          onValueChange={(values) => onChange(values.value)}
          thousandSeparator
          decimalScale={2}
          customInput={TextField}
          InputProps={{ sx: { fontSize: "16px", fontWeight: "500" } }}
          error={Boolean(error)}
        />
      </Box>

      {error && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};
