import { Box, Button, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { AddOutlinedIcon } from "components/common/icons";
import { IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useStore } from "storeContainer";
import {
  VBillLineItemsAccordian,
  VBillLineItemsAccordianDetails,
  VBillLineItemsAccordianSummary,
} from "../../common/VBillLineItemsAccordian/VBillLineItemsAccordian";
import { useLineItemsFormErrors } from "../../hooks";
import { TVBillFormFields } from "../../types";
import { emptyOCRFormLineFields } from "../../utils";
import { IncomingLineItem } from "./IncomingLineItem/IncomingLineItem";
import { LineItemsApplySuggestions } from "./LineItemsApplySuggestions/LineItemsApplySuggestions";

export const IncomingLineItems: FunctionComponent = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { append, remove, fields } = useFieldArray<TVBillFormFields>({ name: "lineItems" });

  useLineItemsFormErrors();

  const handleIncomingLineItemRemoveBtnClick = (index: number) => {
    if (fields.length !== 1) {
      remove(index);
    }
  };
  const { invoiceLineItemsExpanded, invoiceLineItemsItemized } = vBillStore;
  const invoiceData = vBillStore.invoice.data;

  useEffect(() => {
    if (invoiceData?.status !== IVBillInvoiceIncomingStatus.Queued) {
      vBillStore.setInvoiceLineItemsItemized(true);
    }
  }, [invoiceData?.status, vBillStore]);

  return (
    <Box>
      <LineItemsApplySuggestions />

      <VBillLineItemsAccordian
        expanded={invoiceLineItemsExpanded}
        onChange={() => vBillStore.setInvoiceLineItemsExpanded(!invoiceLineItemsExpanded)}
      >
        {invoiceLineItemsItemized && (
          <VBillLineItemsAccordianSummary
            accordianExpanded={invoiceLineItemsExpanded}
            headingTotalLineItems={fields.length}
          />
        )}

        <VBillLineItemsAccordianDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow />
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <IncomingLineItem
                    key={field.id}
                    index={index}
                    onRemoveBtnClick={() => handleIncomingLineItemRemoveBtnClick(index)}
                    invoiceLineItemsItemized={invoiceLineItemsItemized}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ marginBottom: "10px" }}>
            {invoiceLineItemsItemized && (
              <Button
                onClick={() => append(emptyOCRFormLineFields)}
                type="button"
                startIcon={<AddOutlinedIcon />}
                sx={{ "&:hover": { textDecoration: "underline" } }}
              >
                Add Item
              </Button>
            )}
          </Box>
        </VBillLineItemsAccordianDetails>
      </VBillLineItemsAccordian>
    </Box>
  );
});
