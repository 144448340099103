import { Box, Divider } from "@mui/material";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
} from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useMemo } from "react";
import { useStore } from "storeContainer";
import { InvoiceMappingField } from "../common/Forms/InvoiceMappingField/InvoiceMappingField";
import { VBillEmailFrom } from "../common/VBillEmailFrom/VBillEmailFrom";
import { VBillFrom } from "../common/VBillFrom/VBillFrom";
import { VBillPayment } from "../common/VBillPayment/VBillPayment";
import { VBillToDetails } from "../common/VBillToDetails/VBillToDetails";
import { NewLineItems } from "./NewLineItems/NewLineItems";
import { NewSelectCompany } from "./NewSelectCompany/NewSelectCompany";

export const VBillNew: FunctionComponent = observer(() => {
  const vBillStore = useStore("VBillStore");
  const invoiceAdditionalMappings = useMemo(
    () =>
      (vBillStore.settings.data?.vBill.additionalMappings.invoice ?? []).filter(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
      ),
    [vBillStore.settings.data?.vBill.additionalMappings.invoice],
  );

  if (!vBillStore.invoice.data) {
    return null;
  }

  const invoiceData = vBillStore.invoice.data;

  return (
    <>
      <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <VBillFrom />

        <Divider flexItem orientation="vertical" />

        <Box sx={{ width: "calc(33.3% - 27.5px)" }}>
          <VBillEmailFrom invoice={invoiceData} />
          <VBillToDetails invoice={invoiceData} />
          <NewSelectCompany invoice={invoiceData} />
        </Box>

        <Divider flexItem orientation="vertical" />

        <VBillPayment />
      </Box>

      {!!invoiceAdditionalMappings.length && (
        <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px", flexWrap: "wrap" }}>
          {invoiceAdditionalMappings.map(
            (additionalMapping: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput) => (
              <Box key={additionalMapping.storeDataType} sx={{ width: "calc(33.3% - 13.5px)" }}>
                <InvoiceMappingField
                  key={additionalMapping.storeDataType}
                  additionalMapping={additionalMapping}
                  // TODO: v2
                  // suggestion={suggestions?.additionalMapping?.find(
                  //   (addMapSuggestion) =>
                  //     addMapSuggestion.type ===
                  //     (additionalMapping.storeDataType as unknown as IVBillIStoreIntDataType)
                  // )}
                  invoice={invoiceData}
                />
              </Box>
            ),
          )}
        </Box>
      )}

      <NewLineItems />
    </>
  );
});
