import { IVBillInvoicePaginationInput, IVBillInvoicesFiltersInput } from "generated/sdk.vbill";
import { useParams, useRouteMatch } from "react-router";
import { VBillCodingLedgerBasePath } from "routes/routes";
import { JsonParam, QueryParamConfig, useQueryParams, withDefault } from "use-query-params";
import { VBillCodingLedgerChildRoutes } from "./utils";

export const codingLedgerFiltersInitialPaginationParams: IVBillInvoicePaginationInput = { page: 1, per_page: 50 };
const initialQsPaginationParams = withDefault<IVBillInvoicePaginationInput, IVBillInvoicePaginationInput>(
  JsonParam,
  codingLedgerFiltersInitialPaginationParams,
);

export function useCodingLedgerFilters() {
  return useQueryParams<{
    pagination: QueryParamConfig<IVBillInvoicePaginationInput, IVBillInvoicePaginationInput>;
    filters: QueryParamConfig<IVBillInvoicesFiltersInput, IVBillInvoicesFiltersInput>;
  }>(
    {
      pagination: initialQsPaginationParams,
      filters: JsonParam,
    },
    { removeDefaultsFromUrl: true },
  );
}

export interface vBillCodingLedgerChildRoute {
  isAllChildRoute: boolean;
  isPendingReviewChildRoute: boolean;
  isCodingChildRoute: boolean;
  isApprovalsChildRoute: boolean;
  isActionRequiredChildRoute: boolean;
  isCompletedChildRoute: boolean;
}

export function useVBillCodingLedgerChildRoute(): vBillCodingLedgerChildRoute {
  const isAllChildRoute = !!useRouteMatch({ path: VBillCodingLedgerBasePath, exact: true });
  const isPendingReviewChildRoute = !!useRouteMatch(
    `${VBillCodingLedgerBasePath}/${VBillCodingLedgerChildRoutes.PENDING_REVIEW}`,
  );
  const isCodingChildRoute = !!useRouteMatch(`${VBillCodingLedgerBasePath}/${VBillCodingLedgerChildRoutes.CODING}`);
  const isApprovalsChildRoute = !!useRouteMatch(
    `${VBillCodingLedgerBasePath}/${VBillCodingLedgerChildRoutes.APPROVALS}`,
  );
  const isActionRequiredChildRoute = !!useRouteMatch(
    `${VBillCodingLedgerBasePath}/${VBillCodingLedgerChildRoutes.ACTIONS_REQUIRED}`,
  );
  const isCompletedChildRoute = !!useRouteMatch(
    `${VBillCodingLedgerBasePath}/${VBillCodingLedgerChildRoutes.COMPLETED}`,
  );

  return {
    isAllChildRoute,
    isPendingReviewChildRoute,
    isCodingChildRoute,
    isApprovalsChildRoute,
    isActionRequiredChildRoute,
    isCompletedChildRoute,
  };
}

export function useVBillCodingLedgerQuickFilterUrl(route: VBillCodingLedgerChildRoutes) {
  const { organizationId } = useParams<{ organizationId: string }>();

  return `/org/${organizationId}/vbillcodingledger${route === VBillCodingLedgerChildRoutes.ALL ? "" : `/${route}`}`;
}
