import { Box, Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { useState } from "react";
import { AmountFilterPopover } from "./AmountFilterPopover/AmountFilterPopover";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

export type AmountValues = Pick<IVBillBillsFiltersInput, "amount_from" | "amount_to">;

interface IVBillAmountFilterProps {
  onAmountChange: (values: AmountValues) => void;
  amount: AmountValues;
  smallHeight?: boolean;
  actionsBtnSmallHeight?: boolean;
  sx?: SxProps<Theme>;
  btnText?: string;
}

export const VBillAmountFilter = ({
  amount,
  onAmountChange,
  smallHeight,
  actionsBtnSmallHeight,
  sx,
  btnText,
}: IVBillAmountFilterProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const popoverVisible = Boolean(popoverAnchorEl);
  const popoverId = popoverVisible ? "amount-filter" : undefined;

  return (
    <Box sx={sx}>
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        aria-describedby={popoverId}
        variant={amount.amount_from || amount.amount_to ? "containedFilters" : "outlined"}
        endIcon={popoverVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
        sx={{ ...(smallHeight ? { height: "30px" } : {}) }}
      >
        {btnText ?? "Amount"}
      </Button>

      {popoverVisible && (
        <AmountFilterPopover
          onAmountChange={onAmountChange}
          amount={amount}
          popoverId={popoverId}
          popoverAnchorEl={popoverAnchorEl}
          onClosePopover={() => setPopoverAnchorEl(null)}
          actionsBtnSmallHeight={actionsBtnSmallHeight}
        />
      )}
    </Box>
  );
};
