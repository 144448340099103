import { Layout } from "components/layout/Layout";
import { OrganizationLayout } from "components/layout/OrganizationLayout";
import AutoDepositRules from "components/pages/AutoDepositRulesPage/AutoDepositRules";
import { BudgetManagementRoute } from "components/pages/BudgetManagement/BudgetManagementRoute";
// import { UndepositedChecks } from "components/pages/UndepositedChecks/UndepositedChecks";
import { CheckBatchDetailsRoute } from "components/pages/BudgetManagement/CheckBatchDetailsRoute";
import { VBillInactive } from "components/pages/common/VBill/VBillInactive/VBillInactive";
import { AccessDenied } from "components/pages/DepositAccounts/AccessDenied";
import { DepositAccounts } from "components/pages/DepositAccounts/DepositAccounts";
import { DepositAccountsLayout } from "components/pages/DepositAccounts/DepositAccountsLayout";
import IntegrationsPage from "components/pages/Integrations/IntegrationsPage";
import { Invoice } from "components/pages/Invoice/Invoice";
import { LiveStats } from "components/pages/LiveStats/LiveStats";
import { MainDashboard } from "components/pages/MainDashoard";
import { OrganizationAccounts } from "components/pages/Settings/Accounts/OrganizationAccounts";
import { AddBankAccount } from "components/pages/Settings/AddBankAccount/AddBankAccount";
import { Accounts } from "components/pages/Settings/Company/Accounts/Accounts";
import { CompanyDetails } from "components/pages/Settings/Company/CompanyDetails";
import DepositAddressDetails from "components/pages/Settings/Company/DepositAddressDetails";
import LinkedAddressDetails from "components/pages/Settings/Company/LinkedAddressDetails";
import LinkedAddresses from "components/pages/Settings/Company/LinkedAddresses";
import Integrations from "components/pages/Settings/Integrations";
import Integration from "components/pages/Settings/Integrations/Integration";
import { IntegrationLanding } from "components/pages/Settings/Integrations/IntegrationLanding";
import { useSelectedOrganizationCompanyId } from "components/pages/Settings/OrganizationCompanySelector/useSelectedOrganizationCompanyId";
import ProfileSettings from "components/pages/Settings/ProfileSettings";
import AclCheckWrapGenerator from "components/pages/Settings/Users/AclCheckWrapGenerator";
import { InviteUser } from "components/pages/Settings/Users/InviteUser";
import { Permissions2Add } from "components/pages/Settings/Users/User/Permissions2/Permissions2Add";
import { Permissions2Edit } from "components/pages/Settings/Users/User/Permissions2/Permissions2Edit";
import { Permissions2View } from "components/pages/Settings/Users/User/Permissions2/Permissions2View";
import Vendors from "components/pages/Settings/Vendors";
import Vendor from "components/pages/Settings/Vendors/Vendor";
import { VBillIncoming } from "components/pages/VBill/VBillIncoming/VBillIncoming";
import { VBillLayout } from "components/pages/VBill/VBillLayout/VBillLayout";
import { VBillNew } from "components/pages/VBill/VBillNew/VBillNew";
import { VBillSummary } from "components/pages/VBill/VBillSummary/VBillSummary";
import { VBillBatchDetails } from "components/pages/VBillBatchDetails/VBillBatchDetails";
import { VBillBatchLedger } from "components/pages/VBillBatchLedger/VBillBatchLedger";
import { VBillCodingLedger } from "components/pages/VBillCodingLedger/VBillCodingLedger";
import { OrganizationAccountsRoute } from "components/pages/VBillOverview/OrganizationAccountsRoute";
import { VBillPayLedger } from "components/pages/VBillPayLedger/VBillPayLedger";
import { VBillPaymentsLedger } from "components/pages/VBillPaymentsLedger/VBillPaymentsLedger";
import { VCheckPage } from "components/pages/VChecks/VCheckPage/VCheckPage";
import { VChecksRoute } from "components/pages/VChecks/VChecksRoute";
import { VReimbursement } from "components/pages/VReimbursement/VReimbursement";
import { RouteConfig } from "react-router-config";
import { Redirect } from "react-router-dom";
import { AccessRestricted } from "../components/pages/AccessRestricted";
import { CompanyPendingChecks } from "../components/pages/Company/CompanyPendingChecks/CompanyPendingChecks";
import DepositAccountsLedger from "../components/pages/DepositAccounts/DepositAccountsLedger";
import { DevLogin } from "../components/pages/DevLogin/DevLogin";
import { PaymentOptions } from "../components/pages/PaymentOptions/PaymentOptions";
import { OrganizationSettings } from "../components/pages/Settings/Accounts/components/OrganizationSettings";
import Organization from "../components/pages/Settings/Organization";
import Users from "../components/pages/Settings/Users";
import User from "../components/pages/Settings/Users/User";
import Users2 from "../components/pages/Settings/Users/User/Permissions2/Users2";

export type VBillRoutes =
  | "CODING_DETAILS"
  | "CODING_LEDGER"
  | "PAY_LEDGER"
  | "PAYMENTS_LEDGER"
  | "BATCH_DETAILS"
  | "BATCH_LEDGER";
// TODO: /vbills/... prefix routes
export const VBillsInactivePath = "/org/:organizationId/vbills-inactive";
export const VBillLeadingPath = "/org/:organizationId/vbill/:vbillId";
export const VBillIncomingPath = `${VBillLeadingPath}/incoming`;
export const VBillNewPath = `${VBillLeadingPath}/new`;
export const VBillSummaryPath = `${VBillLeadingPath}/summary`;
// export const VBillPaymentPath = `${VBillLeadingPath}/payment/:paymentId`;
export const VBillCodingLedgerBasePath = "/org/:organizationId/vbillcodingledger";
export const VBillCodingLedgerPath = `${VBillCodingLedgerBasePath}/:selectedQuickFilter?`; // TODO:
export const OrganizationAccountsPath = "/org/:organizationId/organization-accounts";
export const VBillFileStreamPath = "/api/vbill/file/stream";
export const CheckBatchDetailsPath = "/org/:organizationId/bchecks/:batchId";
export const VBillBatchLedgerBasePath = "/org/:organizationId/vbillbatchledger"; // TODO:
export const VBillBatchLedgerPath = `${VBillBatchLedgerBasePath}/:selectedQuickFilter?`; // TODO:
export const VBillPayLedgerBasePath = "/org/:organizationId/vbillpayledger"; // TODO:
export const VBillPayLedgerPath = `${VBillPayLedgerBasePath}/:selectedQuickFilter?`; // TODO:
export const VBillPaymentsLedgerBasePath = "/org/:organizationId/vbillpaymentsledger"; // TODO:
export const VBillPaymentsLedgerPath = `${VBillPaymentsLedgerBasePath}/:selectedQuickFilter?`; // TODO:

export const VBillBatchDetailsPath = "/org/:organizationId/vbillbatch/:batchId"; // TODO:

const orgUser2SubRoutes: RouteConfig[] = [
  {
    path: ["/org/:organizationId/users2"],
    component: Users2,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users"],
    component: Users,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users/invite"],
    component: () => <InviteUser goBack={true} />,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users/:organizationUserId"],
    component: User,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users/:organizationUserId/groups"],

    component: User,

    exact: true,
  },
  {
    path: ["/org/:organizationId/users/:organizationUserId/users2/permissions"],
    component: User,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users/:organizationUserId/users2/permissions/:category"],
    component: User,

    exact: true,
  },
  {
    path: ["/org/:organizationId/users2/permissions"],
    // component: Permissions2List,
    component: (() => {
      const { organizationId } = useSelectedOrganizationCompanyId();
      return <Redirect to={`/org/${organizationId}/users2`} />;
    }) as React.FC,
    exact: true,
  },
  {
    path: [
      "/org/:organizationId/users2/permissions/add",
      "/org/:organizationId/users2/permissions/add/:user_type/:user_id",
    ],
    component: Permissions2Add,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users2/permissions/:user_type/:user_id"],
    component: Permissions2View,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users2/permissions/:user_type/:user_id/:entity_type/:entity_id"],
    component: Permissions2Edit,
    exact: true,
  },
];

const orgSubRoutes: RouteConfig[] = [
  {
    path: ["/org"], // Add Bank Account
    component: MainDashboard,
    exact: true,
  },
  {
    path: ["/org/addBankAccount"], // Add Bank Account
    component: AddBankAccount,
    exact: true,
  },
  {
    path: ["/org/:organizationId"], // Organization Bank Accounts
    component: OrganizationAccounts,
    exact: true,
  },
  {
    path: "/org/:organizationId/bchecks",
    component: BudgetManagementRoute,
    exact: true,
  },
  {
    path: "/org/:organizationId/invoice",
    component: Invoice,
    exact: true,
  },
  {
    path: [CheckBatchDetailsPath],
    component: CheckBatchDetailsRoute,
    exact: true,
  },
  //
  {
    path: VBillBatchLedgerPath,
    component: VBillBatchLedger,
    exact: true,
  },
  {
    path: VBillPayLedgerPath,
    component: VBillPayLedger,
    exact: true,
  },
  {
    path: VBillPaymentsLedgerPath,
    component: VBillPaymentsLedger,
    exact: true,
  },
  {
    path: VBillBatchDetailsPath,
    component: VBillBatchDetails,
    exact: true,
  },
  {
    path: VBillCodingLedgerPath,
    component: VBillCodingLedger,
    exact: true,
  },
  {
    path: "/org/:organizationId/vreimbursement",
    component: VReimbursement,
    exact: true,
  },
  //
  {
    path: ["/org/:organizationId/company/:companyId/bas"], // Company Bank Accounts
    component: Accounts,
    exact: true,
  },
  {
    path: OrganizationAccountsPath,
    component: OrganizationAccountsRoute,
    exact: true,
  },
  {
    path: VBillsInactivePath,
    component: VBillInactive,
    exact: true,
  },
  {
    path: VBillLeadingPath,
    component: VBillLayout,
    routes: [
      {
        path: VBillIncomingPath,
        component: VBillIncoming,
        exact: true,
      },
      {
        path: VBillNewPath,
        component: VBillNew,
        exact: true,
      },
      {
        path: VBillSummaryPath,
        component: VBillSummary,
        exact: true,
      },
      // {
      //   path: VBillPaymentPath,
      //   component: VBillPaymentDetails,
      //   exact: true,
      // },
    ],
  },
  {
    path: ["/org/:organizationId/company/:companyId/bas/:accountId/deposits"], // Company Bank Accounts
    component: DepositAddressDetails,
    exact: true,
  },
  {
    path: ["/org/:organizationId/company/:companyId/bas/:accountId/emails"], // Company Bank Accounts
    component: LinkedAddresses,
    exact: true,
  },
  {
    path: ["/org/:organizationId/company/:companyId/bas/:accountId/emails/add"], // Company Bank Accounts
    component: LinkedAddressDetails,
    exact: true,
  },
  {
    path: ["/org/:organizationId/company/:companyId/vendors"], // Company Vendors
    component: Vendors,
    exact: true,
  },
  {
    path: ["/org/:organizationId/company/:companyId/vendors/:vendorId"], // Company Vendors
    component: Vendor,
    exact: true,
  },
  {
    path: ["/org/:organizationId/company/:companyId/integrations"], // Company Integrations
    component: Integrations,
    exact: true,
  },
  {
    path: [
      "/org/:organizationId/company/:companyId/integrations/:integrationId",
      "/org/:organizationId/company/:companyId/integrations/:integrationId/userMapping",
      "/org/:organizationId/company/:companyId/integrations/:integrationId/bankaccountsMapping",
    ], // Company Integrations
    component: Integration,
    exact: true,
  },
  {
    path: ["/org/:organizationId/company/:companyId"], // Company Details
    component: CompanyDetails,
    exact: true,
  },
  {
    path: ["/org/:organizationId/import/:companyId"], // Company Details
    component: CompanyPendingChecks,
    exact: true,
  },
  {
    path: ["/org/:organizationId/users2"],
    component: AclCheckWrapGenerator,
    routes: orgUser2SubRoutes,
  },
  {
    path: ["/org/:organizationId/settings"],
    component: OrganizationSettings,
    exact: true,
  },

  {
    path: ["/org/:organizationId/groups"],
    component: Users,
    exact: true,
  },
  {
    path: ["/org/:organizationId/groups/:groupId"],
    component: Organization,
    exact: true,
  },
  {
    path: ["/org/:organizationId/groups/:groupId/users"],
    component: Organization,

    exact: true,
  },
  {
    path: ["/org/:organizationId/groups/:groupId/users2/permissions"],
    component: Organization,

    exact: true,
  },
  {
    path: ["/org/:organizationId/groups/:groupId/users2/permissions/:category"],
    component: Organization,

    exact: true,
  },
  {
    path: ["/org/:organizationId/vChecks/payments/:selectedQuickFilter"],
    component: VChecksRoute,
    exact: true,
  },

  {
    path: ["/org/:organizationId/vChecks/deposits/:selectedQuickFilter"],
    component: VChecksRoute,
    exact: true,
  },
  // {
  //   path: ["/org/:organizationId/vChecks/deposits/:selectedQuickFilter/:sharedToken"],
  //   component: VChecksRoute,
  //   exact: true,
  // },
  // {
  //   path: ["/org/:organizationId/vChecks/payments/:selectedQuickFilter/:sharedToken"],
  //   component: VChecksRoute,
  //   exact: true,
  // },
  {
    path: ["/org/:organizationId/ledger/:bankAccountId/checks/:checkId"],
    component: VCheckPage,
    exact: true,
  },
];

const intgrationRoutes: RouteConfig[] = [
  // /integrations/qbd?companyidentifier={0}&companyname={1}
  {
    path: ["/integrations/:integrationSlug"],
    component: IntegrationLanding,
  },
];
const depositAccountsRoutes: RouteConfig[] = [
  {
    path: ["/depositAccounts/ledger/:selectedQuickFilter"],
    component: DepositAccountsLedger,
    exact: true,
  },
  {
    path: ["/depositAccounts/budget-management"], // Add Bank Account
    component: AccessDenied,
    exact: true,
  },
  {
    path: ["/depositAccounts/payments"], // Add Bank Account
    component: AccessDenied,
    exact: true,
  },
  {
    path: ["/depositAccounts/bills"], // Add Bank Account
    component: AccessDenied,
    exact: true,
  },
  {
    path: ["/depositAccounts/invoices"], // Add Bank Account
    component: AccessDenied,
    exact: true,
  },
  {
    path: ["/depositAccounts"], // Add Bank Account
    component: DepositAccounts,
    exact: true,
  },
  {
    path: ["/organization-accounts"],
    component: OrganizationAccounts,
    exact: true,
  },
  {
    path: ["/organization-accounts"],
    component: OrganizationAccounts,
    exact: true,
  },
];

// const oldRoutes: RouteConfig[] = [
//   {
//     path: ["/companies/:companyId/import"],
//     exact: true,
//     component: CompanyPendingChecks,
//   },

//   // {
//   //   path: ["/integrations/:integrationSlug"],
//   //   component: IntegrationLanding,
//   // },
//   // {
//   //   path: ["/settings"],
//   //   component: Settings,
//   // },
// ];

let _appRoutes: RouteConfig[] = [
  {
    path: ["/guest/:hash/collect"],
    component: PaymentOptions,
  },
  {
    path: ["/live-stats"],
    component: LiveStats,
    exact: true,
  },
  // ...oldRoutes,
  ...intgrationRoutes,
  ...depositAccountsRoutes,
  {
    path: ["/org", "/org/:organizationId", "/org/:organizationId/settings", "/org/:organizationId/company/:companyId"],
    component: OrganizationLayout,
    // routes: [...orgSubRoutes, ...compSubRoutes],
    routes: [...orgSubRoutes],
  },
  {
    path: ["/depositAccounts"],
    component: DepositAccounts,
    // routes: [...orgSubRoutes, ...compSubRoutes],
    routes: [...depositAccountsRoutes],
  },
  {
    path: ["/depositAccounts/ledger"],
    component: DepositAccountsLedger,
    // routes: [...orgSubRoutes, ...compSubRoutes],
    routes: [...depositAccountsRoutes],
  },

  // {
  //   path: ["/undepositedChecks"], //Undeposited Checks route
  //   component: UndepositedChecks,
  //   exact: true,
  // },
  {
    path: ["/accessRestricted"], // Access Restricted
    component: AccessRestricted,
    exact: true,
  },
  // {
  //   path: ["/notifications"], //Undeposited Checks route
  //   component: Notifications,
  //   exact: true,
  // },

  /*   {
    path: ["/importErrors/:companyId"], //Deposited Checks route
    component: CompanyPendingChecks,
    exact: true,
  },
  {
    path: ["/importErrors/"], //Deposited Checks route
    component: CompanyPendingChecks,
    exact: true,
  }, */
  //example url /organizations/aKdWLxWAlB/ledger/xJXPJOY492/checks/7dWKyZqnz8
  {
    path: ["/check/:checkId"],
    component: VCheckPage,
    exact: true,
  },
];

if (import.meta.env.MODE === "development") {
  _appRoutes = [
    ..._appRoutes,

    {
      path: ["/devlogin"],
      component: DevLogin,
    },
  ];
}

const appRoutes: RouteConfig[] = [
  { path: "/", exact: true, component: () => <Redirect to="/org" /> },
  { path: "/access-restricted", exact: true, component: () => <AccessRestricted /> },
  {
    path: "/logout",
    exact: true,
    component: () => {
      (window as any).location = "/login";
      return null; //<Redirect to="/login" />;
    },
  },
  {
    path: ["/depositAccounts", "/depositAccounts/legder", "/organization-accounts", "/depositAccounts/access-denied"],
    component: DepositAccountsLayout,
    routes: _appRoutes,
  },
  {
    path: ["/guest/:hash/collect"],
    component: PaymentOptions,
  },
  {
    path: ["/live-stats"],
    component: LiveStats,
    exact: true,
  },
  { path: "/account", exact: true, component: () => <Redirect to="/account/profile" /> },
  {
    path: ["/account/rules"],
    component: AutoDepositRules,
    exact: true,
  },
  {
    path: ["/account/profile"],
    component: ProfileSettings,
    exact: true,
  },
  {
    path: ["/org/:organizationId/integrations"],
    component: IntegrationsPage,
    exact: true,
  },
  {
    path: [
      "/org/:organizationId/company/:companyId",
      "/org/:organizationId/company",
      "/org/:organizationId",
      "/org",
      "/",
      "*",
    ],
    component: Layout,
    routes: _appRoutes,
  },
];

export { appRoutes };
