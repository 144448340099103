import { graphqlVBillClient } from "common/graphqlClient";
import { Decimal } from "common/helpers/decimal";
import {
  getSdk,
  IVBillAdditionalMappingInput,
  IVBillInvoiceIncomingStatus,
  IVBillIStoreIntDataType,
  IVBillLineItemInput,
  IVBillNullableInvoiceInput,
  IVBillRelatedInvoiceSchema,
  IVBillVBillInvoiceQuery,
} from "generated/sdk.vbill";
import { debounce, isNil } from "lodash";
import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, SubmitHandler, UnpackNestedValue, useFormContext, useFormState } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { VBillIncomingPath, VBillNewPath, VBillSummaryPath } from "routes/routes";
import { useStore } from "storeContainer";
import { useVBillCodingLedgerQuickFilterUrl } from "../VBillCodingLedger/hooks";
import { VBillCodingLedgerChildRoutes } from "../VBillCodingLedger/utils";
import { TVBillFormFields } from "./types";

const { VBillValidateInvoice, VBillCheckInvoiceForDuplicates } = getSdk(graphqlVBillClient);

const invoiceDateMoreThenFieldErrorMsg = "Due should be more then Issued.";

// Extended SubmitHandler type
export declare type ExtendedSubmitHandler<TFieldValues extends FieldValues, TAdditionalArg> = (
  data: UnpackNestedValue<TFieldValues>,
  event?: BaseSyntheticEvent,
  additionalArg?: TAdditionalArg,
) => any | Promise<any>;

export function adaptIncomingInvoiceToInputType(
  formFields: TVBillFormFields,
  invoiceId: number,
): IVBillNullableInvoiceInput {
  const {
    amount,
    invoiceDate,
    invoiceDateDue,
    invoiceNumber,
    senderName,
    senderEmail,
    senderAddress,
    senderPhone,
    mainFile,
    lineItems,
  } = formFields;

  const lineItemsInput: IVBillLineItemInput[] = (lineItems ?? []).map(
    ({ amount, description, lineItemId, qty, rate, uid }, index) => {
      return {
        amount: amount.length ? new Decimal(amount).toFixed(2) : "",
        description: description.length ? description : "",
        qty: qty.length ? new Decimal(qty).toNumber() : undefined,
        rate: rate.length ? new Decimal(rate).toFixed(2) : undefined,
        invoiceId,
        uid: uid.length ? uid : undefined,
        id: lineItemId.length ? Number(lineItemId) : undefined,
        orderIndex: index,
      };
    },
  );

  const invoiceData: IVBillNullableInvoiceInput = {
    amount: amount.length ? new Decimal(amount).toFixed(2) : undefined,
    invoiceDate: invoiceDate?.length ? invoiceDate : undefined,
    invoiceDateDue: invoiceDateDue?.length ? invoiceDateDue : undefined,
    invoiceNumber: invoiceNumber.length ? invoiceNumber : undefined,
    senderName: senderName.length ? senderName : undefined,
    senderEmail: senderEmail.length ? senderEmail : undefined,
    senderAddress: senderAddress.length ? senderAddress : undefined,
    senderPhone: senderPhone.length ? senderPhone : undefined,
    mainFileId: mainFile?.value ? mainFile.value : undefined,
    lineItems: lineItemsInput.length ? lineItemsInput : undefined,
  };

  return invoiceData;
}

export function useUpdateIncomingVBillForm(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillStore = useStore("VBillStore");
  const [isReqPending, setIsReqPending] = useState(false);
  const formMethods = useFormContext<TVBillFormFields>();
  const [totalDifferenceAlertVisible, setTotalDifferenceAlertVisible] = useState(false);

  const handleFormSubmitWithCustomValidation: SubmitHandler<TVBillFormFields> = async (formFields) => {
    const invoiceDateTime = new Date(formFields.invoiceDate ?? 0).getTime();
    const invoiceDateDueTime = new Date(formFields.invoiceDateDue ?? 0).getTime();

    if (invoiceDateDueTime < invoiceDateTime) {
      return formMethods.setError("invoiceDateDue", {
        type: "custom",
        message: invoiceDateMoreThenFieldErrorMsg,
      });
    }

    const totalFormItemsAmount = (formFields.lineItems ?? []).reduce(
      (acc, val) => (!isNil(val.amount) ? Decimal.add(acc, val.amount || 0).toNumber() : acc),
      0,
    );

    if (totalFormItemsAmount !== Number(formFields.amount ?? 0)) {
      setTotalDifferenceAlertVisible(true);

      return;
    }

    formMethods.handleSubmit(handleFormSubmitWithNewTotalAmount)();
  };

  const handleFormSubmitWithNewTotalAmount: ExtendedSubmitHandler<TVBillFormFields, string> = async (
    formFields,
    _event,
    newTotalAmount,
  ) => {
    setIsReqPending(true);

    if (totalDifferenceAlertVisible) {
      setTotalDifferenceAlertVisible(false);
    }

    const formFieldsUpdated = { ...formFields, ...(!isNil(newTotalAmount) ? { amount: newTotalAmount } : {}) };
    const invoiceData = adaptIncomingInvoiceToInputType(formFieldsUpdated, invoice?.id ?? 0);
    const resp = await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoice?.id ?? 0,
      invoiceData,
      skipAdditionalMappingsValidations: true,
    });

    setIsReqPending(false);

    if (resp) {
      const navigateToUrl = `/org/${organizationId}/vbill/${invoice?.id ?? ""}/new`;

      history.push(navigateToUrl);
    }
  };

  return {
    handleFormSubmitWithNewTotalAmount: (newTotalAmount: string) =>
      formMethods.handleSubmit((...args) => handleFormSubmitWithNewTotalAmount(...args, newTotalAmount))(),
    handleFormSubmitWithCustomValidation: formMethods.handleSubmit(handleFormSubmitWithCustomValidation),
    isReqPending,
    totalDifferenceAlertVisible,
    handleCloseTotalDifferenceAlert: () => setTotalDifferenceAlertVisible(false),
  };
}

export function useUpdateNewVBillForm(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const history = useHistory();
  const { organizationId } = useParams<{ organizationId: string }>();
  const vBillStore = useStore("VBillStore");
  const [isReqPending, setIsReqPending] = useState(false);
  const formMethods = useFormContext<TVBillFormFields>();
  const vbillCodingLedgerCodingUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);
  const [showDuplicatesModal, setShowDuplicatesModal] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<IVBillRelatedInvoiceSchema[]>([]);

  const handleFormSubmit: ExtendedSubmitHandler<TVBillFormFields, boolean> = async (
    formData,
    _event,
    withStartApproval,
  ) => {
    const {
      invoiceDate,
      invoiceDateDue,
      invoiceNumber,
      senderName,
      senderEmail,
      senderAddress,
      senderPhone,
      mainFile,
      lineItems,
      ...additionalMapping
    } = formData;

    setIsReqPending(true);

    const lineItemsInput: IVBillLineItemInput[] = (lineItems ?? []).map(
      ({ amount, description, lineItemId, qty, rate, uid, ...glMappings }, index) => {
        const additionalMappingInput: IVBillAdditionalMappingInput[] = (
          Object.keys(glMappings) as IVBillIStoreIntDataType[]
        )
          .filter((add) => Object.values(IVBillIStoreIntDataType).find((key) => key === add)) // remove extra metaData & highlighted
          .map((mappingType) => {
            const currentAdditionalMapping = glMappings[mappingType];

            return {
              type: mappingType,
              key: currentAdditionalMapping?.addMappingSettingKey ?? "",
              value: currentAdditionalMapping?.key ?? "",
            };
          })
          .filter(({ key, value }) => !!key.length && !!value.length); // remove empty values, temp

        return {
          amount: amount.length ? new Decimal(amount).toFixed(2) : "",
          description: description.length ? description : "",
          qty: qty.length ? new Decimal(qty).toNumber() : undefined,
          rate: rate.length ? new Decimal(rate).toFixed(2) : undefined,
          invoiceId: invoice?.id ?? 0,
          uid: uid.length ? uid : undefined,
          id: lineItemId.length ? Number(lineItemId) : undefined,
          orderIndex: index,
          glMappings: additionalMappingInput.length ? [{ additionalMapping: additionalMappingInput }] : undefined,
        };
      },
    );
    const additionalMappingInput: IVBillAdditionalMappingInput[] = (
      Object.keys(additionalMapping) as IVBillIStoreIntDataType[]
    )
      .filter((add) => Object.values(IVBillIStoreIntDataType).find((key) => key === add)) // remove extra field meta
      .map((mappingType) => {
        const currentAdditionalMapping = additionalMapping[mappingType];

        return {
          type: mappingType,
          key: currentAdditionalMapping?.addMappingSettingKey ?? "",
          value: currentAdditionalMapping?.key ?? "",
        };
      })
      .filter(({ key, value }) => !!key.length && !!value.length); // remove empty values, temp

    const invoiceData: IVBillNullableInvoiceInput = {
      invoiceDate: invoiceDate.length ? invoiceDate : undefined,
      invoiceDateDue: invoiceDateDue.length ? invoiceDateDue : undefined,
      invoiceNumber: invoiceNumber.length ? invoiceNumber : undefined,
      senderName: senderName.length ? senderName : undefined,
      senderEmail: senderEmail.length ? senderEmail : undefined,
      senderAddress: senderAddress.length ? senderAddress : undefined,
      senderPhone: senderPhone.length ? senderPhone : undefined,
      mainFileId: mainFile?.value ? mainFile.value : undefined,
      // additionalMapping
      additionalMapping: additionalMappingInput.length ? additionalMappingInput : undefined,
      // line items
      lineItems: lineItemsInput.length ? lineItemsInput : undefined,
    };

    const validateInvoiceResp = await VBillValidateInvoice({ invoiceId: invoice?.id ?? 0, invoiceData });

    // invoice
    const invoiceRequiredFieldsAndMappingsError = [
      ...(validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.required ?? []),
      ...(validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.required ?? []),
    ];
    const invoiceCustomFieldsError =
      validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.custom?.fields ?? [];
    const invoiceCustomMappingsError =
      validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.custom?.fields ?? [];

    Object.keys(formData).forEach((fieldKey) => {
      if (invoiceCustomFieldsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "custom",
          message: validateInvoiceResp?.validateInvoice.validationErrors?.invoice.fields.custom?.message,
        });

        return;
      } else if (invoiceCustomMappingsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "custom",
          message: validateInvoiceResp?.validateInvoice.validationErrors?.invoice.mappings.custom?.message,
        });

        return;
      } else if (invoiceRequiredFieldsAndMappingsError.includes(fieldKey)) {
        formMethods.setError(fieldKey as keyof TVBillFormFields, {
          type: "required",
          message: `${fieldKey} is required.`,
        });
      }
    });

    // line items

    let lineItemsError = false;
    lineItems?.forEach((lineItem, index) => {
      const lineItemKeys = Object.keys(lineItem);

      for (const fieldKey of lineItemKeys) {
        const lineErrors = validateInvoiceResp.validateInvoice.validationErrors?.lineItems?.find(
          ({ key }) => key === lineItem.lineItemId,
        );

        if (!lineErrors) {
          break;
        }

        lineItemsError = true;

        const lineItemCustomFieldsError = lineErrors?.fields.custom?.fields;
        const lineItemCustomGLMappingsError = lineErrors?.glMapping.mappings.custom?.fields;
        const lineItemRequiredFieldsAndMappingsError = [
          ...(lineErrors?.fields.required ?? []),
          ...(lineErrors?.glMapping.mappings.required ?? []),
          ...(lineErrors?.glMapping.fields.required ?? []),
        ];

        if (lineItemCustomFieldsError?.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "custom",
            message: lineErrors?.fields.custom?.message,
          });

          break;
        } else if (lineItemCustomGLMappingsError?.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "custom",
            message: lineErrors?.glMapping.mappings.custom?.message,
          });

          break;
        } else if (lineItemRequiredFieldsAndMappingsError.includes(fieldKey)) {
          formMethods.setError(`lineItems.${index}.${fieldKey}` as unknown as keyof TVBillFormFields, {
            type: "required",
            message: `${fieldKey} is required.`,
          });
        }
      }
    });

    if (
      invoiceRequiredFieldsAndMappingsError.length ||
      invoiceCustomFieldsError.length ||
      invoiceCustomMappingsError.length ||
      lineItemsError
    ) {
      setIsReqPending(false);

      return;
    }

    const duplicatesResponse = await VBillCheckInvoiceForDuplicates({
      invoiceId: invoice?.id!,
      invoiceData,
    });

    if (duplicatesResponse.checkInvoiceForDuplicates.length) {
      setShowDuplicatesModal(true);
      setDuplicates(duplicatesResponse.checkInvoiceForDuplicates as IVBillRelatedInvoiceSchema[]);
      setIsReqPending(false);

      return;
    }

    const updateResponse = await vBillStore.updateInvoiceAndMappings({
      invoiceId: invoice?.id ?? 0,
      invoiceData,
      skipAdditionalMappingsValidations: false,
    });

    if (updateResponse?.status !== IVBillInvoiceIncomingStatus.Mapped) {
      setIsReqPending(false);

      return;
    }

    if (withStartApproval) {
      const startApprovalResponse = await vBillStore.startApprovalForInvoice({ invoiceId: invoice?.id ?? 0 });

      if (startApprovalResponse?.status !== IVBillInvoiceIncomingStatus.PendingApproval) {
        setIsReqPending(false);

        return;
      }
    }

    setIsReqPending(false);
    history.push(vbillCodingLedgerCodingUrl);
  };

  return {
    handleFormSubmit: (withStartApproval: boolean) =>
      formMethods.handleSubmit((...args) => handleFormSubmit(...args, withStartApproval))(),
    isReqPending,
    duplicates,
    showDuplicatesModal,
    hideDuplicatesModal: () => setShowDuplicatesModal(false),
  };
}

export enum VBillSummaryActionType {
  StartApproval,
  Pay,
}

export function useVBillStartApprovalAction(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const vBillStore = useStore("VBillStore");
  const [isReqPending, setIsReqPending] = useState(false);
  const history = useHistory();
  const vbillCodingLedgerCodingUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.CODING);

  const handleVbillStartApproval = async () => {
    setIsReqPending(true);

    const startApprovalResponse = await vBillStore.startApprovalForInvoice({ invoiceId: invoice?.id ?? 0 });

    if (startApprovalResponse?.status !== IVBillInvoiceIncomingStatus.PendingApproval) {
      setIsReqPending(false);

      return;
    }

    setIsReqPending(false);
    history.push(vbillCodingLedgerCodingUrl);
  };

  return {
    handleVbillStartApproval,
    isReqPending,
  };
}

export function useCurrentVBillRoute() {
  const isIncomingRoute = !!useRouteMatch(VBillIncomingPath);
  const isNewRoute = !!useRouteMatch(VBillNewPath);
  const isSummaryRoute = !!useRouteMatch(VBillSummaryPath);

  return {
    isIncomingRoute,
    isNewRoute,
    isSummaryRoute,
  };
}

export function useVBillDomElementWidth() {
  const domElementRef = useRef<HTMLDivElement | null>(null);
  const [elementWidth, setElementWidth] = useState<number | undefined>(undefined);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const firstEntryWidth = entries?.[0].borderBoxSize?.[0].inlineSize.toFixed(2) ?? "";
        debouncedSetElementWidth(Number(firstEntryWidth));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetElementWidth = useCallback(debounce(setElementWidth, 250), []);

  useEffect(() => {
    const element = domElementRef?.current;

    if (!element) {
      return;
    }

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      debouncedSetElementWidth.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    domElementRef,
    width: elementWidth,
  };
}

export function useVBillInvoiceVendorId(invoice?: IVBillVBillInvoiceQuery["invoice"] | null) {
  const { settings } = useStore("VBillStore");

  if (!invoice) {
    return false;
  }

  const contactKey = settings._dataObsevable?.vBill.additionalMappings.invoice.find((mapping) => mapping.isContact);

  if (!contactKey) {
    return false;
  }

  const invoiceMapping = invoice?.additionalMappings?.find((mapping) => mapping.key === contactKey.key);

  if (!invoiceMapping) {
    return false;
  }

  return invoiceMapping.value;
}

export function useLineItemsFormErrors() {
  const vBillStore = useStore("VBillStore");
  const { errors } = useFormState<TVBillFormFields>();
  const hasUpdateFormErrors = useMemo(() => !!Object.keys(errors?.lineItems ?? {}).length, [errors?.lineItems]);

  useEffect(() => {
    if (hasUpdateFormErrors) {
      vBillStore.setInvoiceLineItemsExpanded(true);
    }
  }, [vBillStore, hasUpdateFormErrors]);
}
