import { Box, InputProps, SxProps, TextField, Theme, Typography } from "@mui/material";
import { IVReimbursementFormFields } from "components/pages/VReimbursement/types";
import { FunctionComponent } from "react";
import { ControllerProps, useController } from "react-hook-form";

interface IFormInputFileProps {
  name: keyof IVReimbursementFormFields;
  sx?: SxProps<Theme>;
  InputProps?: Partial<InputProps>;
  rules?: ControllerProps<IVReimbursementFormFields>["rules"];
  label: string;
  multiple?: boolean;
}

// TODO: validation for pdf? and size
export const FormInputFile: FunctionComponent<IFormInputFileProps> = ({
  name,
  sx,
  InputProps,
  rules,
  label,
  multiple,
}) => {
  const {
    field: { onChange },
    fieldState: { error },
  } = useController<IVReimbursementFormFields>({ name, rules });

  return (
    <Box sx={{ marginBottom: "15px", ...sx }}>
      <Typography sx={{ marginBottom: "5px" }}>{label}</Typography>

      <TextField
        error={!!error}
        onChange={(e) => onChange((e.target as EventTarget & HTMLInputElement).files)}
        sx={{ width: "100%" }}
        InputProps={{ sx: { height: "32px" }, ...InputProps }}
        // label={label}
        helperText={error?.message}
        type="file"
        inputProps={{ multiple }}
      />
    </Box>
  );
};
