import { Typography } from "@mui/material";
import classNames from "classnames";
import { AddIcon } from "components/common/icons";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { useWatch } from "react-hook-form";
import { useStore } from "storeContainer";
import styles from "./widgetForm.module.scss";

interface IWidgetFormProps {
  onAddLineFieldBtnClick: (index: number, fieldName: keyof TVBillFormLineFields) => void;
  onAddInvoiceFieldBtnClick: (fieldName: keyof TVBillFormFields) => void;
  onAddNewLineBtnClick: () => void;
}

export const WidgetForm: FunctionComponent<IWidgetFormProps> = observer(
  ({ onAddLineFieldBtnClick, onAddInvoiceFieldBtnClick, onAddNewLineBtnClick }) => {
    const vBillStore = useStore("VBillStore");
    const vBillFormlineItemsObserver: TVBillFormLineFields[] = useWatch({ name: "lineItems" });

    const { invoiceLineItemsItemized } = vBillStore;

    return (
      <table className={styles.widgetTable}>
        <thead>
          <tr>
            <td colSpan={2}>
              <strong>
                <Typography marginBottom="10px" fontWeight={500}>
                  Apply to:
                </Typography>
              </strong>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderName")}>Sender Name</AddButton>
            </td>

            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderAddress")}>Sender Address</AddButton>
            </td>

            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderEmail")}>Sender Email</AddButton>
            </td>
            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("senderPhone")}>Sender Phone</AddButton>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("invoiceNumber")}>Invoice #</AddButton>
            </td>

            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("invoiceDate")}>Date Issued</AddButton>
            </td>

            <td colSpan={1}>
              <AddButton onClick={() => onAddInvoiceFieldBtnClick("invoiceDateDue")}>Invoice Due</AddButton>
            </td>
          </tr>
          <tr>
            <td colSpan={5}>
              <hr />
            </td>
          </tr>
          {vBillFormlineItemsObserver.map((_, index) => (
            <tr key={index}>
              <td colSpan={1}>Line {invoiceLineItemsItemized && `#${index + 1}`}</td>

              <td colSpan={1}>
                <AddButton onClick={() => onAddLineFieldBtnClick(index, "description")}>Description</AddButton>
              </td>
              <td colSpan={1}>
                <AddButton onClick={() => onAddLineFieldBtnClick(index, "qty")}>Qty</AddButton>
              </td>

              <td colSpan={1}>
                <AddButton onClick={() => onAddLineFieldBtnClick(index, "rate")}>Rate</AddButton>
              </td>
              <td colSpan={1}>
                <AddButton onClick={() => onAddLineFieldBtnClick(index, "amount")}>Total</AddButton>
              </td>
            </tr>
          ))}

          {invoiceLineItemsItemized && (
            <tr>
              <td />
              <td colSpan={2}>
                <AddButton onClick={onAddNewLineBtnClick} className={styles.addLineBtn}>
                  <AddIcon sx={{ fontSize: "15px" }} />
                </AddButton>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  },
);

interface IAddButtonProps {
  onClick: () => void;
  className?: string;
}

const AddButton: FunctionComponent<IAddButtonProps> = ({ children, onClick, className }) => (
  <button onClick={onClick} className={classNames(className, styles.addButton)}>
    {children}
  </button>
);
