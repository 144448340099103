import { normalizeIsoDateOnMidnight } from "common/helpers/dates";
import {
  IVBillInvoiceIncomingStatus,
  IVBillInvoiceLineItemMetaSchema,
  IVBillLineItemSchema,
  IVBillVBillInvoiceQuery,
} from "generated/sdk.vbill";
import { isNil } from "lodash";
import { TAdditionalMappings, TVBillFormFields, TVBillFormLineFields } from "./types";

export function getVBillRouteRedirectByStatus(
  organizationId: string,
  vbillId: string,
  search: string,
  status?: IVBillInvoiceIncomingStatus,
) {
  const leadingPath = `/org/${organizationId}/vbill/${vbillId}`;
  let newPath = leadingPath;

  switch (status) {
    case IVBillInvoiceIncomingStatus.Queued:
      newPath += "/incoming";
      break;
    case IVBillInvoiceIncomingStatus.New:
      newPath += "/new";
      break;
    case IVBillInvoiceIncomingStatus.Mapped:
    case IVBillInvoiceIncomingStatus.Approved:
    case IVBillInvoiceIncomingStatus.Declined:
    case IVBillInvoiceIncomingStatus.Deleted:
    case IVBillInvoiceIncomingStatus.History:
    case IVBillInvoiceIncomingStatus.PendingApproval:
      newPath += "/summary";
      break;
    default:
      newPath += "/incoming";
  }

  if (!!search.length) {
    newPath += search;
  }

  return newPath;
}

export function getVBillFormIncomingDefaultValues(
  invoice?: IVBillVBillInvoiceQuery["invoice"],
  mainFile?: IVBillVBillInvoiceQuery["invoice"]["mainFile"],
): TVBillFormFields | undefined {
  if (!invoice) {
    return undefined;
  }

  const selectedFileMetadata = invoice.files?.find(({ id }) => id === mainFile?.id)?.metadata?.GDAI;
  const lineItems =
    invoice.status === IVBillInvoiceIncomingStatus.Queued
      ? [
          {
            ...emptyOCRFormLineFields,
            description: "Bill total",
            qty: "1",
            rate: "0",
            amount: !isNil(selectedFileMetadata?.amount) ? `${selectedFileMetadata?.amount}` : "",
          },
        ]
      : invoice.lineItems?.length
      ? invoice.lineItems
          .sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0))
          .map((lineItem) => lineItemToFormLineFields(lineItem))
      : [emptyOCRFormLineFields]; // add one default line item
  const primaryValues = invoice.status === IVBillInvoiceIncomingStatus.Queued ? selectedFileMetadata : invoice;
  const invoiceDateDueValue =
    invoice.status === IVBillInvoiceIncomingStatus.Queued
      ? isNil(selectedFileMetadata?.invoiceDateDue)
        ? invoice?.invoiceDateDue
        : selectedFileMetadata?.invoiceDateDue
      : invoice?.invoiceDateDue;

  return {
    amount: !isNil(primaryValues?.amount) ? `${primaryValues?.amount}` : "",
    invoiceDate: primaryValues?.invoiceDate ? normalizeIsoDateOnMidnight(primaryValues.invoiceDate) : "",
    invoiceDateDue: invoiceDateDueValue ? normalizeIsoDateOnMidnight(invoiceDateDueValue) : "",
    invoiceNumber: primaryValues?.invoiceNumber ?? "",
    senderName: primaryValues?.senderName ?? "",
    senderEmail: primaryValues?.senderEmail ?? "",
    senderAddress: primaryValues?.senderAddress ?? "",
    senderPhone: primaryValues?.senderPhone ?? "",
    mainFile: mainFile ? { text: mainFile.name ?? "", value: mainFile.id } : null,
    lineItems,
    companyId: "",
  };
}

export function getVBillFormNewDefaultValues(
  invoice?: IVBillVBillInvoiceQuery["invoice"],
): TVBillFormFields | undefined {
  if (!invoice) {
    return undefined;
  }

  const currentAdditionalMappings = invoiceAdditionalMappingsToFormFields(invoice.additionalMappings);
  const currentLineItems: TVBillFormLineFields[] = (invoice.lineItems ?? [])
    .sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0))
    .map((lineItem) => ({
      ...lineItemToFormLineFields(lineItem),
      ...lineItemAdditionalMappingsToFormLineFields(lineItem),
    }));

  return {
    amount: invoice.amount ?? "",
    invoiceDate: invoice.invoiceDate ?? "",
    invoiceDateDue: invoice.invoiceDateDue ?? "",
    invoiceNumber: invoice.invoiceNumber ?? "",
    senderName: invoice.senderName ?? "",
    senderEmail: invoice.senderEmail ?? "",
    senderAddress: invoice.senderAddress ?? "",
    senderPhone: invoice.senderPhone ?? "",
    companyId: invoice.companyId ?? "",
    mainFile: invoice.mainFile ? { text: invoice.mainFile.name ?? "", value: invoice.mainFile.id } : null,
    lineItems: currentLineItems,
    ...currentAdditionalMappings,
  };
}

export const invoiceAdditionalMappingsToFormFields = (
  additionalMappings: IVBillVBillInvoiceQuery["invoice"]["additionalMappings"],
) =>
  (additionalMappings ?? [])
    .map(({ label, label2, parentLabel, type, value, key }) => ({
      addMappingSettingKey: key,
      key: value,
      type: type,
      label: label ?? "",
      label2: label2 ?? "",
      parentLabel: parentLabel ?? "",
    }))
    .reduce<TAdditionalMappings>((prev, curr) => ({ ...prev, [curr.type ?? ""]: curr }), {});

export const lineItemAdditionalMappingsToFormLineFields = (lineItem: IVBillLineItemSchema) =>
  (lineItem.glMappings?.[0]?.additionalMappings ?? [])
    .map(({ label, label2, parentLabel, type, value, key }) => ({
      addMappingSettingKey: key,
      key: value,
      type: type,
      label: label ?? "",
      label2: label2 ?? "",
      parentLabel: parentLabel ?? "",
    }))
    .reduce<TAdditionalMappings>((prev, curr) => ({ ...prev, [curr.type ?? ""]: curr }), {});

const lineItemToFormLineFields = (lineItem: IVBillLineItemSchema): TVBillFormLineFields => ({
  amount: !isNil(lineItem.amount) ? lineItem.amount : "",
  description: !isNil(lineItem.description) ? lineItem.description : "",
  qty: !isNil(lineItem.qty) ? `${lineItem.qty}` : "1",
  rate: !isNil(lineItem.rate) ? lineItem.rate : "0",
  highlighted: false,
  uid: !isNil(lineItem.uid) ? lineItem.uid : "__1001",
  lineItemId: !isNil(lineItem.id) ? `${lineItem.id}` : "__1002",
});

export const lineItemMetaToFormLineFields = (lineItem: IVBillInvoiceLineItemMetaSchema): TVBillFormLineFields => ({
  amount: !isNil(lineItem.amount?.value) ? `${lineItem.amount?.value}` : "",
  description: !isNil(lineItem.description?.value) ? `${lineItem.description?.value}` : "",
  qty: !isNil(lineItem.qty?.value) ? `${lineItem.qty?.value}` : "1",
  rate: !isNil(lineItem.rate?.value) ? `${lineItem.rate?.value}` : "0",
  highlighted: false,
  uid: !isNil(lineItem.uid) ? lineItem.uid : "__1001",
  lineItemId: "__1002",
});

export const emptyOCRFormLineFields: TVBillFormLineFields = {
  amount: "",
  description: "",
  qty: "1",
  rate: "0",
  highlighted: false,
  uid: "__1001",
  lineItemId: "__1002",
};
