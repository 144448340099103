import { Box } from "@mui/material";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillSearchFilter } from "components/pages/common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import {
  IStatusOption,
  VBillStatusFilter,
} from "components/pages/common/VBill/FilterComponents/VBillStatusFilter/VBillStatusFilter";
import {
  friendlyInvoiceActionRequiredStatusNames,
  friendlyInvoiceQuickFilterStatusNames,
} from "components/pages/common/VBill/utils";
import { IVBillInvoiceActionRequiredStatus, IVBillInvoiceQuickFilterStatus } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { useCodingLedgerFilters, useVBillCodingLedgerChildRoute } from "../hooks";
import { CodingSyncBtn } from "./CodingSyncBtn/CodingSyncBtn";

const codingStatusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceQuickFilterStatus)
  .filter((group) => group === IVBillInvoiceQuickFilterStatus.New || group === IVBillInvoiceQuickFilterStatus.Mapped)
  .map((value) => ({
    label: friendlyInvoiceQuickFilterStatusNames[value],
    value,
  }));
const actionsRequiredStatusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceActionRequiredStatus).map(
  (value) => ({
    label: friendlyInvoiceActionRequiredStatusNames[value],
    value,
  }),
);
const completedStatusFilterOptions: IStatusOption[] = Object.values(IVBillInvoiceQuickFilterStatus)
  .filter(
    (group) =>
      group === IVBillInvoiceQuickFilterStatus.PushDone ||
      group === IVBillInvoiceQuickFilterStatus.PushPending ||
      group === IVBillInvoiceQuickFilterStatus.Declined,
  )
  .map((value) => ({
    label: friendlyInvoiceQuickFilterStatusNames[value],
    value,
  }));

export const CodingListFilters = () => {
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();
  const { isCodingChildRoute, isActionRequiredChildRoute, isCompletedChildRoute } = useVBillCodingLedgerChildRoute();

  const handleCodingStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setCodingLedgerFilters((prev) => {
      const quickFilterStatusListValues = checked
        ? [...(prev.filters?.quickFilterStatusList ?? []), value]
        : [...(prev.filters?.quickFilterStatusList ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          amount_sort: undefined,
          vbill_number_sort: undefined,
          quickFilterStatusList: quickFilterStatusListValues.length
            ? (quickFilterStatusListValues as IVBillInvoiceQuickFilterStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  const handleActionsRequiredStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setCodingLedgerFilters((prev) => {
      const actionRequiredStatusList = checked
        ? [...(prev.filters?.actionRequiredStatusList ?? []), value]
        : [...(prev.filters?.actionRequiredStatusList ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          amount_sort: undefined,
          vbill_number_sort: undefined,
          actionRequiredStatusList: actionRequiredStatusList.length
            ? (actionRequiredStatusList as IVBillInvoiceActionRequiredStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  const handleCompletedStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setCodingLedgerFilters((prev) => {
      const quickFilterStatusListValues = checked
        ? [...(prev.filters?.quickFilterStatusList ?? []), value]
        : [...(prev.filters?.quickFilterStatusList ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          amount_sort: undefined,
          vbill_number_sort: undefined,
          quickFilterStatusList: quickFilterStatusListValues.length
            ? (quickFilterStatusListValues as IVBillInvoiceQuickFilterStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", gap: "10px", padding: "10px 0", alignItems: "center" }}
    >
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center" }}>
        <VBillSearchFilter
          term={codingLedgerFilters.filters?.search}
          onTermChange={(term) => {
            setCodingLedgerFilters((prev) => ({
              filters: {
                amount_sort: undefined,
                vbill_number_sort: undefined,
                search: term?.length ? term : undefined,
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }));
          }}
          placeholder="Search Bill#, cid"
        />

        {isCodingChildRoute && (
          <VBillStatusFilter
            status={codingLedgerFilters.filters?.quickFilterStatusList}
            multiSelect
            onStatusChange={handleCodingStatusFilterChange}
            options={codingStatusFilterOptions}
          />
        )}

        {isActionRequiredChildRoute && (
          <VBillStatusFilter
            status={codingLedgerFilters.filters?.actionRequiredStatusList}
            multiSelect
            onStatusChange={handleActionsRequiredStatusFilterChange}
            options={actionsRequiredStatusFilterOptions}
          />
        )}

        {isCompletedChildRoute && (
          <VBillStatusFilter
            status={codingLedgerFilters.filters?.quickFilterStatusList}
            multiSelect
            onStatusChange={handleCompletedStatusFilterChange}
            options={completedStatusFilterOptions}
          />
        )}

        <VBillAmountFilter
          amount={{
            amount_from: !isNil(codingLedgerFilters.filters?.amount_from)
              ? `${codingLedgerFilters.filters.amount_from}`
              : undefined,
            amount_to: !isNil(codingLedgerFilters.filters?.amount_to)
              ? `${codingLedgerFilters.filters.amount_to}`
              : undefined,
          }}
          onAmountChange={({ amount_from, amount_to }) =>
            setCodingLedgerFilters((prev) => ({
              filters: {
                ...prev.filters,
                amount_sort: undefined,
                vbill_number_sort: undefined,
                amount_from: Number(amount_from),
                amount_to: Number(amount_to),
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
        />

        <VBillDateFilter
          date={{
            date: codingLedgerFilters.filters?.date,
            date_to: codingLedgerFilters.filters?.date_to,
          }}
          onDateChange={({ date, date_to }) =>
            setCodingLedgerFilters((prev) => ({
              filters: { ...prev.filters, amount_sort: undefined, vbill_number_sort: undefined, date, date_to },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          btnText="Create date"
        />

        <VBillDateFilter
          date={{
            date: codingLedgerFilters.filters?.date_due_from,
            date_to: codingLedgerFilters.filters?.date_due_to,
          }}
          onDateChange={({ date, date_to }) =>
            setCodingLedgerFilters((prev) => ({
              filters: {
                ...prev.filters,
                amount_sort: undefined,
                vbill_number_sort: undefined,
                date_due_from: date,
                date_due_to: date_to,
              },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          btnText="Due date"
        />
      </Box>

      <CodingSyncBtn />
    </Box>
  );
};
