import { Box, Typography } from "@mui/material";
import { TVBillFormFields } from "components/pages/VBill/types";
import { useMemo } from "react";
import { useFormState } from "react-hook-form";

export const VBillToolbarFormErrors = () => {
  const { errors } = useFormState<TVBillFormFields>();

  const hasUpdateFormErrors = useMemo(() => !!Object.keys(errors).length, [errors]);

  if (!hasUpdateFormErrors) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "end" }}>
      <Typography sx={{ color: "#B00020" }}>Please fix errors</Typography>
    </Box>
  );
};
