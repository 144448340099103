import { Box } from "@mui/material";
import { VBillAdditionalMappingsFilter } from "components/pages/common/VBill/FilterComponents/VBillAdditionalMappingsFilter/VBillAdditionalMappingsFilter";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillCompanyListFilter } from "components/pages/common/VBill/FilterComponents/VBillCompanyListFilter/VBillCompanyListFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillRelativeDueDateFilter } from "components/pages/common/VBill/FilterComponents/VBillRelativeDueDateFilter/VBillRelativeDueDateFilter";
import {
  IVBillBillsFiltersMappingInput,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIStoreIntDataType,
} from "generated/sdk.vbill";
import { partition, pick } from "lodash";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { VBillSearchFilter } from "../../../../common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";

export const VBillsAvailableFilters = observer(function VBillsAvailableFilters() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const { batchFilters } = vBillBatchDetailsStore;

  const [vendorsMapping, restOfMappings] = useMemo(() => {
    const lineAdditionalMappings = (
      vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.data?.invoice ?? []
    ).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment,
    );

    return partition(lineAdditionalMappings, (map) => map.isContact);
  }, [vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.data?.invoice]);

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    const currentMappings = batchFilters.vbill_ava_fil?.mappings ?? [];
    const newMappings = currentMappings.find(({ key }) => key === value.key)
      ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
      : [...currentMappings, value];
    const newMappingsExcludeEmptyValuesIn = newMappings.filter(({ valuesIn }) => valuesIn.length > 0);

    vBillBatchDetailsStore.setBatchFilters((prev) => ({
      vbill_ava_fil: { ...prev.vbill_ava_fil, mappings: newMappingsExcludeEmptyValuesIn },
    }));
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", marginBottom: "5px" }}>
        <VBillSearchFilter
          smallHeight
          term={batchFilters.vbill_ava_fil?.search}
          onTermChange={(term) => {
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              vbill_ava_fil: { search: term?.length ? term : undefined },
              vbill_ava_pag: { page: 1, per_page: prev.vbill_ava_pag.per_page },
            }));
          }}
        />
        <VBillCompanyListFilter
          smallHeight
          actionsBtnSmallHeight
          companies={batchFilters.vbill_ava_fil?.companyIds ?? []}
          onCompaniesChange={async (values) =>
            await vBillBatchDetailsStore.setBatchFilters((prev) => ({
              vbill_ava_fil: { ...prev.vbill_ava_fil, companyIds: values },
            }))
          }
        />
        <VBillAmountFilter
          amount={{
            amount_from: batchFilters.vbill_ava_fil?.amount_from,
            amount_to: batchFilters.vbill_ava_fil?.amount_to,
          }}
          onAmountChange={({ amount_from, amount_to }) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              vbill_ava_fil: { ...prev.vbill_ava_fil, amount_from, amount_to },
            }))
          }
          smallHeight
          actionsBtnSmallHeight
        />
        <VBillDateFilter
          date={{
            date: batchFilters.vbill_ava_fil?.date,
            date_to: batchFilters.vbill_ava_fil?.date_to,
          }}
          onDateChange={({ date, date_to }) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              vbill_ava_fil: { ...prev.vbill_ava_fil, date, date_to },
            }))
          }
          smallHeight
          actionsBtnSmallHeight
        />
        {/* <VBillDueDateFilter
          smallHeight
          actionsBtnSmallHeight
          dueDate={{
            date_due_from: batchFilters.vbill_ava_fil?.date_due_from,
            date_due_to: batchFilters.vbill_ava_fil?.date_due_to,
          }}
          onDueDateChange={({ date_due_from, date_due_to }) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              vbill_ava_fil: { ...prev.vbill_ava_fil, date_due_from, date_due_to },
            }))
          }
        /> */}

        <VBillRelativeDueDateFilter
          btnSmallHeight
          dialogActionsBtnSmallHeight
          dates={pick(batchFilters.vbill_ava_fil, [
            "relativeReferenceDate",
            "relativeDateDueFrom",
            "relativeDateDueTo",
          ])}
          onDatesSubmit={async (dates) =>
            vBillBatchDetailsStore.setBatchFilters((prev) => ({
              vbill_ava_fil: { ...prev.vbill_ava_fil, ...dates },
            }))
          }
          btnText="Due date range"
          dialogTitle="Available Bills relative to "
        />

        {vendorsMapping.map(({ label, key, storeDataType }) => (
          <VBillAdditionalMappingsFilter
            key={key}
            storeType={storeDataType as unknown as IVBillIStoreIntDataType}
            storeKey={key}
            batchCompanyIds={vBillBatchDetailsStore.batchDetails.data?.batchFilters?.companyIds}
            searchPlaceholder={`Search ${label ?? ""}`}
            inputLabel={label ?? ""}
            mapping={batchFilters.vbill_ava_fil?.mappings?.find((mapping) => mapping.key === key)}
            onMappingChange={handleAdditionalMappingChange}
            btnSmallHeight
            actionsBtnSmallHeight
          />
        ))}

        {restOfMappings.map(({ label, key, storeDataType }) => (
          <VBillAdditionalMappingsFilter
            key={key}
            storeType={storeDataType as unknown as IVBillIStoreIntDataType}
            storeKey={key}
            batchCompanyIds={vBillBatchDetailsStore.batchDetails.data?.batchFilters?.companyIds}
            searchPlaceholder={`Search ${label ?? ""}`}
            inputLabel={label ?? ""}
            mapping={batchFilters.vbill_ava_fil?.mappings?.find((mapping) => mapping.key === key)}
            onMappingChange={handleAdditionalMappingChange}
            btnSmallHeight
            actionsBtnSmallHeight
          />
        ))}

        {/* <InBatchFilter />

        <InOtherBatchesFilter /> */}
      </Box>
    </Box>
  );
});
