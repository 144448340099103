import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { IVBillInvoiceRelationType, IVBillRelatedInvoiceSchema } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { COLORS } from "themes/default";
import { VBillStatus } from "../../common/VBillStatus/VBillStatus";

interface IDuplicatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  duplicates: IVBillRelatedInvoiceSchema[];
  title?: ReactNode;
  invoiceId: number;
}

export const DuplicatesModal: React.FunctionComponent<IDuplicatesModalProps> = observer(
  ({
    isOpen,
    onClose,
    duplicates,
    invoiceId,
    title = (
      <Typography variant="h2" sx={{ marginBottom: "10px" }}>
        Possible duplicates found
      </Typography>
    ),
    children,
  }) => {
    if (!duplicates.length) {
      return null;
    }

    return (
      <Modal
        open={isOpen}
        onClose={(e: any) => {
          e.stopPropagation();
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper
          sx={{ display: "flex" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box sx={{ boxShadow: 1, padding: "15px", maxHeight: "500px", overflowY: "scroll" }}>
            {title}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Sender</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Date due</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {duplicates.map((duplicate) => (
                  <InvoiceDuplicateRow invoiceId={invoiceId} duplicate={duplicate} />
                ))}
              </TableBody>
            </Table>
            {children}
          </Box>
        </Paper>
      </Modal>
    );
  },
);

interface IInvoiceDuplicateRowProps {
  duplicate: IVBillRelatedInvoiceSchema;
  invoiceId: number;
}

const duplicateTypeMap: { [key in IVBillInvoiceRelationType]: string } = {
  [IVBillInvoiceRelationType.DuplicateFiles]: "Contains duplicate files",
  [IVBillInvoiceRelationType.PotentialOcrDuplicateOf]: "Contains matching data",
  [IVBillInvoiceRelationType.DuplicateOf]: "Duplicate",
  [IVBillInvoiceRelationType.CopyOf]: "Copy Of",
};

const InvoiceDuplicateRow: React.FunctionComponent<IInvoiceDuplicateRowProps> = ({ duplicate, invoiceId }) => {
  return (
    <TableRow
      key={duplicate.invoice.id}
      sx={{ outline: invoiceId === duplicate.relation?.toInvoiceId ? `2px solid ${COLORS.black}` : undefined }}
    >
      <TableCell>
        <Box>Bill# {duplicate.invoice.invoiceNumber}</Box>
        <Box>cid: {duplicate.invoice.id}</Box>
      </TableCell>
      <TableCell>
        <Box>{duplicate.invoice.senderName}</Box>
        <Box>{duplicate.invoice.senderEmail}</Box>
      </TableCell>
      <TableCell>{formatCurrency(Number(duplicate.invoice.amount))}</TableCell>
      <TableCell>{getVBillFormattedDate(duplicate.invoice.invoiceDateDue)}</TableCell>
      <TableCell>
        <VBillStatus status={duplicate.invoice.status} pushStatus={duplicate.invoice.pushStatus} />
      </TableCell>
      <TableCell>{duplicate.relation?.type && duplicateTypeMap[duplicate.relation.type]}</TableCell>
      <TableCell>
        <Link to={`/org/${duplicate.invoice.coreOrgId}/vbill/${duplicate.invoice.id}`} target="_blank">
          <Button sx={{ "&:hover": { textDecoration: "underline" } }} endIcon={<OpenInNew />}>
            Details
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};
