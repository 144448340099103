import { Box, FormControl, FormHelperText, InputProps, SxProps, TextField, Theme, Typography } from "@mui/material";
import { TVBillFormFields } from "components/pages/VBill/types";
import { FunctionComponent, useMemo } from "react";
import { ControllerProps, useController } from "react-hook-form";
import { COLORS } from "themes/default";

interface IFormInputTextProps {
  name: keyof TVBillFormFields;
  sx?: SxProps<Theme>;
  InputProps?: Partial<InputProps>;
  rules?: ControllerProps<TVBillFormFields>["rules"];
  insideForm?: boolean;
  multiline?: boolean;
  label: string;
  pushedTop?: boolean;
}

export const FormInputText: FunctionComponent<IFormInputTextProps> = ({
  name,
  sx,
  InputProps,
  rules,
  insideForm,
  multiline,
  label,
  pushedTop,
}) => {
  const {
    field: { value, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController<TVBillFormFields>({ name, rules });
  const outsideFormTextValue = useMemo(() => (`${value}`.length ? value : "-"), [value]);

  return (
    <Box sx={{ ...sx, ...(pushedTop ? { marginBottom: "10px" } : {}) }}>
      <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>{label}</Typography>

      {insideForm ? (
        <TextField
          error={!!error}
          onChange={(e) => formFieldOnChange(e.target.value)}
          value={value ?? ""}
          size="small"
          sx={{ width: "100%" }}
          InputProps={InputProps}
          multiline={multiline}
        />
      ) : (
        <Typography sx={{ wordWrap: "break-word" }}>{outsideFormTextValue}</Typography>
      )}

      {error && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};
