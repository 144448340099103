import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import {
  friendlyPaymentIntegrationStatusNames,
  friendlyPaymentPushStatusNames,
  getPaymentIntegrationStatusStyleColor,
  getPaymentPushStatusStyleColor,
  getVBillFormattedDate,
} from "components/pages/common/VBill/utils";
import { IVBillPaymentIntegrationStatus, IVBillPaymentPushStatus, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { LogoMini } from "icons/svg/LogoMini";
import { isNil } from "lodash";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { COLORS } from "themes/default";
import { PaymentDetailsDrawer } from "../../../common/VBill/PaymentDetails/PaymentDetailsDrawer";

const defaultTableCellSx = {
  padding: "5px 16px",
  fontWeight: "500",
};

const defaultTableHeadCellSx = {
  padding: "10px 16px",
};

interface IBillPaymentsProps {
  invoice: IVBillVBillInvoiceQuery["invoice"];
}

export const BillPayments = ({ invoice }: IBillPaymentsProps) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search).toString(), [search]);
  const [billPaymentsDrawerPaymentId, setBillPaymentsDrawerPaymentId] = useState<number | undefined>();
  const [billPaymentDrawerVisible, setBillPaymentDrawerVisible] = useState(false);

  const { payments } = invoice;

  return (
    <>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ cursor: "default" }}>
                <TableCell sx={defaultTableHeadCellSx}>Payment #</TableCell>
                <TableCell sx={defaultTableHeadCellSx}>Date</TableCell>
                <TableCell sx={{ ...defaultTableHeadCellSx, textAlign: "right" }}>Amount</TableCell>
                <TableCell sx={defaultTableHeadCellSx}>Memo</TableCell>
                <TableCell sx={defaultTableHeadCellSx}>Status</TableCell>
                <TableCell sx={defaultTableHeadCellSx} />
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.length ? (
                payments.map(
                  ({
                    paymentId,
                    paymentAmount,
                    paymentIntegrationStatus,
                    paymentPushStatus,
                    paymentDate,
                    memo,
                    submittedByUserAccount,
                  }) => (
                    <TableRow key={paymentId} sx={{ cursor: "default" }}>
                      <TableCell sx={{ ...defaultTableCellSx, color: COLORS.newSecondaryColor }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          #{paymentId}{" "}
                          {submittedByUserAccount && (
                            <Tooltip arrow title="Created from Cherry">
                              <span>
                                <LogoMini sx={{ fontSize: "12px" }} />
                              </span>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell sx={defaultTableCellSx}>{getVBillFormattedDate(paymentDate)}</TableCell>
                      <TableCell sx={{ ...defaultTableCellSx, textAlign: "right" }}>
                        <Box
                          sx={{
                            textDecoration:
                              paymentPushStatus === IVBillPaymentPushStatus.SubmittedError ||
                              paymentIntegrationStatus === IVBillPaymentIntegrationStatus.Voided
                                ? "line-through"
                                : "none",
                          }}
                        >
                          {formatCurrency(Number(paymentAmount))}
                        </Box>
                      </TableCell>
                      <TableCell sx={defaultTableCellSx}>{memo ?? "-"}</TableCell>
                      <TableCell sx={defaultTableCellSx}>
                        <Box
                          sx={{
                            color:
                              paymentPushStatus === IVBillPaymentPushStatus.SubmittedOk
                                ? getPaymentIntegrationStatusStyleColor(paymentIntegrationStatus)
                                : getPaymentPushStatusStyleColor(paymentPushStatus),
                          }}
                        >
                          {paymentPushStatus === IVBillPaymentPushStatus.SubmittedOk
                            ? friendlyPaymentIntegrationStatusNames[paymentIntegrationStatus]
                            : friendlyPaymentPushStatusNames[paymentPushStatus]}
                        </Box>
                      </TableCell>
                      <TableCell sx={defaultTableCellSx}>
                        {/* <Link to={`/org/${organizationId}/vbill/${invoice.id}/payment/${paymentId}?${searchParams}`}>
                      <Button sx={{ padding: 0, height: "24px", "&:hover": { textDecoration: "underline" } }}>
                        Details
                      </Button>
                    </Link> */}
                        <Button
                          onClick={() => {
                            setBillPaymentDrawerVisible(true);
                            setBillPaymentsDrawerPaymentId(paymentId);
                          }}
                          sx={{
                            padding: 0,
                            height: "24px",
                            fontSize: "12px",
                            fontWeight: "500",
                            color: COLORS.newSecondaryColor,
                            "&:hover": { textDecoration: "underline" },
                          }}
                        >
                          Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ),
                )
              ) : (
                <TableRow>
                  <TableCell sx={defaultTableCellSx} colSpan={6}>
                    <Typography sx={{ fontSize: "16px", textAlign: "center" }}> No Payments</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {billPaymentDrawerVisible && !isNil(billPaymentsDrawerPaymentId) && (
        <PaymentDetailsDrawer
          paymentId={billPaymentsDrawerPaymentId}
          onDrawerClose={() => {
            setBillPaymentDrawerVisible(false);
            setBillPaymentsDrawerPaymentId(undefined);
          }}
        />
      )}
    </>
  );
};
