import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { useStore } from "storeContainer";
import {
  VBillLineItemsAccordian,
  VBillLineItemsAccordianDetails,
  VBillLineItemsAccordianSummary,
} from "../../common/VBillLineItemsAccordian/VBillLineItemsAccordian";
import { useLineItemsFormErrors } from "../../hooks";
import { TVBillFormFields } from "../../types";
import { NewLineItem } from "./NewLineItem/NewLineItem";
import { NewSelectMappings } from "./NewSelectMappings/NewSelectMappings";

export const NewLineItems: FunctionComponent = observer(() => {
  const vBillStore = useStore("VBillStore");
  const { fields } = useFieldArray<TVBillFormFields>({ name: "lineItems" });

  useLineItemsFormErrors();

  useEffect(() => {
    vBillStore.setInvoiceLineItemsExpanded(true);
  }, [vBillStore]);
  const { invoiceLineItemsExpanded } = vBillStore;

  return (
    <VBillLineItemsAccordian
      expanded={invoiceLineItemsExpanded}
      onChange={() => vBillStore.setInvoiceLineItemsExpanded(!invoiceLineItemsExpanded)}
    >
      <VBillLineItemsAccordianSummary
        accordianExpanded={invoiceLineItemsExpanded}
        headingTotalLineItems={fields.length}
      />
      <VBillLineItemsAccordianDetails>
        {(fields.length ?? 1) > 1 && <NewSelectMappings />}

        <TableContainer sx={{ marginBottom: "20px" }}>
          <Table>
            <TableHead>
              <TableRow />
            </TableHead>

            <TableBody>
              {fields.map((field, index) => (
                <NewLineItem key={field.id} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </VBillLineItemsAccordianDetails>
    </VBillLineItemsAccordian>
  );
});
