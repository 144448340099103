import { Box, Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { useState } from "react";
import { DueDateFilterPopover, DueDateValues } from "./DueDateFilterPopover/DueDateFilterPopover";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

interface IVBillDueDateFilterProps {
  dueDate: DueDateValues;
  onDueDateChange: (values: DueDateValues) => void;
  smallHeight?: boolean;
  actionsBtnSmallHeight?: boolean;
  showDueDateSimulator?: boolean;
  sx?: SxProps<Theme>;
  btnText?: string;
}

export const VBillDueDateFilter = ({
  dueDate,
  onDueDateChange,
  smallHeight,
  actionsBtnSmallHeight,
  showDueDateSimulator,
  sx,
  btnText,
}: IVBillDueDateFilterProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const popoverVisible = Boolean(popoverAnchorEl);
  const popoverId = popoverVisible ? "amount-filter" : undefined;

  return (
    <Box sx={sx}>
      {/* <InputLabel variant="standard">Date due</InputLabel> */}
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        aria-describedby={popoverId}
        variant="outlined"
        endIcon={popoverVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
        sx={{ ...(smallHeight ? { height: "30px" } : {}) }}
      >
        {btnText ?? "Due Date"}
      </Button>

      {popoverVisible && (
        <DueDateFilterPopover
          actionsBtnSmallHeight={actionsBtnSmallHeight}
          showDueDateSimulator={showDueDateSimulator}
          dueDate={dueDate}
          onDueDateChange={onDueDateChange}
          popoverId={popoverId}
          popoverAnchorEl={popoverAnchorEl}
          onClosePopover={() => setPopoverAnchorEl(null)}
        />
      )}
    </Box>
  );
};
