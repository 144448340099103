import { IVBillVBillInvoicesQuery } from "generated/sdk.vbill";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getVBillFormattedDate, getVBillUrl } from "./utils";

export function useVBillUrl(invoice?: Pick<IVBillVBillInvoicesQuery["invoices"]["items"][number], "id" | "status">) {
  const { organizationId } = useParams<{ organizationId: string }>();

  if (!invoice) {
    return "";
  }

  return getVBillUrl(organizationId, invoice.id, invoice.status);
}

export function useVBillFormattedDate(dateString?: string | null) {
  const formattedDate = useMemo(() => {
    return getVBillFormattedDate(dateString);
  }, [dateString]);

  return formattedDate;
}
