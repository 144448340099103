import { Box, Button, Checkbox, ListItemText, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { isArray } from "lodash";
import { useState } from "react";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

export interface IStatusOption {
  label: string;
  value: string;
}

interface IVBillStatusFilterProps {
  options: IStatusOption[];
  onStatusChange: (status: string, checked?: boolean) => void;
  status?: (string | null)[] | string | null;
  multiSelect?: boolean;
  smallHeight?: boolean;
  sx?: SxProps<Theme>;
}

export const VBillStatusFilter = ({
  options,
  status,
  onStatusChange,
  multiSelect,
  smallHeight,
  sx,
}: IVBillStatusFilterProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (value: string, checked: boolean) => {
    onStatusChange(value, checked);
    closeMenu();
  };

  const menuVisible = Boolean(menuAnchorEl);

  return (
    <Box sx={sx}>
      <Button
        id="vbill-status-menu-button"
        aria-controls={menuVisible ? "vbill-status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuVisible ? "true" : undefined}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        variant={status ? "containedFilters" : "outlined"}
        endIcon={menuVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
        sx={{ ...(smallHeight ? { height: "30px" } : {}) }}
      >
        Status
      </Button>

      {menuVisible && (
        <Menu
          id="vbill-status-menu"
          anchorEl={menuAnchorEl}
          open
          onClose={closeMenu}
          MenuListProps={{
            "aria-labelledby": "vbill-status-menu-button",
          }}
        >
          {options.map(({ label, value }) => (
            <MenuItem
              key={value}
              onClick={() =>
                handleMenuItemClick(value, multiSelect && isArray(status) ? !status.includes(value) : status !== value)
              }
              selected={multiSelect && isArray(status) ? status.includes(value) : status === value}
              sx={{ padding: "12px 16px" }}
            >
              {multiSelect && (
                <Checkbox
                  sx={{ marginRight: "10px" }}
                  checked={multiSelect && isArray(status) ? status.includes(value) : status === value}
                />
              )}
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};
