import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { processJsDateToIsoOnMidnight } from "common/helpers/dates";
import { getVBillFormattedDate } from "components/pages/common/VBill/utils";
import { isValid } from "date-fns";
import { isNil } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { ControllerProps, useController, useFormContext } from "react-hook-form";
import { COLORS } from "themes/default";
import { TVBillFormFields } from "../../../types";

interface IFormInputDateProps {
  name: keyof TVBillFormFields;
  rules?: ControllerProps<TVBillFormFields>["rules"];
  insideForm?: boolean;
  label: string;
  pushedTop?: boolean;
}

export const FormInputDate: FunctionComponent<IFormInputDateProps> = ({
  name,
  rules,
  insideForm,
  label,
  pushedTop,
}) => {
  const formMethods = useFormContext<TVBillFormFields>();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<TVBillFormFields>({
    name,
    rules,
  });
  const [localMuiDate, setLocalMuiDate] = useState<Date | null>(null);
  const [localMuiTextFieldValue, setLocalMuiTextFieldValue] = useState("");

  useEffect(() => {
    const muiDate = (value as string | undefined)?.length === 0 ? null : new Date(value as string);

    setLocalMuiDate(muiDate);
  }, [value]);

  useEffect(() => {
    if (!insideForm) {
      return;
    }

    if (isNil(localMuiDate)) {
      onChange("");

      return;
    }

    if (isValid(localMuiDate) && localMuiTextFieldValue.length === 10) {
      const fieldValue = processJsDateToIsoOnMidnight(localMuiDate.toString());

      onChange(fieldValue);
      formMethods.clearErrors(name);

      return;
    }

    formMethods.setError(name, { type: "custom", message: "Invalid Date" });
  }, [localMuiDate, localMuiTextFieldValue, insideForm]);

  const hasFieldError = !!error ? true : isValid(localMuiDate);

  return (
    <Box sx={{ ...(pushedTop ? { marginBottom: "10px" } : {}) }}>
      <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>{label}</Typography>

      {insideForm ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={localMuiDate}
            onChange={setLocalMuiDate}
            renderInput={(params) => {
              setLocalMuiTextFieldValue((params?.inputProps?.value as string) ?? "");

              return (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: "100%" }}
                  {...(!hasFieldError && {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Clear" arrow>
                            <IconButton onClick={() => setLocalMuiDate(null)} sx={{ marginRight: "-12px" }}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    },
                  })}
                />
              );
            }}
          />
        </LocalizationProvider>
      ) : (
        <Typography>{getVBillFormattedDate(value as string)}</Typography>
      )}

      {hasFieldError && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    </Box>
  );
};
