import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { COLORS } from "themes/default";
import { FormInputText } from "../Forms/FormInputText/FormInputText";

interface IVBillFromProps {
  insideForm?: boolean;
}

export const VBillFrom: FunctionComponent<IVBillFromProps> = ({ insideForm }) => {
  return (
    <Box sx={{ width: !insideForm ? "calc(33.3% - 27.5px)" : "calc(50% - 20.5px)" }}>
      <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
        FROM:
      </Typography>

      <FormInputText insideForm={insideForm} name="senderName" label="Sender Name:" pushedTop />

      <FormInputText insideForm={insideForm} name="senderAddress" label="Sender Address:" pushedTop />

      <FormInputText
        insideForm={insideForm}
        name="senderEmail"
        label="Sender Email:"
        rules={{ required: "Sender Email is required" }}
        pushedTop
      />

      <FormInputText insideForm={insideForm} name="senderPhone" label="Sender Phone:" pushedTop />
    </Box>
  );
};
