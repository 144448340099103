import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { ApprovalButtons } from "components/common/Approval/ApprovalButtons";
import { ArrowBackIosNewIcon } from "components/common/icons";
import {
  friendlyIntegrationStatusNames,
  friendlyInvoiceIncomingStatusNames,
  friendlyInvoicePushStatusNames,
} from "components/pages/common/VBill/utils";
import { useVBillCodingLedgerQuickFilterUrl } from "components/pages/VBillCodingLedger/hooks";
import { VBillCodingLedgerChildRoutes } from "components/pages/VBillCodingLedger/utils";
import { DelegationGroupScopeEnum } from "generated/sdk";
import {
  IVBillInvoiceIncomingStatus,
  IVBillInvoicePushStatus,
  IVBillInvoiceUserActionShowIn,
  IVBillInvoiceUserActionType,
  IVBillVBillInvoiceQuery,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { FunctionComponent, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useQueryParam } from "use-query-params";
import { VBillPayBillDialog } from "../../../common/VBill/VBillPayBillDialog/VBillPayBillDialog";
import {
  useCurrentVBillRoute,
  useUpdateIncomingVBillForm,
  useUpdateNewVBillForm,
  useVBillInvoiceVendorId,
  useVBillStartApprovalAction,
} from "../../hooks";
import { IInvoiceProps } from "../../types";
import { DuplicatesModal } from "../../VBillIncoming/Duplicates/DuplicatesModal";
import { DuplicatesModalSavingActions } from "../../VBillIncoming/Duplicates/DuplicatesModalSavingActions";
import { vBillSidebarPanelQSKey, vBillSidebarPanels } from "../../VBillSidebar/SidebarTabs/SidebarTabs";
import { ToolbarActionsMenu } from "./ToolbarActionsMenu";
import { ToolbarStatusBadge } from "./ToolbarStatusBadge";
import { VBillToolbarFormErrors } from "./VBillToolbarFormErrors/VBillToolbarFormErrors";
import { VBillToolbarTotalDifferentAlert } from "./VBillToolbarTotalDifferenceAlert/VBillToolbarTotalDifferenceAlert";

export const VBillToolbar: FunctionComponent<IInvoiceProps> = observer(({ invoice }) => {
  const vBillStore = useStore("VBillStore");
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const organizationStore = useStore("OrganizationStore");
  const history = useHistory();
  const { isIncomingRoute, isNewRoute, isSummaryRoute } = useCurrentVBillRoute();
  const {
    handleFormSubmitWithNewTotalAmount: handleUpdateIncomingFormSubmitWithNewTotalAmount,
    isReqPending: isUpdateIncomingReqPending,
    totalDifferenceAlertVisible,
    handleCloseTotalDifferenceAlert,
    handleFormSubmitWithCustomValidation: handleUpdateIncomingFormSubmitWithCustomValidation,
  } = useUpdateIncomingVBillForm(invoice);
  const {
    handleFormSubmit: handleUpdateNewFormSubmit,
    isReqPending: isUpdateNewReqPending,
    showDuplicatesModal,
    hideDuplicatesModal,
    duplicates,
  } = useUpdateNewVBillForm(invoice);
  const { handleVbillStartApproval, isReqPending: isStartApprovalReqPending } = useVBillStartApprovalAction(invoice);
  const vendorId = useVBillInvoiceVendorId(invoice);
  const [isPayBillDialogOpen, setIsPayBillDialogOpen] = useState(false);
  // const vbillPayLedgerUrl = useVBillPayLedgerQuickFilterUrl(VBillPayLedgerChildRoutes.ALL);
  const vbillCodingLedgerUrl = useVBillCodingLedgerQuickFilterUrl(VBillCodingLedgerChildRoutes.ALL);
  const [, setSidebarTabPanel] = useQueryParam<vBillSidebarPanels>(vBillSidebarPanelQSKey);
  const [isCreateNewReqLoading, setIsCreateNewReqLoading] = useState(false);
  const [isReSyncReqLoading, setIsReSyncReqLoading] = useState(false);
  const invoiceStatusName = useMemo(() => friendlyInvoiceIncomingStatusNames[invoice.status], [invoice.status]);
  const invoiceSyncTooltipText = useMemo(
    () => (invoice.pushStatus ? friendlyInvoicePushStatusNames[invoice.pushStatus] : null),
    [invoice.pushStatus],
  );

  const handleCreateNewBtnClick = async () => {
    setIsCreateNewReqLoading(true);
    await vBillStore.copyInvoice({ invoiceId: invoice.id });
    setIsCreateNewReqLoading(false);
  };

  const handleReSyncBtnClick = async () => {
    setIsReSyncReqLoading(true);
    await vBillStore.pushInvoice({ invoiceId: invoice.id });
    setIsReSyncReqLoading(false);
  };

  const handlePayBillSubmitEnd = async () => {
    await vBillStore.loadInvoice({ invoiceId: invoice?.id ?? 0 }, true);
    setSidebarTabPanel(vBillSidebarPanels.BILL_PAYMENTS);
  };

  const navigateToLedger = () => {
    const { ledgerRecordedNavigation } = vBillCodingLedgerStore;

    if (
      invoice?.status === IVBillInvoiceIncomingStatus.Approved &&
      invoice.pushStatus === IVBillInvoicePushStatus.Done
    ) {
      history.push(vbillCodingLedgerUrl);
    } else {
      if (ledgerRecordedNavigation) {
        history.push(ledgerRecordedNavigation);
      } else {
        history.push(vbillCodingLedgerUrl);
      }
    }
  };

  const handleApprovalButtonsReloadParent = () => {
    vBillStore.loadInvoice({ invoiceId: invoice.id }, true);
    history.push(vbillCodingLedgerUrl);
  };

  const handleSubmitBtnClick = (action: IVBillVBillInvoiceQuery["invoice"]["nextUserActions"][number]) => {
    if (action.type === IVBillInvoiceUserActionType.CompleteOcr) {
      handleUpdateIncomingFormSubmitWithCustomValidation();
    }

    if (isNewRoute && action.type === IVBillInvoiceUserActionType.CompleteCoding) {
      handleUpdateNewFormSubmit(false);
    }

    if (isNewRoute && action.type === IVBillInvoiceUserActionType.StartApproval) {
      handleUpdateNewFormSubmit(true);
    }

    if (isSummaryRoute && action.type === IVBillInvoiceUserActionType.StartApproval) {
      handleVbillStartApproval();
    }
  };

  const incomingRouteSubmitBtns = invoice.nextUserActions.filter(
    (action) =>
      action.showIn === IVBillInvoiceUserActionShowIn.InvoiceDetails &&
      action.type === IVBillInvoiceUserActionType.CompleteOcr,
  );
  const newRouteSubmitBtns = invoice.nextUserActions.filter(
    (action) =>
      action.showIn === IVBillInvoiceUserActionShowIn.InvoiceDetails &&
      (action.type === IVBillInvoiceUserActionType.CompleteCoding ||
        action.type === IVBillInvoiceUserActionType.StartApproval),
  );
  const summaryRouteSubmitBtns =
    invoice?.status === IVBillInvoiceIncomingStatus.Mapped
      ? invoice.nextUserActions.filter(
          (action) =>
            action.showIn === IVBillInvoiceUserActionShowIn.InvoiceDetails &&
            action.type === IVBillInvoiceUserActionType.StartApproval,
        )
      : [];
  const canAmountFreeToPayBill = Number(invoice?.amounts?.amountFreeToPay ?? 0) > 0;

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: COLORS.lightestGrey,
          padding: "10px",
          marginBottom: "10px",
          position: "sticky",
          top: "0",
          backgroundColor: "white",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Tooltip arrow title="Click to go back">
              <IconButton onClick={navigateToLedger} size="medium" sx={{ backgroundColor: "#EBF4FF" }}>
                <ArrowBackIosNewIcon color="primary" />
              </IconButton>
            </Tooltip>

            <Box>
              <Typography
                noWrap
                sx={{
                  maxWidth: "280px", // TODO:
                  fontSize: "22px",
                  fontWeight: "600",
                  color: COLORS.logoBlue,
                }}
                title={
                  isIncomingRoute
                    ? invoice.invoiceNumber
                      ? `Bill# ${invoice.invoiceNumber}`
                      : `cid: ${invoice.id ?? ""}`
                    : `Bill# ${invoice.invoiceNumber ?? ""}`
                }
              >
                {isIncomingRoute
                  ? invoice.invoiceNumber
                    ? `Bill# ${invoice.invoiceNumber}`
                    : `cid: ${invoice.id ?? ""}`
                  : `Bill# ${invoice.invoiceNumber ?? ""}`}
              </Typography>
              <Typography sx={{ fontSize: "12px", lineHeight: "12px", color: COLORS.textGray }}>
                cid: {invoice.id}
              </Typography>
            </Box>

            <ToolbarStatusBadge
              color={
                invoice?.status === IVBillInvoiceIncomingStatus.Declined ||
                invoice?.status === IVBillInvoiceIncomingStatus.Deleted ||
                invoice?.status === IVBillInvoiceIncomingStatus.Duplicate ||
                invoice?.status === IVBillInvoiceIncomingStatus.PendingApproval
                  ? "orange"
                  : "blue"
              }
            >
              {invoice.status !== IVBillInvoiceIncomingStatus.Approved ? invoiceStatusName : invoiceSyncTooltipText}
            </ToolbarStatusBadge>

            {isSummaryRoute && invoice.pushStatus === IVBillInvoicePushStatus.Error && (
              <LoadingButton
                loading={isReSyncReqLoading}
                onClick={handleReSyncBtnClick}
                variant="outlined"
                sx={{
                  height: "30px",
                  border: "1px solid rgba(40, 119, 236, 0.25) !important",
                  color: COLORS.newSecondaryColor,
                  fontWeight: "500",
                }}
              >
                Re sync
              </LoadingButton>
            )}

            {isSummaryRoute && invoice?.pushStatus && invoice.integrationStatus && (
              <Box>
                Status:{" "}
                <strong>
                  {invoice.pushStatus === IVBillInvoicePushStatus.Done
                    ? friendlyIntegrationStatusNames[invoice.integrationStatus]
                    : invoice.pushStatus}
                </strong>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent:
                isIncomingRoute || (isNewRoute && newRouteSubmitBtns.length === 1) || isSummaryRoute
                  ? "flex-end"
                  : "space-between",
              flexGrow: "1",
            }}
          >
            {isIncomingRoute &&
              incomingRouteSubmitBtns.map((action) => (
                <Tooltip arrow title={action.description}>
                  <LoadingButton
                    loading={isUpdateIncomingReqPending}
                    onClick={() => handleSubmitBtnClick(action)}
                    variant="contained"
                    sx={{ height: "32px" }}
                  >
                    {action.displayName}
                  </LoadingButton>
                </Tooltip>
              ))}

            {isNewRoute &&
              newRouteSubmitBtns.map((action) => (
                <Tooltip arrow title={action.description}>
                  <LoadingButton
                    loading={isUpdateNewReqPending}
                    onClick={() => handleSubmitBtnClick(action)}
                    variant={action.type === IVBillInvoiceUserActionType.CompleteCoding ? "outlined" : "contained"}
                    sx={{
                      ...(action.type === IVBillInvoiceUserActionType.CompleteCoding
                        ? {
                            border: "1px solid rgba(40, 119, 236, 0.25) !important",
                            color: COLORS.newSecondaryColor,
                            fontWeight: "500",
                          }
                        : {}),
                      height: "32px",
                    }}
                  >
                    {action.displayName}
                  </LoadingButton>
                </Tooltip>
              ))}

            {isSummaryRoute &&
              summaryRouteSubmitBtns.map((action) => (
                <Tooltip arrow title={action.description}>
                  <LoadingButton
                    loading={isStartApprovalReqPending}
                    onClick={() => handleSubmitBtnClick(action)}
                    variant="contained"
                    sx={{
                      height: "32px",
                    }}
                  >
                    {action.displayName}
                  </LoadingButton>
                </Tooltip>
              ))}
          </Box>

          {vendorId && (
            <ApprovalButtons
              scope={DelegationGroupScopeEnum.VbillApprove}
              entityParams={{
                organizationId: invoice.coreOrgId,
                companyId: invoice.company?.companyId,
                vendorId,
                billId: invoice.hashedId,
              }}
              approvalStatus={invoice.approvalStatus}
              showAssign={true}
              showApproval={isSummaryRoute && invoice?.status === IVBillInvoiceIncomingStatus.PendingApproval}
              showDecline={
                invoice?.status === IVBillInvoiceIncomingStatus.New ||
                invoice?.status === IVBillInvoiceIncomingStatus.Mapped ||
                invoice?.status === IVBillInvoiceIncomingStatus.PendingApproval
              }
              reloadParent={handleApprovalButtonsReloadParent}
            />
          )}

          {isSummaryRoute &&
            invoice?.status === IVBillInvoiceIncomingStatus.Approved &&
            organizationStore.organizationVBillPayLedgerEnabled && (
              <>
                {!organizationStore.organizationVBillPayBtnEnabled ? (
                  <Tooltip title="Payment must be enabled in integration">
                    <ErrorOutlineIcon sx={{ color: COLORS.orange, fontSize: "20px" }} />
                  </Tooltip>
                ) : canAmountFreeToPayBill && invoice.canBePaid ? (
                  <Button sx={{ height: "32px" }} onClick={() => setIsPayBillDialogOpen(true)} variant="contained">
                    Pay
                  </Button>
                ) : null}
              </>
            )}

          {!isIncomingRoute &&
            (invoice?.status === IVBillInvoiceIncomingStatus.New ||
              invoice?.status === IVBillInvoiceIncomingStatus.Mapped) && <ToolbarActionsMenu />}
        </Box>

        <VBillToolbarFormErrors />

        {invoice.displayError && (
          <Box sx={{ display: "flex", marginTop: "10px", justifyContent: "end" }}>
            <Typography sx={{ color: "#B00020" }}>{invoice.displayError.message}</Typography>
          </Box>
        )}
      </Box>

      {isPayBillDialogOpen && invoice && (
        <VBillPayBillDialog
          vBill={invoice}
          onSubmitEnd={handlePayBillSubmitEnd}
          onCloseDialog={() => setIsPayBillDialogOpen(false)}
        />
      )}

      {showDuplicatesModal && (
        <DuplicatesModal
          invoiceId={invoice.id}
          isOpen={showDuplicatesModal}
          onClose={() => hideDuplicatesModal()}
          duplicates={duplicates}
        >
          <DuplicatesModalSavingActions
            invoice={invoice}
            onClose={() => hideDuplicatesModal()}
            reloadInvoicesList={() => vBillStore.invoice?.reload?.()}
          />
        </DuplicatesModal>
      )}

      {totalDifferenceAlertVisible && (
        <VBillToolbarTotalDifferentAlert
          onCloseDialog={handleCloseTotalDifferenceAlert}
          onSubmitDialog={handleUpdateIncomingFormSubmitWithNewTotalAmount}
        />
      )}
    </>
  );
});
