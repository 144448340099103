import { Box, Typography } from "@mui/material";
import { invoiceAdditionalMappingsToFormFields } from "components/pages/VBill/utils";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
  IVBillVBillInvoiceQuery,
  IVBillVBillSettingsQuery,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { COLORS } from "themes/default";

interface ISummaryInvoiceMappingsProps {
  vBillData: IVBillVBillInvoiceQuery["invoice"];
  vBillAdditionalMappingsSettings?: IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"];
}

export const SummaryInvoiceMappings = observer(function SummaryInvoiceMappings({
  vBillData,
  vBillAdditionalMappingsSettings,
}: ISummaryInvoiceMappingsProps) {
  const invoiceData = vBillData;
  const invoiceMappings = useMemo(
    () => invoiceAdditionalMappingsToFormFields(invoiceData?.additionalMappings),
    [invoiceData?.additionalMappings],
  );
  const invoiceMappingsSettingsData = useMemo(
    () =>
      (vBillAdditionalMappingsSettings?.invoice ?? []).filter(
        (additionalMapping) =>
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
      ),
    [vBillAdditionalMappingsSettings?.invoice],
  );

  return (
    <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px", flexWrap: "wrap" }}>
      {invoiceMappingsSettingsData.map(
        (additionalMapping: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput) => (
          <Box key={additionalMapping.storeDataType} sx={{ width: "calc(33.3% - 13.5px)" }}>
            <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
              {additionalMapping.label ?? additionalMapping.storeDataType}:
            </Typography>

            <Typography sx={{ wordBreak: "break-word" }}>{`${
              invoiceMappings[additionalMapping.storeDataType]?.label ?? "-"
            }${
              invoiceMappings[additionalMapping.storeDataType]?.label2
                ? ` - ${invoiceMappings[additionalMapping.storeDataType]?.label2}`
                : ""
            }`}</Typography>
          </Box>
        ),
      )}
    </Box>
  );
});
