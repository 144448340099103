import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { graphqlVBillClient } from "common/graphqlClient";
import { useVBillUrl } from "components/pages/common/VBill/hooks";
import { DuplicatesLoader } from "components/pages/VBill/VBillIncoming/Duplicates/DuplicatesLoader";
import { DuplicatesModalIncomingActions } from "components/pages/VBill/VBillIncoming/Duplicates/DuplicatesModalIncomingActions";
import { IVBillInvoicePushStatus, IVBillInvoiceRelationType } from "generated/graphql.vbill";
import {
  getSdk,
  IVBillInvoiceIncomingStatus,
  IVBillInvoiceUserActionShowIn,
  IVBillVBillInvoicesQuery,
} from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { useVBillCodingLedgerChildRoute } from "../../hooks";
import { defaultTableCellSx } from "../CodingVBill";

const { VBillPushInvoice } = getSdk(graphqlVBillClient);

interface IVBillActionsProps {
  invoice: IVBillVBillInvoicesQuery["invoices"]["items"][number];
}

export const VBillActions = observer(({ invoice }: IVBillActionsProps) => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const history = useHistory();
  const { isCompletedChildRoute } = useVBillCodingLedgerChildRoute();
  const vBillInvoiceUrl = useVBillUrl(invoice);
  const [isReSyncReqLoading, setIsReSyncReqLoading] = useState(false);

  const handleReSyncBtnClick = async () => {
    setIsReSyncReqLoading(true);

    try {
      await VBillPushInvoice({ invoiceId: invoice.id });
      handleReloadInvoicesList();
    } catch (_e) {
      vBillCodingLedgerStore.setShowVBillRequestErrorToast(true);
    } finally {
      setIsReSyncReqLoading(false);
    }
  };

  const handleReloadInvoicesList = () => {
    vBillCodingLedgerStore.invoicesList.reload?.();
  };

  const handleModalActionsStartCodingBtnClick = () => {
    history.push(vBillInvoiceUrl);
  };

  if (isCompletedChildRoute) {
    return null;
  }

  return (
    <TableCell sx={{ ...defaultTableCellSx, padding: "0px 16px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        {invoice.isPotentialDuplicate && invoice.status === IVBillInvoiceIncomingStatus.Queued && (
          <DuplicatesLoader
            // FIXME InvoiceSchema vs InvoiceLightSchema
            invoice={invoice as any}
            reloadInvoicesList={handleReloadInvoicesList}
            relationTypes={[
              IVBillInvoiceRelationType.DuplicateFiles,
              IVBillInvoiceRelationType.PotentialOcrDuplicateOf,
            ]}
            onModalActionsStartCodingBtnClick={handleModalActionsStartCodingBtnClick}
            ModalActionsComponent={DuplicatesModalIncomingActions}
          >
            {(loading, onClick) => (
              <Tooltip arrow title="This bill could be potential duplicate">
                <LoadingButton loading={loading} onClick={onClick} variant="outlined">
                  Possible duplicate
                </LoadingButton>
              </Tooltip>
            )}
          </DuplicatesLoader>
        )}

        {invoice.pushStatus === IVBillInvoicePushStatus.Error && (
          <LoadingButton
            loading={isReSyncReqLoading}
            onClick={handleReSyncBtnClick}
            variant="outlined"
            sx={{
              border: "1px solid rgba(40, 119, 236, 0.25) !important",
              color: COLORS.newSecondaryColor,
              fontWeight: "500",
            }}
          >
            Re sync
          </LoadingButton>
        )}

        {invoice.nextUserActions
          .filter((action) => action.showIn === IVBillInvoiceUserActionShowIn.InvoiceListing)
          .map((action) => (
            <Tooltip arrow title={action.description}>
              <Box component={Link} to={vBillInvoiceUrl}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid rgba(40, 119, 236, 0.25) !important",
                    color: COLORS.newSecondaryColor,
                    fontWeight: "500",
                  }}
                >
                  {action.displayName}
                </Button>
              </Box>
            </Tooltip>
          ))}
      </Box>
    </TableCell>
  );
});
