import { Box, FormControl, FormHelperText, Skeleton } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import {
  getSdk,
  IVBillFileInfoSchema,
  IVBillInvoiceIncomingStatus,
  IVBillKFileOcrState,
  IVBillVBillFilesOcrQuery,
  IVBillVBillFilesOcrQueryVariables,
  IVBillVBillInvoiceQuery,
} from "generated/sdk.vbill";
import { FunctionComponent, useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useStore } from "storeContainer";
import { FilesSelector, TVBillFileInfo } from "../../common/FilesSelector/FilesSelector";
import { TVBillFormFields } from "../../types";
import { getVBillFormIncomingDefaultValues } from "../../utils";
import { AttachmentsViewer } from "./AttachmentsViewer";

export interface IFileForViewer {
  id: number;
  name: string;
  files: IVBillFileInfoSchema[];
}

const { VBillFilesOcr } = getSdk(graphqlVBillClient);

const fetchFiles = (data: IVBillVBillFilesOcrQueryVariables) => VBillFilesOcr(data);

interface IAttachmentsProps {
  invoice: IVBillVBillInvoiceQuery["invoice"];
  enableOcr?: boolean;
}

export const Attachments: FunctionComponent<IAttachmentsProps> = ({ invoice, enableOcr }) => {
  const { id: invoiceId, files, coreOrgId } = invoice;
  const vBillStore = useStore("VBillStore");
  const sessionStore = useStore("SessionStore");
  const [imageFilesData, setImageFilesData] = useState<IVBillVBillFilesOcrQuery["files"]>();
  const [selectedPageInFile, setSelectedPageInFile] = useState<number>(1);
  const [isFetchFilesLoading, setIsFetchFilesLoading] = useState(false);
  const {
    field: { value: formFieldValue = null },
    fieldState: { error: formFieldError },
  } = useController({
    name: "mainFile",
    rules: { required: true },
  });
  const formMethods = useFormContext<TVBillFormFields>();
  const [selectedFile, setSelectedFile] = useState<TVBillFileInfo>();

  useEffect(() => {
    setSelectedFile(
      files?.find(
        ({ id }) =>
          id === (invoice.status === IVBillInvoiceIncomingStatus.Queued ? formFieldValue?.value : invoice.mainFile?.id),
      ),
    );
  }, [files, formFieldValue?.value, invoice.mainFile?.id, invoice.status]);

  useEffect(() => {
    if (
      !selectedFile ||
      selectedFile?.fileIsEncrypted ||
      selectedFile?.fileOcrState === IVBillKFileOcrState.OcrError ||
      selectedFile?.fileOcrState === IVBillKFileOcrState.Unknown ||
      selectedFile?.fileOcrState === IVBillKFileOcrState.OcrStartTriggered ||
      Boolean(selectedFile?.movedToInvoice?.id)
    ) {
      return;
    }

    setIsFetchFilesLoading(true);
    fetchFiles({
      filters: {
        coreOrgId,
        invoiceId,
        attachmentId: selectedFile?.id ?? 0,
      },
      pagination: { page: selectedPageInFile },
    }).then(({ files }) => {
      setImageFilesData(files);
      setIsFetchFilesLoading(false);
    });
  }, [selectedFile, selectedPageInFile, coreOrgId, invoiceId]);

  const handleChangeFileBtnClick = (file: TVBillFileInfo) => {
    if (file.movedToInvoice?.id) {
      return;
    }

    setSelectedPageInFile(1);

    if (invoice.status === IVBillInvoiceIncomingStatus.Queued) {
      const vBillFormDefaultValues = getVBillFormIncomingDefaultValues(
        invoice,
        file
          ? {
              id: file.id,
              name: file.name,
            }
          : undefined,
      );

      formMethods.reset(vBillFormDefaultValues);
      vBillStore.setInvoiceLineItemsExpanded(false);
      vBillStore.setInvoiceLineItemsItemized(false);
    } else {
      setSelectedFile(file);
    }
  };

  const canMoveAttachmentsToNewBill = sessionStore.acl.canMoveAttachmentToNewBill(invoice);

  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 156px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <FilesSelector
        files={files}
        selectedFile={selectedFile!}
        onChangeFileBtnClick={handleChangeFileBtnClick}
        reloadData={() => vBillStore.invoice.reload && vBillStore.invoice.reload({ markAsLoading: false })}
        canMoveAttachmentsToNewBill={canMoveAttachmentsToNewBill}
      />

      {formFieldError && (
        <FormControl error>
          <FormHelperText sx={{ margin: "10px 0", lineHeight: "15px" }}>Main file is required.</FormHelperText>
        </FormControl>
      )}

      {isFetchFilesLoading ? (
        <Skeleton variant="rectangular" sx={{ width: "100%", height: "500px" }} />
      ) : (
        <AttachmentsViewer
          imageFilesData={imageFilesData}
          onChangePageBtnClick={setSelectedPageInFile}
          enableOcr={enableOcr}
        />
      )}
    </Box>
  );
};
