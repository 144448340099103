import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { FormInputDate } from "./common/forms/FormInputDate/FormInputDate";
import { FormInputFile } from "./common/forms/FormInputFile/FormInputFile";
import { FormInputNumber } from "./common/forms/FormInputNumber/FormInputNumber";
import { FormInputText } from "./common/forms/FormInputText/FormInputText";
import { IVReimbursementFormFields } from "./types";

export const VReimbursement = observer(() => {
  const formMethods = useForm<IVReimbursementFormFields>();

  const onSubmit: SubmitHandler<IVReimbursementFormFields> = (data) => console.log(data);
  const watch = useWatch({ control: formMethods.control });

  console.log(111111, watch);

  return (
    <Box>
      <Box sx={{ maxWidth: "400px", margin: "0 auto" }}>
        <Typography variant="h1" sx={{ fontSize: 24, margin: "20px 0 30px" }}>
          Expense report
        </Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <FormInputDate name="reimbursementDate" label="Date" rules={{ required: "Is required" }} />
            <FormInputNumber
              name="expenseReportNumber"
              label="Expense report number"
              rules={{ required: "Is required" }}
            />
            <FormInputText name="employeeId" label="Employee ID" rules={{ required: "Is required" }} />
            <FormInputText name="reasonForExpense" label="Reason for Expense" rules={{ required: "Is required" }} />
            <FormInputFile name="attachments" label="Attachment(s)" rules={{ required: "Is required" }} multiple />
            <FormInputNumber name="amount" label="Amount" />
            <FormInputDate name="expenseDate" label="Expense Date" rules={{ required: "Is required" }} />

            <Typography sx={{ fontSize: "18px", margin: "30px 0 20px" }}>Payee related data:</Typography>

            <FormInputText name="payeeEmailAddress" label="Email address" rules={{ required: "Is required" }} />
            <FormInputText name="payeeBankName" label="Bank Name" rules={{ required: "Is required" }} />
            <FormInputText name="payeeTypeOfAccount" label="Type of account" rules={{ required: "Is required" }} />
            <FormInputNumber
              name="payeeBankAccountNumber"
              label="Bank Account Number "
              rules={{ required: "Is required" }}
            />

            <Button type="submit" variant="contained" sx={{ height: "32px" }}>
              Submit
            </Button>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
});
