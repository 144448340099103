import { Box, Button, Tooltip } from "@mui/material";
import { Decimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import {
  IVBillAddEditBatchSubmitPayload,
  VBillAddEditBatchDialog,
} from "../../../common/VBill/VBillAddEditBatchDialog/VBillAddEditBatchDialog";
import { blackBtnStyles } from "../summariesStyles";

export const SummariesBudget = observer(function SummariesBudget() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { organizationId } = useParams<{ organizationId: string }>();

  const { batchDetails } = vBillBatchDetailsStore;
  const batchData = batchDetails.data;

  const handleAddEditBatchSubmit = async ({ name, budget }: IVBillAddEditBatchSubmitPayload) => {
    if (!batchData) {
      return;
    }

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: batchData.id,
      batchData: {
        organizationId,
        name: name ?? batchData.name,
        budget: new Decimal(budget ?? batchData.budget ?? 0).toFixed(2),
      },
    });
    setIsDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Edit budget" arrow>
        <Button onClick={() => setIsDialogOpen(true)} sx={blackBtnStyles}>
          <Box sx={{ color: COLORS.logoBlue }}>Budget:</Box>{" "}
          <strong>{!isNil(batchData?.budget) ? formatCurrency(Number(batchData?.budget ?? 0)) : 0}</strong>
        </Button>
      </Tooltip>

      {isDialogOpen && (
        <VBillAddEditBatchDialog
          showBudgetPicker
          batchData={batchData}
          onDialogClose={() => setIsDialogOpen(false)}
          actionsBtnSmallHeight
          onSubmit={handleAddEditBatchSubmit}
        />
      )}
    </>
  );
});
