import { VBillActiveFilters } from "components/pages/common/VBill/VBillActiveFilters/VBillActiveFilters";
import { isNil } from "lodash";
import { useCodingLedgerFilters } from "../hooks";

export const CodingActiveFilters = () => {
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();

  return (
    <VBillActiveFilters
      filters={{
        search: codingLedgerFilters.filters?.search,
        quickFilterStatusList: codingLedgerFilters.filters?.quickFilterStatusList,
        actionRequiredStatusList: codingLedgerFilters.filters?.actionRequiredStatusList,
        amount_from: !isNil(codingLedgerFilters.filters?.amount_from)
          ? `${codingLedgerFilters.filters.amount_from}`
          : undefined,
        amount_to: !isNil(codingLedgerFilters.filters?.amount_to)
          ? `${codingLedgerFilters.filters.amount_to}`
          : undefined,
        date: codingLedgerFilters.filters?.date,
        date_to: codingLedgerFilters.filters?.date_to,
        date_due_from: codingLedgerFilters.filters?.date_due_from,
        date_due_to: codingLedgerFilters.filters?.date_due_to,
      }}
      onFiltersChange={(values) =>
        setCodingLedgerFilters((prev) => ({
          filters: Object.keys(values).length
            ? {
                ...prev.filters,
                search: values.search,
                quickFilterStatusList: values.quickFilterStatusList,
                actionRequiredStatusList: values.actionRequiredStatusList,
                amount_from: Number(values.amount_from),
                amount_to: Number(values.amount_to),
                date: values.date,
                date_to: values.date_to,
                date_due_from: values.date_due_from,
                date_due_to: values.date_due_to,
              }
            : undefined,
          pagination: { page: 1, per_page: prev.pagination.per_page },
        }))
      }
      sx={{ margin: "0 0 10px" }}
    />
  );
};
