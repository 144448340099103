import { ExpandCircleDown } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import classNames from "classnames";
import { getVBillUrl } from "components/pages/common/VBill/utils";
import { IVBillKFileOcrState, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { ImageIcon } from "icons/svg/ImageIcon";
import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { VBillFileStreamPath } from "routes/routes";
import { COLORS } from "themes/default";
import { TVBillFileInfo } from "../FilesSelector";
import { MoveFileToNewBillModal } from "../MoveFileToNewBillModal/MoveFileToNewBillModal";
import styles from "./fileItem.module.scss";

interface IFileItemProps {
  file: NonNullable<IVBillVBillInvoiceQuery["invoice"]["files"]>[number];
  onChangeFileBtnClick: (file: TVBillFileInfo) => void;
  selected: boolean;
  allowMoveToNewBill: boolean;
  reloadData?: () => any;
}

export const FileItem: React.FunctionComponent<IFileItemProps> = ({
  file,
  selected,
  onChangeFileBtnClick,
  allowMoveToNewBill,
  reloadData,
}) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [confirmModalopen, setConfirmModalOpen] = useState<boolean>(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const dropdownOpen = Boolean(dropdownAnchorEl);
  const handleOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };
  const handleOptionsClose = () => {
    setDropdownAnchorEl(null);
  };

  return (
    <div key={file.id} className={classNames(styles.fileSelector, selected && styles.fileSelectorActive)}>
      <div className={styles.infoRow}>
        <Tooltip title={file.name} arrow>
          <button
            onClick={() => {
              if (
                file.fileIsEncrypted ||
                file.fileOcrState === IVBillKFileOcrState.OcrError ||
                file.fileOcrState === IVBillKFileOcrState.Unknown ||
                file.fileOcrState === IVBillKFileOcrState.OcrStartTriggered
              ) {
                return;
              }

              onChangeFileBtnClick(file);
            }}
            className={styles.selectorBtn}
          >
            <ImageIcon sx={{ color: COLORS.mediumGrey, fontSize: "18px" }} />{" "}
            <Box sx={{ textAlign: "left" }}>
              <div className={styles.selectorBtnText}>{file.name}</div>
              <Typography fontSize={12} fontWeight={200}>
                {getFileSizeAsKb(file.size)}
              </Typography>
            </Box>
          </button>
        </Tooltip>

        {!file.movedToInvoice && (
          <>
            <Tooltip title="Options" arrow>
              <Button onClick={handleOptionsClick}>
                <ExpandCircleDown sx={{ color: COLORS.mediumGrey, fontSize: "18px" }} />
              </Button>
            </Tooltip>

            <Menu anchorEl={dropdownAnchorEl} open={dropdownOpen} onClose={handleOptionsClose}>
              <MenuItem>
                <a
                  href={`${VBillFileStreamPath}/${(file.name || "").replace(/\.[^/.]+$/, "")}?id=${file.id ?? ""}`}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(styles.selectorLink)}
                >
                  Download
                </a>
              </MenuItem>
              {allowMoveToNewBill && <MenuItem onClick={() => setConfirmModalOpen(true)}>Move to new bill</MenuItem>}
            </Menu>
          </>
        )}
      </div>

      {file.movedToInvoice ? (
        <div className={styles.fileError}>
          This file was moved to{" "}
          <Link to={getVBillUrl(organizationId, file.movedToInvoice.id, file.movedToInvoice.status)}>
            bill #{file.movedToInvoice.id}
          </Link>
        </div>
      ) : (
        (file.fileOcrState === IVBillKFileOcrState.OcrError ||
          file.fileOcrState === IVBillKFileOcrState.Unknown ||
          file.fileOcrState === IVBillKFileOcrState.PendingStartProcessing ||
          file.fileOcrState === IVBillKFileOcrState.OcrStartTriggered) && (
          <div className={styles.fileError}>
            {(file.fileOcrState === IVBillKFileOcrState.OcrError ||
              file.fileOcrState === IVBillKFileOcrState.Unknown) &&
              "Error processing file."}
            {(file.fileOcrState === IVBillKFileOcrState.PendingStartProcessing ||
              file.fileOcrState === IVBillKFileOcrState.OcrStartTriggered) &&
              "The file is processing ..."}
          </div>
        )
      )}

      {file.fileIsEncrypted && <div className={styles.fileError}>This pdf is password protected.</div>}

      <MoveFileToNewBillModal
        isOpen={confirmModalopen}
        onClose={() => setConfirmModalOpen(false)}
        file={file}
        reloadData={reloadData}
      />
    </div>
  );
};

const getFileSizeAsKb = (size?: number | null) => `${((size ?? 0) / 1024).toFixed(2)}KB`;
