import { Box, Skeleton } from "@mui/material";
import { SidebarDraggableSeparator } from "components/common/SidebarDraggableSeparator/SidebarDraggableSeparator";
import { IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { observer } from "mobx-react-lite";
import { FunctionComponent, useRef } from "react";
import { useResizable } from "react-resizable-layout";
import { useStore } from "storeContainer";
import { ISidebarTabsRef, SidebarTabs } from "./SidebarTabs/SidebarTabs";

interface IVBillSidebarProps {
  invoice?: IVBillVBillInvoiceQuery["invoice"] | null;
}

export const VBillSidebar: FunctionComponent<IVBillSidebarProps> = observer(({ invoice }) => {
  const vBillStore = useStore("VBillStore");

  const {
    isDragging: isResizableDragging,
    position: resizableWidth,
    separatorProps: resizableSeparatorProps,
  } = useResizable({
    axis: "x",
    initial: 610,
    min: 400,
    max: 770,
    reverse: true,
    onResizeEnd: handleResizableDragEnd,
  });
  const sidebarTabsRef = useRef<ISidebarTabsRef>(null);

  function handleResizableDragEnd() {
    sidebarTabsRef.current?.tabsUpdateIndicator();
  }

  return (
    <>
      <SidebarDraggableSeparator isDragging={isResizableDragging} sx={{ zIndex: "2" }} {...resizableSeparatorProps} />
      <Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "auto",
          paddingRight: "20px",
          ...(isResizableDragging ? { filter: "blur(5px)" } : {}),
        }}
        style={{ width: resizableWidth }}
      >
        {vBillStore.invoice.isLoading || vBillStore.settings.isLoading ? (
          <Skeleton variant="rounded" sx={{ margin: "30px", height: "600px" }} />
        ) : (
          <SidebarTabs invoice={invoice} ref={sidebarTabsRef} />
        )}
      </Box>
    </>
  );
});
