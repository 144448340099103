import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { IVBillVBillInvoiceQuery, IVBillVBillSettingsQuery } from "generated/sdk.vbill";
import { cloneDeep } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useMemo } from "react";
import {
  VBillLineItemsAccordian,
  VBillLineItemsAccordianDetails,
  VBillLineItemsAccordianSummary,
} from "../../common/VBillLineItemsAccordian/VBillLineItemsAccordian";
import { SummaryLineItem } from "./SummaryLineItem/SummaryLineItem";

interface ISummaryLineItemsProps {
  vBillData: IVBillVBillInvoiceQuery["invoice"];
  vBillAdditionalMappingsSettings?: IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"];
  onLineItemsExpandedChange: (value: boolean) => void;
  lineItemsExpanded: boolean;
}

export const SummaryLineItems = observer(function SummaryLineItems({
  vBillData,
  vBillAdditionalMappingsSettings,
  lineItemsExpanded,
  onLineItemsExpandedChange,
}: ISummaryLineItemsProps) {
  const invoiceLineItemsData = useMemo(
    () => cloneDeep(toJS(vBillData.lineItems ?? [])).sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0)),
    [vBillData.lineItems],
  );

  return (
    <VBillLineItemsAccordian
      expanded={lineItemsExpanded}
      onChange={() => onLineItemsExpandedChange(!lineItemsExpanded)}
    >
      <VBillLineItemsAccordianSummary
        accordianExpanded={lineItemsExpanded}
        headingTotalLineItems={invoiceLineItemsData.length}
      />
      <VBillLineItemsAccordianDetails>
        <TableContainer sx={{ marginBottom: "20px" }}>
          <Table>
            <TableHead>
              <TableRow />
            </TableHead>
            <TableBody>
              {invoiceLineItemsData.map((lineItem, index) => (
                <SummaryLineItem
                  key={lineItem.id}
                  lineItem={lineItem}
                  index={index}
                  vBillAdditionalMappingsSettings={vBillAdditionalMappingsSettings}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </VBillLineItemsAccordianDetails>
    </VBillLineItemsAccordian>
  );
});
