import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Box, TableRow, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useVBillUrl } from "components/pages/common/VBill/hooks";
import {
  friendlyInvoiceIncomingStatusNames,
  friendlyInvoicePushStatusNames,
  getVBillFormattedDate,
} from "components/pages/common/VBill/utils";
import { format } from "date-fns";
import { IVBillInvoiceIncomingStatus, IVBillVBillInvoicesQuery } from "generated/graphql.vbill";
import { observer } from "mobx-react";
import { CSSProperties, useMemo } from "react";
import { Link } from "react-router-dom";
import { useStore } from "storeContainer";
import { formatCurrency } from "../../../../common/helpers/utils";
import { COLORS } from "../../../../themes/default";
import { useVBillCodingLedgerChildRoute } from "../hooks";
import { VBillActions } from "./VBillActions/VBillActions";
import { VBillProcessingOverlay } from "./VBillProcessingOverlay/VBillProcessingOverlay";

/*

// vendor ? ender Name: VBillCompaniesSettings.query.graphql
// noWrap final size
// TODO: verify all date with -4
// date sort?
// suggestedMainFileId




// bill# head to invoice# 

// vill# col to cid



// options to actions col
// remove icon "open invoice in a new tab"

// posible duplicates link icon to open invoice in a new tab



// what start coding btn?

igone in duplcates to "start coding btn" 




// "new" to "pending review"



edit invoice / edit mapping 

save draft left  finish coding button right

finish coding insted of start approval


incoming save draft to save next 

new save draft in meniu & finish coding btn

start approval direct

navigate to ledger after start approving









// coding status: new & mapped 


// approvals
// after approve navigate to legder


// compl;eted 
// status: synching and synced 
// status no options col



// all: by status

// pending review "start coding"
  duplicates ingrosare linie
  igone in duplcates to "not a duplicate start coding btn" 

// coding: "code ionvoice" | "map"
  // new / mapping

// approvals: "approve"
//  bring back to ledger

actions required ???

// completed
// status (syncing, synced and declined)  


// incoming
//  next

// new
//  finish coding
//  with start approval

//  CodingActiveFilters with new statuses


// ledger blue btn


*/

export const defaultTableCellSx = {
  padding: "0",
};
const defaultLinkStyles: CSSProperties = {
  minHeight: "60px",
  textDecoration: "none",
  color: "inherit",
  padding: "5px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  fontSize: "14px",
  fontWeight: "500",
};

interface ICodingVBillProps {
  invoice: IVBillVBillInvoicesQuery["invoices"]["items"][number];
}

export const CodingVBill = observer(({ invoice }: ICodingVBillProps) => {
  const vBillCodingLedgerStore = useStore("VBillCodingLedgerStore");
  const { isAllChildRoute, isCodingChildRoute, isCompletedChildRoute, isActionRequiredChildRoute } =
    useVBillCodingLedgerChildRoute();
  const vBillInvoiceUrl = useVBillUrl(invoice);
  const organizationStore = useStore("OrganizationStore");
  const { companiesSettings } = vBillCodingLedgerStore;
  const vendorMappingKey = useMemo(
    () => companiesSettings.data?.invoice?.find(({ isContact }) => isContact)?.key,
    [companiesSettings.data?.invoice],
  );
  const vendorDetails = useMemo(
    () => invoice.additionalMappings?.find((mapping) => mapping.key === vendorMappingKey),
    [invoice.additionalMappings, vendorMappingKey],
  );
  const companyName = useMemo(
    () =>
      (organizationStore.organizationVBillEnabledCompanies.data?.items ?? []).find(
        ({ companyId }) => companyId === invoice.companyId,
      )?.name,
    [invoice.companyId, organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const billToName = companyName || invoice.recipientName;
  const invoiceCreateDate = useMemo(() => getVBillFormattedDate(invoice.invoiceDate), [invoice.invoiceDate]);
  const invoiceDueDate = useMemo(() => getVBillFormattedDate(invoice.invoiceDateDue), [invoice.invoiceDateDue]);
  const invoiceEmailDate = useMemo(
    () => (invoice.emailDate ? format(new Date(invoice.emailDate), "MM/dd/yy hh:mmaaa") : "-"),
    [invoice.emailDate],
  );
  const invoiceAmount = useMemo(() => formatCurrency(Number(invoice.amount)), [invoice.amount]);
  const invoiceStatusName = useMemo(() => friendlyInvoiceIncomingStatusNames[invoice.status], [invoice.status]);
  const invoiceSyncTooltipText = useMemo(
    () => (invoice.pushStatus ? friendlyInvoicePushStatusNames[invoice.pushStatus] : null),
    [invoice.pushStatus],
  );

  return (
    <TableRow sx={{ cursor: "default", position: "relative" }}>
      <TableCell sx={defaultTableCellSx}>
        <VBillProcessingOverlay filesOcrState={invoice.filesOcrState} />

        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>
            <Box sx={{ color: COLORS.newSecondaryColor }}>Bill# {invoice.invoiceNumber ?? "-"}</Box>
            <Box sx={{ fontSize: "12px" }}>cid: {invoice.id}</Box>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>
            {vendorDetails ? `${vendorDetails.label ?? "-"} - ${vendorDetails.label2 ?? "-"}` : invoice.senderName}{" "}
            <Tooltip
              title={
                <Box>
                  Email from: {invoice.initialSenderName ?? "-"}, {invoice.initialSenderEmail ?? "-"}, <br />
                  Subject: {invoice.emailSubject ? invoice.emailSubject : "-"}, <br />
                  Date: {invoiceEmailDate}
                </Box>
              }
              arrow
            >
              <AlternateEmailIcon sx={{ fontSize: "10px", color: COLORS.textGray }} />
            </Tooltip>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>{billToName || "-"}</Box>
        </Box>
      </TableCell>

      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={{ ...defaultLinkStyles, textAlign: "right" }}>
          {invoiceAmount}
        </Box>
      </TableCell>
      <TableCell sx={defaultTableCellSx}>
        <Box component={Link} to={vBillInvoiceUrl} sx={defaultLinkStyles}>
          <Box sx={{ width: "160px", wordBreak: "break-word" }}>
            <Box>
              <Box component="span" sx={{ fontWeight: "400" }}>
                Create date:{" "}
              </Box>
              {invoiceCreateDate}
            </Box>
            <Box>
              <Box component="span" sx={{ fontWeight: "400" }}>
                Due date:{" "}
              </Box>
              {invoiceDueDate}
            </Box>
          </Box>
        </Box>
      </TableCell>

      {(isAllChildRoute || isCodingChildRoute || isCompletedChildRoute || isActionRequiredChildRoute) && (
        <TableCell sx={defaultTableCellSx}>
          <Box component={Link} to={vBillInvoiceUrl} sx={{ ...defaultLinkStyles }}>
            <Box sx={{ width: "80px", wordBreak: "break-word" }}>
              {invoice.status !== IVBillInvoiceIncomingStatus.Approved ? invoiceStatusName : invoiceSyncTooltipText}
            </Box>
          </Box>
        </TableCell>
      )}

      <VBillActions invoice={invoice} />
    </TableRow>
  );
});
