import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { IVBillFormMainFileOption, TVBillFormFields } from "components/pages/VBill/types";
import { IVBillInvoiceIncomingStatus } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import { ChangeEvent, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useStore } from "storeContainer";
import { emptyOCRFormLineFields, lineItemMetaToFormLineFields } from "../../../utils";

export const LineItemsApplySuggestions = observer(function LineItemsApplySuggestions() {
  const vBillStore = useStore("VBillStore");
  const formMainFileObserver: IVBillFormMainFileOption | null = useWatch({ name: "mainFile" });
  const formMethods = useFormContext<TVBillFormFields>();

  const { invoice, invoiceLineItemsItemized } = vBillStore;
  const invoiceData = invoice.data;
  const selectedFileMetadata = useMemo(
    () => invoiceData?.files?.find(({ id }) => id === formMainFileObserver?.value)?.metadata?.GDAI,
    [invoiceData?.files, formMainFileObserver?.value],
  );

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isSwitchChecked = event.target.checked;

    vBillStore.setInvoiceLineItemsItemized(isSwitchChecked);
    vBillStore.setInvoiceLineItemsExpanded(true);

    if (isSwitchChecked) {
      const lineItemsWithSuggestions = (selectedFileMetadata?.lineItems ?? []).map((lineItem) =>
        lineItemMetaToFormLineFields(lineItem),
      );

      const formValues = {
        ...formMethods.getValues(),
        lineItems: lineItemsWithSuggestions.length ? lineItemsWithSuggestions : [emptyOCRFormLineFields],
      };

      formMethods.reset(formValues);
    } else {
      const formValues = {
        ...formMethods.getValues(),
        lineItems: [
          {
            ...emptyOCRFormLineFields,
            description: "Bill total",
            qty: "1",
            rate: "0",
            amount: !isNil(selectedFileMetadata?.amount) ? `${selectedFileMetadata?.amount}` : "",
          },
        ],
      };

      formMethods.reset(formValues);
    }
  };

  if (invoiceData?.status !== IVBillInvoiceIncomingStatus.Queued) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
      <FormControlLabel
        sx={{ margin: 0 }}
        control={<Switch checked={invoiceLineItemsItemized} onChange={handleSwitchChange} />}
        label={
          <Typography>
            Itemized bill{" "}
            <Box component="strong" sx={{ fontSize: "12px" }}>
              ({selectedFileMetadata?.lineItems?.length || 1})
            </Box>
          </Typography>
        }
      />
    </Box>
  );
});
