import { graphqlClient } from "common/graphqlClient";
import {
  AclEntityTypeEnum,
  AclPermissionTypeEnum,
  AclSourceEnum,
  AppliedPermissionGroup,
  BankAccountManageCheckAclPermission,
  getSdk,
  LimitCreateVChecksRule,
  PermissionGroup,
  PermissionGroupInput,
} from "generated/sdk";
import { definitelyFilter, flattenUnionOfArrayTypes } from "generated/utils";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { createObservableContainer } from "storeContainer";
import { StoreBase } from "./StoreBase";

const ACL_INFINITY = -1;

const { PermissionsGroups, OrganizationCompaniesWithBankaccounts, ACLPermissionAggregatedLimits } =
  getSdk(graphqlClient);

const fetchOrganizationCompaniesWithBankaccounts = async (organization_id: string) =>
  definitelyFilter((await OrganizationCompaniesWithBankaccounts({ organization_id })).OrganizationDashboard.companies);
export const fetchPermissionGroups = async (): Promise<PermissionGroup[]> =>
  definitelyFilter((await PermissionsGroups()).PermissionsGroups);

export const fetchOrganizationUserAclPermissionsAgg = async (
  organization_user_id: string,
): Promise<AppliedPermissionGroup> =>
  (await ACLPermissionAggregatedLimits({ organization_user_id })).ACLPermissionAggregatedLimits || {};
export class AclStore extends StoreBase {
  constructor() {
    super();
    makeObservable(this);
  }

  private _permissionGroups = createObservableContainer<PermissionGroup[]>();
  private aggregatedPermissions = createObservableContainer<AppliedPermissionGroup>();
  private _organizationStructure =
    createObservableContainer<Awaited<ReturnType<typeof fetchOrganizationCompaniesWithBankaccounts>>>();

  private intervalId?: number;

  @observable organizationId: string | null = null;
  @observable organizationUserId: string | null = null;
  @observable user_group_id: string | null = null;
  @observable applyInProgress: boolean = false;
  @observable aclDone: boolean = false;

  @action
  setContext(organizationId: string, organizationUserId: string | null, userGroupId: string | null) {
    // let reloadRequired = 0;

    if (!this.storeContainer?.SessionStore.isOrganizationIdInSession(organizationId)) return;

    if (
      this.organizationId === organizationId &&
      this.organizationUserId === organizationUserId &&
      this.user_group_id === userGroupId
    ) {
      return;
    }

    this.organizationId = organizationId;

    if (organizationUserId) {
      this.organizationUserId = organizationUserId;
      this.user_group_id = null;
      this.flush();
    } else if (userGroupId) {
      this.organizationUserId = null;
      this.user_group_id = userGroupId;
      this.flush();
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      throw new Error(`Invalid context for acl`);
    }

    // when context changes, cancel previous interval and reset it later
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.reload(true, true).then(() => {
      this.intervalId = Number(setInterval(() => this.reload(true, false), 300000));
    });
  }

  @computed
  get errors() {
    return [this._permissionGroups.error, this.aggregatedPermissions.error, this._organizationStructure.error];
  }

  async reload(forceUpdate?: boolean, markAsLoading: boolean = true) {
    const promises: Promise<any>[] = [];
    promises.push(
      this._permissionGroups.cachedLoad(() => fetchPermissionGroups(), [], {
        forceUpdate,
        markAsLoading,
      }),
    );

    if (this.organizationUserId) {
      await this.aggregatedPermissions.cachedLoad(
        () => fetchOrganizationUserAclPermissionsAgg(this.organizationUserId!),
        [this.organizationUserId],
        { forceUpdate, markAsLoading },
      );
    } else {
      await this.aggregatedPermissions.load(Promise.resolve({}));
    }

    const { selectedOrganizationId } = this.storeContainer?.ContextStore!;
    if (selectedOrganizationId) {
      promises.push(
        this._organizationStructure.cachedLoad(
          () => fetchOrganizationCompaniesWithBankaccounts(selectedOrganizationId),
          [selectedOrganizationId],
          { markAsLoading },
          // { forceUpdate }
        ),
      );
    }

    await Promise.all(promises);

    runInAction(() => (this.aclDone = true));
  }

  async flush() {
    this.aggregatedPermissions.flush();
  }

  @computed get isFetching() {
    return (
      this.applyInProgress ||
      this._permissionGroups.isFetching ||
      this._organizationStructure.isFetching ||
      this.aggregatedPermissions.isFetching
    );
  }

  @computed get isUser() {
    return !!this.organizationUserId;
  }

  @computed
  get organizationStructure(): IAclOrganizationStructure | undefined {
    const selectedOrganization = this.storeContainer?.ContextStore.selectedOrganization;
    if (!selectedOrganization || !selectedOrganization.name) return undefined;

    return {
      id: selectedOrganization.id,
      name: selectedOrganization.name,
      perm: {},
      permAgg: {},
      companies:
        this._organizationStructure.data
          ?.filter((c) => c?.id && c.name)
          .map((company) => {
            return {
              id: company.id,
              name: company.name,
              perm: {},
              permAgg: {},
              bas:
                company.bank_accounts
                  ?.filter((ba) => ba?.id && ba.name)
                  .map((ba) => {
                    return {
                      id: ba?.id!,
                      name: ba?.name!,
                      perm: {},
                      permAgg: {},
                    };
                  }) || [],
            };
          }) || [],
    };
  }

  @computed
  get organizationStructurePermissions(): IAclOrganizationStructure | undefined {
    return this.organizationStructure;
  }

  @computed
  get inheritedPermissions() {
    const organizationStructurePermissions = this.organizationStructurePermissions;
    if (!organizationStructurePermissions) return [];
    const inheritedPermissions: IAclPermissionRow[] = [];

    const { companies, ...org } = organizationStructurePermissions;

    let orgChildrenBankaccountsCount = 0;
    companies.forEach((c) => {
      const { bas, ...company } = c;
      inheritedPermissions.push({
        ...company,
        entityType: AclEntityTypeEnum.Company,
        entityLevel: 2,
        parents: [org.id],
        childrenBankaccountsCount: bas.length,
      });
      orgChildrenBankaccountsCount += bas.length;
      bas.forEach((b) =>
        inheritedPermissions.push({
          ...b,
          entityType: AclEntityTypeEnum.BankAccount,
          entityLevel: 3,
          parents: [org.id, c.id],
          entityLevelLast: true,
        }),
      );
    });
    inheritedPermissions.unshift({
      ...org,
      entityType: AclEntityTypeEnum.Organization,
      entityLevel: 1,
      parents: [],
      childrenCompaniesCount: companies.length,
      childrenBankaccountsCount: orgChildrenBankaccountsCount,
    });

    return inheritedPermissions;
  }

  get inheritedPermissions2() {
    const inheritedPermissions: IAclPermissionRow[] = [];
    const aggregatedPermissions: AppliedPermissionGroup | undefined = this.aggregatedPermissions.data;
    const organizationStructure = this.organizationStructure;

    const filterInPgs = (unfiltered: AppliedPermissions, filterFunction: (e: AllBasePermissionType) => boolean) => {
      const filtered: AppliedPermissions = {};
      AclPermissionGroupNames.forEach((PG) => {
        const unfilteredPg: AllBasePermissionType[] = definitelyFilter<any>(unfiltered[PG]);
        filtered[PG] = unfilteredPg.filter(filterFunction) as any;
      });
      return filtered;
    };

    if (organizationStructure && aggregatedPermissions) {
      const { companies, ...org } = organizationStructure;

      org.perm = filterInPgs(
        aggregatedPermissions as AppliedPermissions,
        (e) =>
          e.entity_type === AclEntityTypeEnum.Organization &&
          e.entity_id === org.id &&
          e.acl_source !== AclSourceEnum.Aggregated,
      );

      org.permAgg = filterInPgs(
        aggregatedPermissions as AppliedPermissions,
        (e) =>
          e.entity_type === AclEntityTypeEnum.Organization &&
          e.entity_id === org.id &&
          e.acl_source === AclSourceEnum.Aggregated,
      );

      inheritedPermissions.push({ ...org, entityType: AclEntityTypeEnum.Organization, entityLevel: 1, parents: [] });
      companies.forEach((c) => {
        const { bas, ...company } = c;

        company.perm = filterInPgs(
          aggregatedPermissions as AppliedPermissions,
          (e) =>
            e.entity_type === AclEntityTypeEnum.Company &&
            e.entity_id === company.id &&
            e.acl_source !== AclSourceEnum.Aggregated,
        );

        company.permAgg = filterInPgs(
          aggregatedPermissions as AppliedPermissions,
          (e) =>
            e.entity_type === AclEntityTypeEnum.Company &&
            e.entity_id === company.id &&
            e.acl_source === AclSourceEnum.Aggregated,
        );

        inheritedPermissions.push({
          ...company,
          entityType: AclEntityTypeEnum.Company,
          entityLevel: 2,
          parents: [org.id],
        });
        bas.forEach((b) => {
          const bankaccount = { ...b };

          bankaccount.perm = filterInPgs(
            aggregatedPermissions as AppliedPermissions,
            (e) =>
              e.entity_type === AclEntityTypeEnum.BankAccount &&
              e.entity_id === bankaccount.id &&
              e.acl_source !== AclSourceEnum.Aggregated,
          );

          bankaccount.permAgg = filterInPgs(
            aggregatedPermissions as AppliedPermissions,
            (e) =>
              e.entity_type === AclEntityTypeEnum.BankAccount &&
              e.entity_id === bankaccount.id &&
              e.acl_source === AclSourceEnum.Aggregated,
          );

          inheritedPermissions.push({
            ...bankaccount,
            entityType: AclEntityTypeEnum.BankAccount,
            entityLevel: 3,
            parents: [org.id, c.id],
            entityLevelLast: true,
          });
        });
      });
    }
    return inheritedPermissions;
  }

  @action
  async apply(_perm: PermissionGroupInput) {
    this.applyInProgress = true;
    this.aclDone = false;
    await this.reload(true);
    this.applyInProgress = false;
  }

  @computed
  get PermissionGroups(): IPermissionGroupWithSettings[] {
    return definitelyFilter(
      this._permissionGroups.data
        ?.map((permissionGroup) => ({
          ...permissionGroup,
          settings: this.getAclPermissionsSettings(permissionGroup.internal_alias as any),
        }))
        .map((e) => ({
          ...e,
          appliesTo: [
            ...(e.settings.appliesToOrganization && !e.settings.appliesToCompany && !e.settings.appliesToBankAccount
              ? [AclEntityTypeEnum.Organization]
              : []),
            ...(e.settings.appliesToOrganization && e.settings.appliesToCompany && !e.settings.appliesToBankAccount
              ? [AclEntityTypeEnum.Company]
              : []),
            ...(e.settings.appliesToOrganization && e.settings.appliesToCompany && e.settings.appliesToBankAccount
              ? [AclEntityTypeEnum.BankAccount]
              : []),
          ],
        })),
    );
  }

  getAclPermissionsSettings(permissionGroup: keyof typeof AclPermissionGroupSettings): IAclPermissionGroupSettings {
    return AclPermissionGroupSettings[permissionGroup] || defaultAclPermissionGroupsInfo;
  }

  @computed
  get canManageOrganization(): boolean {
    return this._hasPerm(AclPermissionTypeEnum.OrganizationManage, AclEntityTypeEnum.Organization, this.organizationId);
  }

  @computed
  get canManageUsers(): boolean {
    return this._hasPerm(
      AclPermissionTypeEnum.OrganizationUsersManage,
      AclEntityTypeEnum.Organization,
      this.storeContainer?.ContextStore.selectedOrganizationId,
    );
  }

  @computed
  get canListCompanies(): boolean {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyList,
      AclEntityTypeEnum.Organization,
      this.storeContainer?.ContextStore.selectedOrganizationId,
    );
  }

  @computed
  get canListNotifications(): boolean {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyNotifications,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canAddBankAccountToOrg(): boolean {
    return this._hasPerm(
      AclPermissionTypeEnum.OrganizationBankAccountAdd,
      AclEntityTypeEnum.Organization,
      this.storeContainer?.ContextStore.selectedOrganizationId,
    );
  }

  @computed
  get isPermissionGroupLoaded(): boolean {
    return this._permissionGroups.isLoaded;
  }

  @computed
  get canListOrgVChecks(): boolean {
    const basUserCanList = [
      ...this._entitiesUserHasPermOn(AclPermissionTypeEnum.BankAccountChecksListOwn, AclEntityTypeEnum.BankAccount),
      ...this._entitiesUserHasPermOn(AclPermissionTypeEnum.BankAccountChecksListOther, AclEntityTypeEnum.BankAccount),
    ];

    const orgBas = this.storeContainer?.OrganizationStore.organizationCompanies.data?.reduce(
      (acc, co) => [...acc!, ...co.bank_accounts!.map((ba) => ba?.id!)],
      [] as string[],
    );

    return orgBas?.some((ba) => basUserCanList.includes(ba)) ?? false;
  }

  @computed
  get orgsUserMayAddCompanyTo() {
    const orgs = this._entitiesUserHasPermOn(
      AclPermissionTypeEnum.OrganizationCompanyAdd,
      AclEntityTypeEnum.Organization,
    );

    return this.storeContainer?.SessionStore.organizations.filter((org) => orgs.includes(org.id)) ?? [];
  }

  @computed
  get orgsUserMayAddBankAccountTo() {
    const orgs = this._entitiesUserHasPermOn(
      AclPermissionTypeEnum.OrganizationBankAccountAdd,
      AclEntityTypeEnum.Organization,
    );

    return this.storeContainer?.SessionStore.organizations?.filter((org) => orgs.includes(org.id)) ?? [];
  }

  @computed
  get basUserMayList() {
    const bas = this._entitiesUserHasPermOn(AclPermissionTypeEnum.BankAccountList, AclEntityTypeEnum.BankAccount);

    const { selectedCompanyBanksAccounts } = this.storeContainer?.ContextStore!;

    return selectedCompanyBanksAccounts.filter((ba) => bas.includes(ba.id));
  }

  @computed
  get canListSelectedCompanyContacts() {
    return this.storeContainer?.ContextStore.selectedCompanyId
      ? this.canListCompanyContacts(this.storeContainer?.ContextStore.selectedCompanyId)
      : false;
  }

  @computed
  get hasNoPermission() {
    const perms = this.aggregatedPermissions._dataObsevable || {};
    return Object.keys(perms)
      .filter((key) => key !== "__typename")
      .every((key) => !perms[key as keyof AppliedPermissionGroup]!.length);
  }

  canListCompanyContacts(companyId: string) {
    return this._hasPerm(AclPermissionTypeEnum.CompanyContactsList, AclEntityTypeEnum.Company, companyId);
  }

  @computed
  get canListCompanyVendors() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyContactsList,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canManageCompanyGroups() {
    return this._hasPerm(
      AclPermissionTypeEnum.OrganizationManageCompanyGroups,
      AclEntityTypeEnum.Organization,
      this.storeContainer?.ContextStore.selectedOrganizationId,
    );
  }

  canUpdateMemo(bankAccountId: string, amount?: number): boolean {
    return this._hasPermWithinLimits(
      AclPermissionTypeEnum.BankAccountChecksUpdateMemo,
      AclEntityTypeEnum.BankAccount,
      bankAccountId,
      amount,
    );
  }

  canListCompanyBankAccounts(companyId: string) {
    const company =
      this.storeContainer?.OrganizationStore.organizationCompaniesWithPagination._dataObsevable?.companies?.find(
        (co) => co.id === companyId,
      );

    if (!company) {
      console.info(`%ccanListCompanyBankAccounts: could not find company id ${companyId}`, "color:orange");
      return false;
    }

    const companyBas = company.bank_accounts?.map((ba) => ba?.id!) ?? [];
    const basWithPerm = this._entitiesUserHasPermOn(
      AclPermissionTypeEnum.BankAccountList,
      AclEntityTypeEnum.BankAccount,
    );

    return companyBas?.some((ba) => basWithPerm.includes(ba));
  }

  canListCompany(companyId: string): boolean {
    return this._hasPerm(AclPermissionTypeEnum.CompanyList, AclEntityTypeEnum.Company, companyId);
  }

  @computed
  get canManageIntegration() {
    if (this.storeContainer?.ContextStore.selectedCompanyId) {
      return this._hasPerm(
        AclPermissionTypeEnum.CompanyIntegrationList,
        AclEntityTypeEnum.Company,
        this.storeContainer?.ContextStore.selectedCompanyId,
      );
    }
  }

  canManageIntegrationsOnThisCompany(companyId: string) {
    return this._hasPerm(AclPermissionTypeEnum.CompanyIntegrationList, AclEntityTypeEnum.Company, companyId);
  }

  @computed
  get canValidateCompany() {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountUpgrade,
      AclEntityTypeEnum.Organization,
      this.storeContainer?.ContextStore.selectedOrganizationId,
    );
  }

  @computed
  get canListCompanyBankAccounts2() {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountList,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore?.selectedCompanyId,
    );
  }

  @computed
  get canManageBankAccounts() {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountManage,
      AclEntityTypeEnum.Organization,
      this.storeContainer?.ContextStore.selectedOrganizationId,
    );
  }

  @computed
  get canAddIntegration() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationCreate,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }
  canAddIntegrationOnThisCompany(companyId: string) {
    return this._hasPerm(AclPermissionTypeEnum.CompanyIntegrationCreate, AclEntityTypeEnum.Company, companyId);
  }

  @computed
  get canRemoveIntegration() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationRemove,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canListCompanyIntegrationMapUsers() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationMapUsers,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canListIntegrationMappedBankAccounts() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationMapBaList,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canMapIntegrationBankAccounts() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationMapBa,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canListIntegrationBankAccounts() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationMapBaList,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canListIntegrationMappedUsers() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationMapUsersList,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  @computed
  get canMapIntegrationUsers() {
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationMapUsers,
      AclEntityTypeEnum.Company,
      this.storeContainer?.ContextStore.selectedCompanyId,
    );
  }

  /* FIXME: move business logic to a flag on invoice */
  canMoveAttachmentToNewBill(invoice: { companyId?: string | null; coreOrgId: string }) {
    if (
      invoice.companyId &&
      [
        AclPermissionTypeEnum.CompanyVbillEdit,
        AclPermissionTypeEnum.CompanyVbillMapVendor,
        AclPermissionTypeEnum.CompanyVbillMapAdditional,
        AclPermissionTypeEnum.CompanyVbillMapGl,
      ].some((perm) => this._hasPerm(perm, AclEntityTypeEnum.Company, invoice.companyId))
    ) {
      return true;
    }

    if (
      !invoice.companyId &&
      this._hasPerm(
        AclPermissionTypeEnum.OrganizationVbillMapCompany,
        AclEntityTypeEnum.Organization,
        invoice.coreOrgId,
      )
    ) {
      return true;
    }

    return false;
  }

  canListPendingImportPending(selectedCompanyId: string) {
    // FIXME: better naming for CompanyIntegrationImportPendingArchive
    return this._hasPerm(
      AclPermissionTypeEnum.CompanyIntegrationImportPendingArchive,
      AclEntityTypeEnum.Company,
      selectedCompanyId,
    );
  }

  canListBankAccountLinkedEmailAddresses(bankAccountId: string) {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountLinkedAddressesList,
      AclEntityTypeEnum.BankAccount,
      bankAccountId,
    );
  }

  canAddBankAccountLinkedEmailAddresses(bankAccountId: string) {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountLinkedAddressesCreate,
      AclEntityTypeEnum.BankAccount,
      bankAccountId,
    );
  }

  canRemoveBankAccountLinkedEmailAddresses(bankAccountId: string) {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountLinkedAddressesRemove,
      AclEntityTypeEnum.BankAccount,
      bankAccountId,
    );
  }

  canListBankAccountAutoDepositAddresses(bankAccountId: string) {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountAutoDepositAddressesList,
      AclEntityTypeEnum.BankAccount,
      bankAccountId,
    );
  }

  canAddBankAccountAutoDepositAddresses(bankAccountId: string) {
    return this._hasPerm(
      AclPermissionTypeEnum.BankAccountAutoDepositAddressesCreate,
      AclEntityTypeEnum.BankAccount,
      bankAccountId,
    );
  }

  canListVbill(companyId: string) {
    return this._hasPerm(AclPermissionTypeEnum.CompanyVbillList, AclEntityTypeEnum.Company, companyId);
  }

  private _entitiesUserHasPermOn(permission: AclPermissionTypeEnum, entity_type: AclEntityTypeEnum) {
    const groupsWithPerm = this._getGroupsWithPermission(permission);

    return groupsWithPerm?.reduce((finalEntityIds, group) => {
      const acls = (this.aggregatedPermissions._dataObsevable || {})[group] ?? [];

      const entityIds = flattenUnionOfArrayTypes(acls)
        .filter((acl) => acl.entity_type === entity_type)
        .map((acl) => acl.entity_id)
        .filter((eid): eid is string => !!eid);

      return [...new Set([...finalEntityIds, ...entityIds])];
    }, [] as string[]);
  }

  private _hasPerm(
    permission: AclPermissionTypeEnum,
    entity_type: AclEntityTypeEnum,
    entity_id: string | null | undefined,
  ) {
    if (!entity_id) {
      return false;
    }
    const groupsWithPerm = this._getGroupsWithPermission(permission);

    return !!groupsWithPerm?.some(
      (group) =>
        !!this.aggregatedPermissions._dataObsevable &&
        this.aggregatedPermissions._dataObsevable[group]?.some(
          (acl) => acl?.entity_type === entity_type && acl.entity_id === entity_id,
        ),
    );
  }

  private _hasPermWithinLimits(
    permission: AclPermissionTypeEnum,
    entity_type: AclEntityTypeEnum,
    entity_id: string | null | undefined,
    amount?: number,
  ) {
    if (!entity_id) {
      return false;
    }
    const groupsWithPerm = this._getGroupsWithPermission(permission);

    return !!groupsWithPerm?.some((group) => {
      return (
        !!this.aggregatedPermissions._dataObsevable &&
        this.aggregatedPermissions._dataObsevable[group]?.some((acl) => {
          //Please do not remove this, for some reason when you have not amount limit the value is -1
          const vCheckMaxAmountLimit = (acl as BankAccountManageCheckAclPermission).limit_manage_vchecks_max_amount!;

          return vCheckMaxAmountLimit === ACL_INFINITY
            ? acl?.entity_type === entity_type && acl.entity_id === entity_id
            : acl?.entity_type === entity_type && acl.entity_id === entity_id && vCheckMaxAmountLimit >= amount!;
        })
      );
    });
  }

  private _getGroupsWithPermission(permission: AclPermissionTypeEnum): Array<PermissionGroupKeys> {
    return (this._permissionGroups._dataObsevable ?? [])
      .filter((pg) => pg.permission_types?.some((pt) => pt?.permission === permission))
      .map((pg) => pg.internal_alias as PermissionGroupKeys);
  }

  private _hasLimits() {
    return this.aggregatedPermissions;
  }
}

export type PermissionGroupKeys = keyof Omit<AppliedPermissionGroup, "__typename">;

export interface IPermissionGroupWithSettings extends PermissionGroup {
  settings: IAclPermissionGroupSettings;
  appliesTo: AclEntityTypeEnum[];
}

export interface IAclPermissionCategory {
  organization: boolean;
  company: boolean;
  bankAccount: boolean;
  vChecks: boolean;
}

export enum PermissionGroupCategory {
  ORGANIZATION = "Organizations",
  COMPANY = "Companies",
  BANK_ACCOUNT = "Bank Accounts",
  VCHECKS_CREATE = "vChecks Create",
  VCHECKS = "vChecks Manage",
}
export interface IAclPermissionGroupSettings {
  appliesToOrganization: boolean;
  appliesToCompany: boolean;
  appliesToBankAccount: boolean;
  hasLimits: boolean;
  category: PermissionGroupCategory;
  order: number;
}

export const defaultAclPermissionGroupsInfo = {
  appliesToOrganization: true,
  appliesToCompany: true,
  appliesToBankAccount: true,
  hasLimits: false,
  category: PermissionGroupCategory.VCHECKS,
  order: 999,
};

export const AclPermissionGroupSettings = {
  ORGANIZATION_COMPANY_MANAGER: {
    ...defaultAclPermissionGroupsInfo,
    appliesToCompany: false,
    appliesToBankAccount: false,
    category: PermissionGroupCategory.ORGANIZATION,
    order: 1,
  },
  ORGANIZATION_USER_MANAGER: {
    ...defaultAclPermissionGroupsInfo,
    appliesToCompany: false,
    appliesToBankAccount: false,
    category: PermissionGroupCategory.ORGANIZATION,
    order: 2,
  },
  COMPANY_VIEW: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: false,
    category: PermissionGroupCategory.COMPANY,
    order: 10,
  },
  COMPANY_INTEGRATION_MANAGER: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: false,
    category: PermissionGroupCategory.COMPANY,
    order: 11,
  },

  BANK_ACCOUNT_VIEW: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: true,
    category: PermissionGroupCategory.BANK_ACCOUNT,
    order: 20,
  },
  BANK_ACCOUNT_MANAGE: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: true,
    category: PermissionGroupCategory.BANK_ACCOUNT,
    order: 21,
  },

  BANK_ACCOUNT_LEDGER_ACCESS: {
    ...defaultAclPermissionGroupsInfo,
    hasLimits: true,
    category: PermissionGroupCategory.VCHECKS,
    order: 30,
  },
  BANK_ACCOUNT_CHECK_CREATE: {
    ...defaultAclPermissionGroupsInfo,
    hasLimits: true,
    category: PermissionGroupCategory.VCHECKS_CREATE,
    order: 31,
  },
  BANK_ACCOUNT_CHECK_SIGNER: {
    ...defaultAclPermissionGroupsInfo,
    hasLimits: true,
    category: PermissionGroupCategory.VCHECKS,
    order: 32,
  },
  BANK_ACCOUNT_CHECK_MANAGE: {
    ...defaultAclPermissionGroupsInfo,
    hasLimits: true,
    appliesToBankAccount: true,
    category: PermissionGroupCategory.VCHECKS,
    order: 33,
  },
  COMPANY_VBILL_ACCESS: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: false,
    hasLimits: true,
    category: PermissionGroupCategory.COMPANY,
  },
  COMPANY_VBILL_EDIT_MAP: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: false,
    hasLimits: false,
    category: PermissionGroupCategory.COMPANY,
  },
  ORGANIZATION_VBILL_COMPANY_MAPPER: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: false,
    appliesToCompany: false,
    hasLimits: false,
    category: PermissionGroupCategory.ORGANIZATION,
  },
  COMPANY_VBILL_PAYER: {
    ...defaultAclPermissionGroupsInfo,
    appliesToBankAccount: false,
    hasLimits: false,
    category: PermissionGroupCategory.COMPANY,
  },
};

interface IBasePermissionTypeExtra {
  direct?: boolean;
  inherited?: boolean;
  orig_entity_type: string;
  orig_entity_id: string;
  aggUserOverride?: boolean;
  usedForAgg?: boolean;
}

export type AppliedPermissions = {
  [K in keyof Omit<AppliedPermissionGroup, "__typename">]: Array<
    NonNullable<NonNullable<AppliedPermissionGroup[K]>[number]> & IBasePermissionTypeExtra
  >;
};

export type AllBasePermissionTypeBase = Required<{
  [K in keyof Omit<AppliedPermissionGroup, "__typename">]: NonNullable<NonNullable<AppliedPermissionGroup[K]>[number]> &
    IBasePermissionTypeExtra;
}>;

export type AllBasePermissionType = AllBasePermissionTypeBase[keyof AllBasePermissionTypeBase];

type AclPermissionGroupName = keyof typeof AclPermissionGroupSettings;

const AclPermissionGroupNames = Object.keys(AclPermissionGroupSettings) as AclPermissionGroupName[];

export interface IAclOrganizationStructure {
  id: string;
  name: string;
  perm: AppliedPermissions;
  permAgg: AppliedPermissions;
  companies: {
    id: string;
    name: string;
    perm: AppliedPermissions;
    permAgg: AppliedPermissions;
    bas: {
      id: string;
      name: string;
      perm: AppliedPermissions;
      permAgg: AppliedPermissions;
    }[];
  }[];
}

export interface IAclPermissionRow {
  entityType: AclEntityTypeEnum;
  entityLevel: number;
  entityLevelLast?: true | undefined;
  id: string;
  parents: string[];
  name: string;
  perm: AppliedPermissions;
  permAgg: AppliedPermissions;
  childrenCompaniesCount?: number;
  childrenBankaccountsCount?: number;
}

//reduceLimitCreateVChecksRule https://www.typescriptlang.org/play?#code/FAehAIGEHsFcDsAu4UEEBG0BuBTYoIAGFElQg8ARlJMsPLHACYaU779GXWGIBmVuACsHRgBZBTeuQrFW7GiLm1ppKfVIBJeADMKpZaWrzBzSSX0luNa6QGD7NMRQC8b9x89fvPnyktkgsY0wTaSnESCxAC04ACccRShKjHgYglJgkrgsQmJXEGqsZR5FLZWReDaeq6+dZ4R4IYkqRmMyWwauW0Q5Z1dVbr4+ADG0PAAzsgAhgBc4AAyAJYAtkuIkABOONOIOABqkAAWOCMA1hMASrAANjgA2gC64C7g9wDe4GMIiPOEADTgaaYXB-cAAX0Bn2+SHmlEBwOwODhGkh4GhcFhzARIORVFU4MeAG5gGNJsh0PNlmsNttdgdjqcLtc7k8Xm8MT95kwcUiwWjOVi+LzQcJUVCvpjfmkRXj1MRCSSyVMvlTVustjs9ocTucrrcHs9Xh9JVyqLL+RKYdKeUDcfMAOwiCFWqVwi1UbKK8CMbRYaY3JYAE3wiAAngAHHCLdW0rUM3XMg3s97AUjW+bwWArdA4TYk0iI0VZnN5kngknAcNRmM0zX0nVM-V3AAip2gm3pQfZ1I1dO1jL1LOjADJ0eBYBGACrQTPZ3ObCGV0njFXbIOwEY4XtxhuDpN3dkACjmtb78cbQ4NT0BlLPu4HiebhoAlGq6-2E03h2yXAA+dE0xIZVkGqdlKBwdgC2A1dkAmDtEGHCZj02A0Jnfc89yfH9HhfF4ANQu4JgAOngzZECPE9bzw-8gWIoto1idB6NxF9oJQEDwCDdtOz2JCULQjCHy-K9WVw-DwEInASJWaYI0oqTAWDAAPGiAKPT5iK0xSJ2nWdJLQ+4VPAABqKhngAfgMoijKDZTTPMlikXAeYwPBF82KAjjYPAABzHANilAAxDtUBWKVj24sZeJwIMkKE+tH2-A022irsbyBcKzRLBc33AHK8wko8oo7LskOInQlngIMjykiSpKc3BwAAHleaYsqQcARzHBrJxncAAPaqU8IAHxG8cM0GHQIRfYjrXwdMfOmbtXhKmKkKPMjELQk8PPYyVyXAdAVq4nj6Q2raNvQDyFpgw6GIAZT2CNkONLTiPgHAAHdwAegKj3ud7pkBd70EeYjZPkuraIahiPPBrbKOBo61KBHIUaJH0QG2cKmo3CNAxGekJlu7zJmgO5iJuaBfI0oEg1vBm7SRJ6cBembK0W+7fN8+L70SkSD0Ndknn2x7npInQOwAUWmEYjkoqY2aUuzUdTUxOOtVB2X8wKfhCzYwqlE8maViNPI1nzrQAIR1gKYH10KOoo47ATNi3BGVCmcCpmm6bNwEtcDqVbfc-auZVa12QAWV2I4Iaqo8g9NJBrY91glmmk8efKu54F8xAji6sdphzwzS95tCqZwfPC-Ryhwaj9wU8QPD1dMEhGCDcYAHIZiDU2qq3cAJna6N5tMcFwBwG4JmjTPwGzyuiOr2ui+6oEy5sivc5rgui+KRuIoA60268juu97-vB-gYfqa+wqJ8EKeZ7nwCO5IHeq4jWAJgVwVEAejNjNc+pBwTn3cufc+2xECwE2PATey9pLAHAcMTiX8iIi2Bugf4Ixwbrk3DgWqsVCE7gFpeIW-wngvlGKub2vtaafAwdJGaQA

export type LimitCreateVChecksRuleClean = {
  count: number;
  above: number;
};

type LimitCreateVChecksRuleDecorated = LimitCreateVChecksRuleClean & { upTo: number };

export const cleanLimitCreateVChecksRule = (
  rules: LimitCreateVChecksRule[] | undefined,
): LimitCreateVChecksRuleClean[] =>
  (rules || []).filter(
    (e): e is LimitCreateVChecksRuleClean =>
      e &&
      e.count !== null &&
      e.above !== null &&
      e.count !== undefined &&
      e.above !== undefined &&
      Number.isInteger(e.count) &&
      Number.isFinite(e.above),
  );

const reduceLimitCreateVChecksRule = (
  a: LimitCreateVChecksRule[] | undefined,
  b: LimitCreateVChecksRule[] | undefined,
): LimitCreateVChecksRule[] => {
  const Inf = 1e100;

  const sortRules = (rules: LimitCreateVChecksRuleClean[]) => rules.sort((a, b) => a.above - b.above);
  const decorateRules = (rules: LimitCreateVChecksRuleClean[]) =>
    rules.map((rule, idx) => ({ ...rule, upTo: rules[idx + 1] ? rules[idx + 1].above : Inf }));
  const getCountForAmount = (decoratedRules: LimitCreateVChecksRuleDecorated[], amount: number): number =>
    (decoratedRules.find((rule) => rule.above <= amount && rule.upTo > amount) || { count: Inf }).count;

  const ac = cleanLimitCreateVChecksRule(a);
  const bc = cleanLimitCreateVChecksRule(b);

  const ad = decorateRules(sortRules(ac));
  const bd = decorateRules(sortRules(bc));

  const aboveSteps = [...new Set([...ac, ...bc].map((rule) => rule.above))].sort((a, b) => a - b); //remove duplicates

  const aggRules: LimitCreateVChecksRuleClean[] = [];
  aboveSteps.forEach((step, _idx) => {
    const countA = getCountForAmount(ad, step);
    const countB = getCountForAmount(bd, step);
    const count = Math.min(countA, countB);
    if (aggRules.length && aggRules[aggRules.length - 1].count === count) {
      // don't add, since same count
    } else if (aggRules.length && aggRules[aggRules.length - 1].count > count) {
      // don't add, since lower count
    } else {
      aggRules.push({ count, above: step });
    }
  });
  return aggRules;
};
