import { IVBillInvoicesFiltersInput, IVBillSortOrderType } from "generated/sdk.vbill";
import { useMemo } from "react";
import { useCodingLedgerFilters, useVBillCodingLedgerChildRoute } from "../hooks";

interface ITableHeadCell {
  id: keyof IVBillInvoicesFiltersInput | string;
  sortable?: boolean;
  label: string;
  value?: IVBillSortOrderType | null;
  onChange?: (value: IVBillSortOrderType) => void;
}

export function useTableHeadCells() {
  const [codingLedgerFilters, setCodingLedgerFilters] = useCodingLedgerFilters();
  const { isAllChildRoute, isCodingChildRoute, isCompletedChildRoute, isActionRequiredChildRoute } =
    useVBillCodingLedgerChildRoute();

  const tableHeadCells: ITableHeadCell[] = useMemo(
    () => [
      {
        id: "bill_number_sort",
        // sortable: true,
        label: "Bill#",
        // value: codingLedgerFilters.filters?.vbill_number_sort,
        // onChange: (value) =>
        //   setCodingLedgerFilters((prev) => ({
        //     filters: { ...prev.filters, amount_sort: undefined, vbill_number_sort: value },
        //   })),
      },
      {
        id: "from",
        sortable: false,
        label: "From",
      },
      {
        id: "bill_to",
        sortable: false,
        label: "Bill To",
      },
      {
        id: "amount_sort",
        sortable: true,
        label: "Amount",
        value: codingLedgerFilters.filters?.amount_sort,
        onChange: (value) =>
          setCodingLedgerFilters((prev) => ({
            filters: {
              ...prev.filters,
              vbill_number_sort: undefined,
              amount_sort: value,
            },
          })),
      },
      {
        id: "date",
        sortable: false,
        label: "Date",
      },
      ...(isAllChildRoute || isCodingChildRoute || isCompletedChildRoute || isActionRequiredChildRoute
        ? [
            {
              id: "status",
              sortable: false,
              label: "Status",
            },
          ]
        : []),
      ...(!isCompletedChildRoute
        ? [
            {
              id: "actions",
              sortable: false,
              label: "Actions",
            },
          ]
        : []),
    ],
    [
      codingLedgerFilters.filters?.amount_sort,
      isAllChildRoute,
      isCodingChildRoute,
      isCompletedChildRoute,
      isActionRequiredChildRoute,
      setCodingLedgerFilters,
    ],
  );

  return tableHeadCells;
}
