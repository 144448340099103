import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, IconButton, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { Decimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { GenericErrorMessage } from "components/common/Errors/GenericErrorMessage";
import {
  getSdk,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIStoreIntDataType,
  IVBillVBillGetBillsQuery,
  IVBillVBillSettingsQuery,
} from "generated/sdk.vbill";
import { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { COLORS } from "themes/default";
import { getVBillFormattedDate } from "../utils";
import { VBillPayBillStoreItemSelector } from "../VBillPayBillStoreItemSelector/VBillPayBillStoreItemSelector";
import { VBillPriceAmount } from "../VBillPriceAmount/VBillPriceAmount";
import { IVBillSingleSelectAutocompleteSuggestion } from "../VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";

const { VBillSettings, VBillCreateBillsPayments } = getSdk(graphqlVBillClient);

interface IPayVBillFormFields {
  amount: string;
  memo: string;
  paymentFromGlAccount: IVBillSingleSelectAutocompleteSuggestion;
}

interface IVBillPayBillDialogProps {
  vBill: Pick<
    IVBillVBillGetBillsQuery["getBills"]["items"][number],
    "amounts" | "id" | "companyId" | "additionalMappings" | "invoiceDate" | "invoiceNumber" | "coreOrgId"
  >;
  onCloseDialog: () => void;
  onSubmitEnd: () => void;
  actionsBtnSmallHeight?: boolean;
}
export const VBillPayBillDialog = ({
  vBill,
  onCloseDialog,
  onSubmitEnd,
  actionsBtnSmallHeight,
}: IVBillPayBillDialogProps) => {
  const { amounts, id, companyId, additionalMappings, invoiceDate, invoiceNumber, coreOrgId } = vBill;
  const payVBillsForm = useForm<IPayVBillFormFields>({ defaultValues: { amount: amounts?.amountFreeToPay ?? "0" } });
  const { organizationId } = useParams<{ organizationId: string }>();
  const [vBillAdditionalMappingsSettings, setVBillAdditionalMappingsSettings] =
    useState<IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"]["invoice"]>();
  const [isSubmitReqLoading, setIsSubmitReqLoading] = useState(false);
  const [hasSubmitReqError, setHasSubmitReqError] = useState(false);
  const [isAdditionalMappingsSettingsFetching, setIsAdditionalMappingsSettingsFetching] = useState(false);

  const vendorAdditionalMapping = useMemo(
    () =>
      (additionalMappings ?? []).find(
        ({ key }) =>
          vBillAdditionalMappingsSettings
            ?.filter(({ isContact }) => isContact)
            .map(({ key }) => key)
            .includes(key),
      ),
    [additionalMappings, vBillAdditionalMappingsSettings],
  );
  const payFromAdditionalMapping = useMemo(
    () =>
      vBillAdditionalMappingsSettings?.find(
        ({ showIn }) => showIn === IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment,
      ),
    [vBillAdditionalMappingsSettings],
  );

  useEffect(() => {
    setIsAdditionalMappingsSettingsFetching(true);
    VBillSettings({ companyId }).then((resp) => {
      setVBillAdditionalMappingsSettings(resp.settings.vBill.additionalMappings.invoice);
      setIsAdditionalMappingsSettingsFetching(false);
    });
  }, [companyId]);

  const handlePayDialogSubmit: SubmitHandler<IPayVBillFormFields> = async (data) => {
    setIsSubmitReqLoading(true);
    setHasSubmitReqError(false);

    try {
      await VBillCreateBillsPayments({
        organizationId: organizationId,
        payableBills: {
          invoiceId: id,
          paymentAmount: new Decimal(data.amount ?? 0).toFixed(2, Decimal.ROUND_DOWN),
          memo: data.memo?.length ? data.memo : undefined,
          ...(payFromAdditionalMapping && data.paymentFromGlAccount
            ? {
                paymentFromGlAccount: {
                  storeType: payFromAdditionalMapping.storeDataType as unknown as IVBillIStoreIntDataType,
                  settingsKey: payFromAdditionalMapping.key,
                  key: data.paymentFromGlAccount.id,
                },
              }
            : {}),
        },
      });
      onCloseDialog();
      onSubmitEnd();
    } catch (_err) {
      setHasSubmitReqError(true);
    } finally {
      setIsSubmitReqLoading(false);
    }
  };

  return (
    <Dialog
      open
      onClose={onCloseDialog}
      PaperProps={{ sx: { padding: "15px", position: "relative", minWidth: "350px" } }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>

      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Pay bill</Typography>

      <Box sx={{ maxWidth: "200px", wordBreak: "break-word" }}>
        {invoiceNumber && (
          <Typography sx={{ fontSize: "14px", color: COLORS.newSecondaryColor }}>#{invoiceNumber}</Typography>
        )}
        <Typography sx={{ color: COLORS.textGray, fontSize: "12px" }}>Bill #{id}</Typography>
        <Typography sx={{ color: COLORS.textGray, fontSize: "12px" }}>{getVBillFormattedDate(invoiceDate)}</Typography>
      </Box>

      <form onSubmit={payVBillsForm.handleSubmit(handlePayDialogSubmit)}>
        <Divider sx={{ margin: "15px 0" }} />

        {isAdditionalMappingsSettingsFetching ? (
          <Skeleton variant="rounded" sx={{ height: "35px", margin: "15px 0" }} />
        ) : (
          vendorAdditionalMapping && (
            <>
              <Typography sx={{ marginBottom: "10px", fontSize: "16px" }}>Vendor:</Typography>

              <Typography>
                {vendorAdditionalMapping
                  ? `${vendorAdditionalMapping.label ?? ""} ${
                      vendorAdditionalMapping.label2 ? `- ${vendorAdditionalMapping.label2}` : ""
                    }`
                  : "-"}
              </Typography>

              <Divider sx={{ margin: "15px 0" }} />
            </>
          )
        )}

        <Typography sx={{ marginBottom: "10px", fontSize: "16px" }}>Amount:</Typography>

        <Controller
          name="amount"
          control={payVBillsForm.control}
          rules={{
            required: "Amount is required.",
            validate: (value) =>
              Number(value) > Number(amounts?.amountFreeToPay ?? 0)
                ? `Amount should be less then ${formatCurrency(Number(amounts?.amountFreeToPay ?? 0))}`
                : undefined,
          }}
          render={({ field, fieldState, formState }) => (
            <VBillPriceAmount
              value={field.value}
              setValue={field.onChange}
              errorMsg={fieldState.error?.message}
              sx={{ marginTop: "10px" }}
              textFieldInputProps={{ sx: { height: "30px" } }}
            />
          )}
        />

        <Divider sx={{ margin: "15px 0" }} />

        <Typography sx={{ marginBottom: "10px", fontSize: "16px" }}>Memo:</Typography>

        <Controller
          name="memo"
          control={payVBillsForm.control}
          render={({ field }) => (
            <TextField
              placeholder="Memo"
              {...field}
              sx={{ width: "100%" }}
              InputProps={{ sx: { height: "30px", width: "194px" } }}
            />
          )}
        />

        <Divider sx={{ margin: "15px 0" }} />

        {isAdditionalMappingsSettingsFetching ? (
          <Skeleton variant="rounded" sx={{ height: "35px", margin: "15px 0" }} />
        ) : (
          payFromAdditionalMapping && (
            <>
              <Typography sx={{ marginBottom: "10px", fontSize: "16px" }}>{payFromAdditionalMapping.label}:</Typography>

              {payFromAdditionalMapping && companyId && (
                <Controller
                  control={payVBillsForm.control}
                  name="paymentFromGlAccount"
                  rules={{
                    required: payFromAdditionalMapping.required
                      ? `${payFromAdditionalMapping.label} is required.`
                      : undefined,
                  }}
                  render={({ field, fieldState }) => (
                    <VBillPayBillStoreItemSelector
                      selectedStoreItem={field.value}
                      onSelectStoreItem={field.onChange}
                      additionalMappings={payFromAdditionalMapping}
                      invoiceCompanyId={companyId}
                      invoiceCoreOrgId={coreOrgId}
                      errorMsg={fieldState.error?.message}
                      btnSmallHeight
                    />
                  )}
                />
              )}
            </>
          )
        )}

        <Divider sx={{ margin: "15px 0" }} />
        <Box sx={{ marginTop: "20px" }}>
          {hasSubmitReqError && <GenericErrorMessage sx={{ marginBottom: "10px", textAlign: "right" }} />}

          <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <LoadingButton
              loading={isSubmitReqLoading}
              variant="contained"
              sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
              type="submit"
            >
              Pay
            </LoadingButton>
            <Button
              variant="outlined"
              type="button"
              onClick={onCloseDialog}
              sx={{ ...(actionsBtnSmallHeight ? { height: "30px" } : {}) }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};
