import { Box, Button, SxProps, Theme } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { useState } from "react";
import { VBillCompanyListPopover } from "./VBillCompanyListPopover/VBillCompanyListPopover";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

interface IVBillCompanyListFilterProps {
  onCompaniesChange: (values: string[]) => Promise<void>;
  companies: string[];
  smallHeight?: boolean;
  actionsBtnSmallHeight?: boolean;
  sx?: SxProps<Theme>;
}

export const VBillCompanyListFilter = ({
  onCompaniesChange,
  companies,
  smallHeight,
  actionsBtnSmallHeight,
  sx,
}: IVBillCompanyListFilterProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement>();
  const popoverVisible = Boolean(popoverAnchorEl);

  return (
    <Box sx={sx}>
      <Button
        onClick={(event) => setPopoverAnchorEl(event.currentTarget)}
        variant="outlined"
        endIcon={popoverVisible ? <ExpandLessIcon sx={expandIconStyles} /> : <ExpandMoreIcon sx={expandIconStyles} />}
        sx={{ ...(smallHeight ? { height: "30px" } : {}) }}
      >
        Companies
      </Button>

      {popoverVisible && (
        <VBillCompanyListPopover
          actionsBtnSmallHeight={actionsBtnSmallHeight}
          popoverAnchorEl={popoverAnchorEl}
          onDialogClose={() => setPopoverAnchorEl(undefined)}
          selectedCompanies={companies}
          onCompaniesSave={onCompaniesChange}
        />
      )}
    </Box>
  );
};
