import { Box, SxProps, Theme } from "@mui/material";
import { DragIndicatorIcon } from "components/common/icons";
import { FunctionComponent } from "react";
import { SeparatorProps } from "react-resizable-layout";
import { COLORS } from "themes/default";

interface ISidebarDraggableSeparatorProps extends SeparatorProps {
  isDragging: boolean;
  sx?: SxProps<Theme>;
}

export const SidebarDraggableSeparator: FunctionComponent<ISidebarDraggableSeparatorProps> = ({
  isDragging,
  sx,
  ...restOfProps
}) => (
  <Box
    tabIndex={0}
    sx={{
      position: "relative",
      flexShrink: 0,
      width: "5px",
      cursor: "col-resize",
      ...sx,
      ...(isDragging ? { backgroundColor: COLORS.newSecondaryColor } : {}),
    }}
    {...restOfProps}
  >
    <Box
      component="span"
      sx={{
        position: "absolute",
        top: "400px",
        left: "-5px",
        backgroundColor: COLORS.ghostWhite,
        borderRadius: "4px",
        padding: "8px 0",
      }}
    >
      <DragIndicatorIcon />
    </Box>
  </Box>
);
