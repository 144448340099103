import { Box, Typography } from "@mui/material";
import {
  IVBillBillsFiltersMappingInput,
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput,
  IVBillIStoreIntDataType,
} from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useMemo, useState } from "react";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { AdditionalMappingsDialog } from "../../../common/VBill/FilterComponents/VBillAdditionalMappingsFilter/AdditionalMappingsDialog/AdditionalMappingsDialog";
import { LightTooltip } from "../../common/LightTooltip/LightTooltip";
import { tooltipDefaultProps, typographyAsBtnStyles } from "../summariesStyles";
import { SummariesAdditionalMapping } from "./SummariesAdditionalMapping/SummariesAdditionalMapping";

export const SummariesAdditionalMappings = observer(function SummariesAdditionalMappings() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [isMappingsTooltipOpen, setIsMappingsTooltipOpen] = useState(false);
  const { batchDetails, vBillBatchAdditionalMappingsSettings } = vBillBatchDetailsStore;
  const batchDetailsData = batchDetails.data;
  const additionalMappingsSettingsData = vBillBatchAdditionalMappingsSettings.data?.invoice;
  const additionalMappingsSettings = useMemo(
    () =>
      (additionalMappingsSettingsData ?? []).filter(
        (additionalMapping) =>
          !additionalMapping.isContact &&
          additionalMapping.showIn !==
            IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
          additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
      ),
    [additionalMappingsSettingsData],
  );
  const totalAdditionalMappingsSettingsValues = useMemo(
    () =>
      additionalMappingsSettings.reduce(
        (result, mapping) =>
          (result =
            result +
            (batchDetailsData?.batchFilters?.mappings?.find(({ key }) => key === mapping.key)?.valuesIn?.length ?? 0)),
        0,
      ),
    [additionalMappingsSettings, batchDetailsData?.batchFilters?.mappings],
  );
  const [selectedAdditionalMapping, setSelectedAdditionalMapping] =
    useState<IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput>();

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    if (!batchDetailsData) {
      return;
    }

    const currentMappings = batchDetailsData?.batchFilters?.mappings ?? [];
    const newMappings = (
      currentMappings.find(({ key }) => key === value.key)
        ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
        : [...currentMappings, value]
    ).filter(({ valuesIn }) => valuesIn.length > 0);

    await vBillBatchDetailsStore.updateBatchDetails({
      batchId: batchDetailsData.id,
      batchData: {
        name: batchDetailsData.name,
        organizationId: batchDetailsData.organizationId,
        batchFilters: { ...batchDetailsData.batchFilters, mappings: newMappings },
      },
    });
    setSelectedAdditionalMapping(undefined);
  };

  if (!additionalMappingsSettings.length) {
    return null;
  }

  return (
    <>
      <LightTooltip
        open={isMappingsTooltipOpen}
        onOpen={() => setIsMappingsTooltipOpen(true)}
        onClose={() => setIsMappingsTooltipOpen(false)}
        {...tooltipDefaultProps}
        title={
          <Box>
            {additionalMappingsSettings.map((mapping) => (
              <SummariesAdditionalMapping
                key={mapping.key}
                mapping={mapping}
                filtersMapping={batchDetailsData?.batchFilters?.mappings?.find(({ key }) => key === mapping.key)}
                handleAddRemoveBtnClick={() => {
                  setIsMappingsTooltipOpen(false);
                  setSelectedAdditionalMapping(mapping);
                }}
              />
            ))}
          </Box>
        }
      >
        <Typography sx={typographyAsBtnStyles}>
          <Box sx={{ color: COLORS.logoBlue }}>Bills Categories:</Box>{" "}
          <strong>({totalAdditionalMappingsSettingsValues})</strong>
        </Typography>
      </LightTooltip>

      {selectedAdditionalMapping && (
        <AdditionalMappingsDialog
          suggestionsComponent="dialog"
          storeType={selectedAdditionalMapping.storeDataType as unknown as IVBillIStoreIntDataType}
          storeKey={selectedAdditionalMapping.key}
          mapping={batchDetailsData?.batchFilters?.mappings?.find(({ key }) => key === selectedAdditionalMapping.key)}
          batchCompanyIds={batchDetailsData?.batchFilters?.companyIds}
          onCloseDialog={() => setSelectedAdditionalMapping(undefined)}
          searchPlaceholder={`Search ${selectedAdditionalMapping.label ?? ""}`}
          onMappingSave={handleAdditionalMappingChange}
          dialogTitle={selectedAdditionalMapping.label ?? ""}
          actionsBtnSmallHeight
        />
      )}
    </>
  );
});
