import CloseIcon from "@mui/icons-material/Close";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Decimal } from "common/helpers/decimal";
import { formatCurrency } from "common/helpers/utils";
import { TVBillFormFields, TVBillFormLineFields } from "components/pages/VBill/types";
import { isNil } from "lodash";
import { useState } from "react";
import { useWatch } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { COLORS } from "themes/default";

interface IVBillToolbarTotalDifferentAlertProps {
  onCloseDialog: () => void;
  onSubmitDialog: (totalAmount: string) => void;
}

export const VBillToolbarTotalDifferentAlert = ({
  onCloseDialog,
  onSubmitDialog,
}: IVBillToolbarTotalDifferentAlertProps) => {
  const vBillFormlineItemsObserver: TVBillFormLineFields[] = useWatch({ name: "lineItems" });
  const vBillFormTotalObserver: TVBillFormFields["amount"] = useWatch({ name: "amount" });
  const [totalValue, setTotalValue] = useState(vBillFormTotalObserver);

  const totalFormItemsAmount = (vBillFormlineItemsObserver ?? []).reduce(
    (acc, val) => (!isNil(val.amount) ? Decimal.add(acc, val.amount || 0).toNumber() : acc),
    0,
  );

  return (
    <Dialog
      open
      onClose={onCloseDialog}
      PaperProps={{
        sx: { padding: "15px", width: "280px", position: "relative" },
      }}
    >
      <Tooltip arrow title="Close">
        <IconButton onClick={onCloseDialog} sx={{ position: "absolute", top: "5px", right: "5px", zIndex: 1 }}>
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      </Tooltip>
      <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>Invoice details</Typography>

      <Typography sx={{ color: COLORS.error, margin: "0 0 10px 0" }}>
        Total is different than lines amount sum: <strong>{formatCurrency(totalFormItemsAmount)}</strong>, create
        anyway?
      </Typography>

      <Typography sx={{ marginBottom: "5px" }}>Set new total due:</Typography>

      <NumericFormat
        allowNegative={false}
        value={totalValue}
        prefix="$"
        onValueChange={(values) => setTotalValue(values.value)}
        thousandSeparator
        decimalScale={2}
        customInput={TextField}
        InputProps={{
          sx: { width: "140px", height: "38px" },
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip arrow title="Apply line items total">
                <IconButton edge="end" onClick={() => setTotalValue(`${totalFormItemsAmount}`)}>
                  <SettingsSuggestIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />

      <Divider sx={{ margin: "30px 0 20px" }} />

      <Box sx={{ display: "flex", justifyContent: "end", gap: "15px" }}>
        <Button variant="contained" onClick={() => onSubmitDialog(totalValue)} sx={{ height: "30px" }}>
          Create
        </Button>

        <Button variant="outlined" onClick={onCloseDialog} sx={{ height: "30px" }}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
