import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { IVBillBillsFiltersInput } from "generated/sdk.vbill";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "storeContainer";
import { TStatsFiltersAppliedForBillsList } from "storesMobx/VBillBatchDetailsStore";
import { SidebarStatsSimulator } from "../SidebarStatsSimulator/SidebarStatsSimulator";

/*
radio
  clear btn
  if selected apply on selected
*/

export const SidebarStatsGlobalSimulator = observer(function SidebarStatsGlobalSimulator() {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const [criteriaAppliedFor, setCriteriaAppliedFor] = useState<TStatsFiltersAppliedForBillsList>("available");
  const [filters, setFilters] = useState<IVBillBillsFiltersInput>();
  const { organizationId } = useParams<{ organizationId: string }>();

  const { batchSelectedVBills } = vBillBatchDetailsStore;
  const batchSelectedVBillsData = batchSelectedVBills.data;

  useEffect(() => {
    // TODO: no selected bills based on filters? it will reset
    // setCriteriaAppliedFor((batchSelectedVBillsData?.items ?? []).length > 0 ? "selected" : "available");
  }, [batchSelectedVBillsData]);

  useEffect(() => {
    if (criteriaAppliedFor) {
      vBillBatchDetailsStore.setStatsFiltersAppliedForBills(criteriaAppliedFor);
    }
  }, [vBillBatchDetailsStore, criteriaAppliedFor]);

  const onFiltersSet = (filters: IVBillBillsFiltersInput) => {
    // console.log("onFiltersSet", filters);
    vBillBatchDetailsStore.setBatchFilters({
      ...(criteriaAppliedFor === "available"
        ? { vbill_ava_fil: filters, vbill_sel_fil: undefined }
        : { vbill_sel_fil: filters, vbill_ava_fil: undefined }),
    });
  };

  const batchId = vBillBatchDetailsStore.batchDetails.data?.id || 0;

  const baseFilters: IVBillBillsFiltersInput = useMemo(
    () =>
      criteriaAppliedFor === "available"
        ? { batch_id_in: [0] } // vBillBatchDetailsStore.batchFilters.vbill_ava_fil
        : { batch_id_in: [batchId] }, // vBillBatchDetailsStore.batchFilters.vbill_sel_fil;
    [criteriaAppliedFor, batchId],
  );
  return (
    <Box sx={{ marginBottom: "50px" }}>
      <FormControl sx={{ marginBottom: "20px" }}>
        <FormLabel id="criteria-applied-for">Apply for:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="criteria-applied-for"
          name="criteria-applied-for-group"
          value={criteriaAppliedFor ?? null}
          onChange={(e) => setCriteriaAppliedFor(e.target.value as "selected" | "available")}
        >
          <FormControlLabel value="selected" control={<Radio color="secondary" />} label="Selected Bills" />
          <FormControlLabel value="available" control={<Radio color="secondary" />} label="Available Bills" />
        </RadioGroup>
      </FormControl>

      <SidebarStatsSimulator
        baseFilters={{
          ...baseFilters,
          organizationId,
        }}
        setFilters={onFiltersSet}
      />
    </Box>
  );
});
