import CommentIcon from "@mui/icons-material/Comment";
import ForumIcon from "@mui/icons-material/Forum";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import PaymentIcon from "@mui/icons-material/Payment";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs, { TabsActions } from "@mui/material/Tabs";
import { ClearVendorRulesButton } from "components/common/Approval/ClearVendorRulesButton";
import { DelegationTreeView } from "components/common/Approval/DelegationTreeView";
import { getApprovalFlags } from "components/common/Approval/getApprovalFlags";
import { useVendorGroups } from "components/common/Approval/useVendorGroups";
import { ApprovalIcon, AttachFileIcon, ChatIcon, HistoryIcon } from "components/common/icons";
import { IActionlogObjectType } from "generated/graphql.actionlog";
import { DelegationGroupScopeEnum } from "generated/sdk";
import { IVBillInvoicePushStatus, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import {
  ChatDetailDashboard,
  IChatChannelParentType,
  IChatContentBlockMessageRelatedType,
  TBaseIoChannel,
} from "modules/Chat";
import { forwardRef, SyntheticEvent, useImperativeHandle, useMemo, useRef } from "react";
import { useParams } from "react-router";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { ActivityLog } from "../../../../common/ActivityLog/ActivityLog";
import { useCurrentVBillRoute } from "../../hooks";
import { Attachments } from "../Attachments/Attachments";
import { BillPayments } from "../BillPayments/BillPayments";
import { a11yProps, TabBtn, TabBtnSmall } from "./TabBtn/TabBtn";
import { TabPanel } from "./TabPanel/TabPanel";

export const vBillSidebarPanelQSKey = "sidebar-tab-panel";
export enum vBillSidebarPanels {
  "ATTACHMENTS" = "attachments",
  "CHAT" = "chat",
  "ACTIVITY_LOG" = "activity_log",
  "BILL_PAYMENTS" = "bill_payments",
  "APPROVAL" = "approval",
}
export const vBillChatPanelQSKey = "chat_panel";
export enum vBillChatPanels {
  "SR_CHAT" = "sr_chat",
  "SUPPORT_CHAT" = "support_chat",
  "RS_CHAT" = "rs_chat",
}

export interface ISidebarTabsRef {
  tabsUpdateIndicator: () => void;
}

interface ISidebarTabsProps {
  invoice?: IVBillVBillInvoiceQuery["invoice"] | null;
}

export const SidebarTabs = forwardRef<ISidebarTabsRef, ISidebarTabsProps>(({ invoice }, ref) => {
  const sessionStore = useStore("SessionStore");
  const { isIncomingRoute } = useCurrentVBillRoute();
  const { organizationId } = useParams<{ organizationId: string }>();
  const sessionAccountId = useMemo(
    () => sessionStore.session.data?.account?.id ?? "",
    [sessionStore.session.data?.account?.id],
  );
  const organizationName = useMemo(
    () =>
      sessionStore.session.data?.organizationUsers?.find(({ organization }) => organization.id === organizationId)
        ?.organization.name ?? "",
    [organizationId, sessionStore.session.data?.organizationUsers],
  );
  const [sidebarPanel, setSidebarPanel] = useQueryParam<vBillSidebarPanels>(
    vBillSidebarPanelQSKey,
    withDefault<any, vBillSidebarPanels>(
      StringParam,
      invoice?.payments?.length
        ? vBillSidebarPanels.BILL_PAYMENTS
        : invoice?.files?.length
        ? vBillSidebarPanels.ATTACHMENTS
        : vBillSidebarPanels.ACTIVITY_LOG,
    ),
    { removeDefaultsFromUrl: true },
  );
  const [chatPanel, setChatPanel] = useQueryParam<vBillChatPanels>(
    vBillChatPanelQSKey,
    withDefault<any, vBillChatPanels>(StringParam, vBillChatPanels.SUPPORT_CHAT),
    { removeDefaultsFromUrl: true },
  );
  const tabsDomAction = useRef<TabsActions>(null);
  const [vendorGroupsResponse, vendorGroupsLoading, refreshVendorRules] = useVendorGroups(invoice);
  const activityLogFilters = useMemo(
    () => ({ type: IActionlogObjectType.Bill, key: `${invoice?.id}`, organizationId }),
    [invoice?.id, organizationId],
  );
  const chatRelatedItems = useMemo(
    () =>
      invoice?.lineItems?.map(({ id, description, amount }) => ({
        key: `${id ?? ""}`,
        related: {
          key: `${id ?? ""}`,
          label: `${id ?? ""} - ${description} - ${amount}`,
          type: IChatContentBlockMessageRelatedType.Bill,
        },
      })),
    [invoice?.lineItems],
  );

  // SidebarTabPanels.SUPPORT_CHAT
  const supportChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
    () => ({
      parentContext: "sending-admin",
      parentKey: `${invoice?.id ?? ""}`,
      parentType: IChatChannelParentType.Vbill,
    }),
    [invoice?.id],
  );

  //SidebarTabPanels.SR_CHAT
  const srChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
    () => ({
      parentContext: "sending",
      parentKey: `${invoice?.id ?? ""}`,
      parentType: IChatChannelParentType.Vbill,
    }),
    [invoice?.id],
  );

  //SidebarTabPanels.RS_CHAT
  const rsChatDetailDashboardBaseIoChannel: TBaseIoChannel = useMemo(
    () => ({
      parentContext: "sending-receiving",
      parentKey: `${invoice?.id ?? ""}`,
      parentType: IChatChannelParentType.Vbill,
    }),
    [invoice?.id],
  );

  useImperativeHandle(ref, () => ({
    tabsUpdateIndicator() {
      tabsDomAction.current?.updateIndicator();
    },
  }));

  if (!invoice) {
    return null;
  }

  const showApprovalTab = invoice.approvalStatus
    ? getApprovalFlags(invoice.approvalStatus, sessionStore.selectedOrganizationUserId!).showApprovalWidget
    : false;
  const tabBtns = [
    ...(invoice.pushStatus === IVBillInvoicePushStatus.Done
      ? [{ label: "Payments", icon: <PaymentIcon />, value: vBillSidebarPanels.BILL_PAYMENTS }]
      : []),
    { label: "Chat", icon: <CommentIcon />, value: vBillSidebarPanels.CHAT },
    { label: "Activity log", icon: <HistoryIcon />, value: vBillSidebarPanels.ACTIVITY_LOG },
    ...(invoice.files?.length
      ? [{ label: "Attachments", icon: <AttachFileIcon />, value: vBillSidebarPanels.ATTACHMENTS }]
      : []),
    ...(showApprovalTab ? [{ label: "Approval", icon: <ApprovalIcon />, value: vBillSidebarPanels.APPROVAL }] : []),
  ];

  return (
    <Box
      sx={{
        height: "calc(100vh - 91px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Tabs
        sx={{ borderBottom: 1, borderColor: COLORS.lightestGrey }}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        value={sidebarPanel}
        onChange={(_event: SyntheticEvent<Element, Event>, value: vBillSidebarPanels) => {
          if (value !== vBillSidebarPanels.CHAT) {
            setChatPanel(vBillChatPanels.SUPPORT_CHAT);
          }
          setSidebarPanel(value);
        }}
        aria-label="invoice tabs"
        action={tabsDomAction}
      >
        {tabBtns.map((tabBtn, index) => (
          <TabBtn
            icon={tabBtn.icon}
            iconPosition="start"
            key={index}
            label={tabBtn.label}
            {...a11yProps(index)}
            value={tabBtn.value}
          />
        ))}
      </Tabs>

      {Boolean(invoice.files?.length) && (
        <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.ATTACHMENTS}>
          <Attachments invoice={invoice} enableOcr={isIncomingRoute} />
        </TabPanel>
      )}

      <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.ACTIVITY_LOG}>
        <ActivityLog filters={activityLogFilters} reloadObserver={invoice} />
      </TabPanel>

      <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.CHAT} sx={{ paddingTop: 0 }}>
        <Tabs
          textColor="primary"
          indicatorColor="primary"
          centered
          value={chatPanel}
          onChange={(_event: SyntheticEvent<Element, Event>, value: vBillChatPanels) => setChatPanel(value)}
          aria-label="chat tabs"
          action={tabsDomAction}
          sx={{ minHeight: "auto" }}
        >
          <TabBtnSmall
            icon={<ChatIcon />}
            iconPosition="start"
            label="Cherry"
            {...a11yProps(0)}
            value={vBillChatPanels.SUPPORT_CHAT}
          />
          <TabBtnSmall
            icon={<ForumIcon />}
            iconPosition="start"
            label={organizationName}
            {...a11yProps(1)}
            value={vBillChatPanels.SR_CHAT}
          />
          <TabBtnSmall
            icon={<MarkChatReadIcon />}
            iconPosition="start"
            label={invoice?.senderName ?? ""}
            {...a11yProps(2)}
            value={vBillChatPanels.RS_CHAT}
          />
        </Tabs>

        <TabPanel activePannel={chatPanel} panelName={vBillChatPanels.SUPPORT_CHAT} sx={{ padding: "0" }}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: supportChatDetailDashboardBaseIoChannel,
              organizationId: organizationId ?? "",
              sessionAccountId: sessionAccountId,
            }}
            settings={{
              forcePublic: true,
              // channelComingSoon: isNil(organizationId),
            }}
            relatedItems={chatRelatedItems}
            sx={{ height: `calc(100vh - 210px)` }}
            channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
          />
        </TabPanel>
        <TabPanel activePannel={chatPanel} panelName={vBillChatPanels.SR_CHAT} sx={{ padding: "0" }}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: srChatDetailDashboardBaseIoChannel,
              organizationId: organizationId ?? "",
              sessionAccountId: sessionAccountId,
            }}
            settings={{
              showMembers: true,
              showVisibility: true,
              // channelComingSoon: isNil(organizationId),
            }}
            relatedItems={chatRelatedItems}
            sx={{ height: `calc(100vh - 210px)` }}
            channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
          />
        </TabPanel>
        <TabPanel activePannel={chatPanel} panelName={vBillChatPanels.RS_CHAT} sx={{ padding: "0" }}>
          <ChatDetailDashboard
            config={{
              baseIoChannel: rsChatDetailDashboardBaseIoChannel,
              organizationId: organizationId ?? "",
              sessionAccountId: sessionAccountId,
            }}
            settings={{
              showMembers: true,
              showVisibility: true,
              channelComingSoon: true,
            }}
            relatedItems={chatRelatedItems}
            sx={{ height: `calc(100vh - 210px)` }}
            channelSx={{ height: "100%", display: "flex", flexDirection: "column" }}
          />
        </TabPanel>
      </TabPanel>

      {invoice.pushStatus === IVBillInvoicePushStatus.Done && (
        <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.BILL_PAYMENTS}>
          <BillPayments invoice={invoice} />
        </TabPanel>
      )}

      {showApprovalTab && (
        <TabPanel activePannel={sidebarPanel} panelName={vBillSidebarPanels.APPROVAL}>
          {invoice.approvalStatus ? (
            <>
              <ClearVendorRulesButton
                scope={DelegationGroupScopeEnum.VbillApprove}
                isRootDelegator={vendorGroupsResponse?.is_root_delegator!}
                refreshVendorRules={refreshVendorRules}
                invoice={invoice}
              />
              <Box className="tree-wrapper" sx={{ width: "610px", height: "500px", margin: "0 auto" }}>
                <DelegationTreeView
                  approvalStatus={invoice.approvalStatus}
                  organizationId={invoice.coreOrgId}
                  billId={invoice.hashedId}
                  translate={{ x: 305, y: 50 }}
                />
              </Box>
            </>
          ) : (
            <Box>
              <Alert severity="info">
                The list of approvers will become available once the bill is ready for approval.
              </Alert>
            </Box>
          )}
        </TabPanel>
      )}
    </Box>
  );
});
