import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { VBillMappingAutocompleteSelector } from "components/pages/VBill/common/Forms/VBillMappingAutocompleteSelector/VBillMappingAutocompleteSelector";
import { TVBillFormAutoComOption, TVBillFormFields } from "components/pages/VBill/types";
import { IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput } from "generated/sdk.vbill";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { COLORS } from "themes/default";

interface ISelectAllMappingFieldSelectorProps {
  additionalMappings: IVBillIntegrationSettingsVBillAdditionalMappingsEntryOutput;
  invoiceCoreOrgId: string;
  invoiceCompanyId: string;
}

export const SelectAllMappingFieldSelector = ({
  invoiceCoreOrgId,
  invoiceCompanyId,
  additionalMappings: { label, storeDataType, key },
  additionalMappings,
}: ISelectAllMappingFieldSelectorProps) => {
  const formMethods = useFormContext<TVBillFormFields>();

  const [selectedStoreItem, setSelectedStoreItem] = useState<TVBillFormAutoComOption>();
  const [isAutocompleteDialogOpen, setIsAutocompleteDialogOpen] = useState(false);

  const handleAutocompleteSelect = async (option: IVBillSingleSelectAutocompleteSuggestion) => {
    setIsAutocompleteDialogOpen(false);

    const currentVBillFormValues = formMethods.getValues();
    const currentValue: TVBillFormAutoComOption = {
      label: option?.name ?? "",
      label2: option.label,
      addMappingSettingKey: key,
      key: option?.id ?? "",
    };

    setSelectedStoreItem(currentValue);

    currentVBillFormValues.lineItems?.forEach((_, index) => {
      formMethods.setValue(`lineItems.${index}.${storeDataType}` as any, currentValue, {
        shouldValidate: true,
      });
    });
  };

  const handleClearBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const currentVBillFormValues = formMethods.getValues();

    setSelectedStoreItem(undefined);
    currentVBillFormValues.lineItems?.forEach((_, index) => {
      formMethods.setValue(`lineItems.${index}.${storeDataType}` as any, null, {
        shouldValidate: true,
      });
    });
  };

  return (
    <Box>
      <Typography sx={{ marginBottom: "5px", color: COLORS.logoBlue }}>{label ?? storeDataType}:</Typography>

      <Button
        variant="outlined"
        onClick={() => setIsAutocompleteDialogOpen(true)}
        endIcon={
          <Box sx={{ display: "flex" }}>
            {selectedStoreItem && (
              <Tooltip arrow title="Clear value">
                <IconButton onClick={handleClearBtnClick} sx={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip arrow title="Open">
              <IconButton sx={{ padding: 0 }}>
                {isAutocompleteDialogOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        }
        sx={{
          justifyContent: "space-between",
          textAlign: "left",
          height: "32px",
          marginBottom: "10px",
        }}
      >
        <Typography noWrap>
          {selectedStoreItem
            ? `${selectedStoreItem.label} ${selectedStoreItem.label2 ? `- ${selectedStoreItem.label2}` : ""}`
            : "Select"}
        </Typography>
      </Button>

      {isAutocompleteDialogOpen && (
        <VBillMappingAutocompleteSelector
          suggestionsComponent="dialog"
          additionalMappings={additionalMappings}
          invoiceCoreOrgId={invoiceCoreOrgId}
          invoiceCompanyId={invoiceCompanyId}
          onCloseAutocomplete={() => setIsAutocompleteDialogOpen(false)}
          onAutocompleteSelect={handleAutocompleteSelect}
          autocompleteSelectedSearchTerm={selectedStoreItem?.label ?? ""}
        />
      )}
    </Box>
  );
};
