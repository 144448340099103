import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { COLORS } from "themes/default";

interface IVBillCopyEmailToClipboardProps {
  email: string;
}

export const VBillCopyEmailToClipboard = ({ email }: IVBillCopyEmailToClipboardProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyBtnClick = () => {
    window.navigator.clipboard.writeText(email);
    setCopied(true);
    const timeoutId = setTimeout(() => {
      setCopied(false);
      clearTimeout(timeoutId);
    }, 2500);
  };

  return (
    <Tooltip title={copied ? `Copied: ${email}` : "Copy email to clipboard"} arrow>
      {copied ? (
        <Box component="span" sx={{ padding: "6.5px 8px" }}>
          <DoneOutlineIcon sx={{ color: COLORS.success }} />
        </Box>
      ) : (
        <IconButton onClick={handleCopyBtnClick}>
          <ContentCopyIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};
