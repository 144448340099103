import { Box, Typography } from "@mui/material";
import { graphqlVBillClient } from "common/graphqlClient";
import { formatCurrency, getSODAsUTCIsoString } from "common/helpers/utils";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import {
  getSdk,
  IVBillBillsFiltersInput,
  IVBillBillsGroupingType,
  IVBillBillsStatsGroupingInput,
  IVBillVBillGetBillsStatsQuery,
} from "generated/sdk.vbill";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetState } from "react-use";
import { useStore } from "storeContainer";
import { VBillBatchDetailsStore } from "storesMobx/VBillBatchDetailsStore";

const { VBillGetBillsStats } = getSdk(graphqlVBillClient);

export type IFilter = { source?: string; value: string; label: string };
export type ICriteria = {
  key: string;
  label: string;
  grouping: IVBillBillsStatsGroupingInput;
  filtersReduce: (filters: IVBillBillsFiltersInput, value: IFilter) => IVBillBillsFiltersInput;
};
const echartsOption = (name: string, data: EChartsOption["data"]) => ({
  tooltip: {
    trigger: "item",
  },
  // legend: {
  //   top: "5%",
  //   left: "center",
  // },
  series: [
    {
      name,
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: true,
      itemStyle: {
        borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      // label: {
      //   show: true,
      //   // position: "center",
      // },
      // emphasis: {
      //   label: {
      //     show: true,
      //     fontSize: 40,
      //     fontWeight: "bold",
      //   },
      // },
      // labelLine: {
      //   show: true,
      // },
      data,
    },
  ],
});
const useCriteriaList = () => {
  const vBillBatchDetailsStore = useStore("VBillBatchDetailsStore");
  const invoiceAdditionalMappings = vBillBatchDetailsStore.vBillBatchAdditionalMappingsSettings.data?.invoice || [];
  const criteriaList: ICriteria[] = [
    {
      key: "company",
      label: "Company",
      grouping: {
        type: IVBillBillsGroupingType.CompanyId,
      },
      filtersReduce: (filters, value) => {
        filters.companyIds = [value.value];
        return filters;
      },
    },
    ...invoiceAdditionalMappings.map(
      (am): ICriteria => ({
        key: am.key,
        label: am.label || "",
        grouping: {
          type: IVBillBillsGroupingType.MappingKey,
          value: am.key,
        },
        filtersReduce: (filters, value) => {
          filters.mappings = filters.mappings || [];
          filters.mappings.push({ key: am.key, valuesIn: [{ source: value.source, value: value.value }] });

          return filters;
        },
      }),
    ),
  ];
  return criteriaList;
};
const computeFilters = (
  baseFilters: IVBillBillsFiltersInput | undefined,
  parentCriteria: ICriteria[] | undefined,
  parentFilters: IFilter[] | undefined,
) => {
  let filters: IVBillBillsFiltersInput = {
    ...baseFilters,
    // date_due_from: dueDates.start?.length ? getSODAsUTCIsoString(new Date(dueDates.start)) : undefined,
    // date_due_to: dueDates.end?.length ? getSODAsUTCIsoString(new Date(dueDates.end)) : undefined,
  };

  if (parentCriteria?.length && parentFilters?.length)
    for (let i in parentCriteria) {
      const criteria = parentCriteria[i];
      const value = parentFilters[i];
      if (value) filters = criteria.filtersReduce(filters, value);
    }

  return filters;
};

const fetchStats = async (filters: IVBillBillsFiltersInput, grouping: IVBillBillsStatsGroupingInput) => {
  const resp = await (await VBillGetBillsStats({ filters, grouping })).getBillsStats;

  const data = _.sortBy(resp.groupStats, "amount")
    .filter((e) => !!e.label)
    .slice(0, 8)
    .map((s) => ({
      value: +s.amount,
      name: s.label || "",
      id: { source: s.source, value: s.value, label: s.label },
    }));
  const totalSum = +(+resp.generalStats.amount).toFixed(2);
  const dataSum = +data
    .map((e) => e.value)
    .reduce((a, b) => a + b, 0)
    .toFixed(2);
  const otherSum = totalSum - dataSum;
  const options = echartsOption("Bills Amount", [
    ...data,
    ...(otherSum ? [{ value: otherSum, name: "(Other)", itemStyle: { color: "#f0f0f0" } }] : []),
  ]);

  return { resp, options };
};

export const SidebarStatsSimulator = ({
  baseFilters,
  setFilters,
}: {
  setFilters?: (filters: IVBillBillsFiltersInput) => void;
  baseFilters?: IVBillBillsFiltersInput;
}) => {
  return <CriteriaStatsSimulator setFilters={setFilters} baseFilters={baseFilters} />;
};

const CriteriaStatsSimulator = ({
  parentCriteria,
  parentFilters,
  baseFilters,
  setFilters,
}: {
  setFilters?: (filters: IVBillBillsFiltersInput) => void;
  baseFilters?: IVBillBillsFiltersInput;
  parentCriteria?: ICriteria[];
  parentFilters?: IFilter[];
}) => {
  const [stats, setStats] = useState<IVBillVBillGetBillsStatsQuery["getBillsStats"]>();
  const [eChartsOption, setEChartsOption] = useState<EChartsOption>({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState<ICriteria | undefined>();
  const [selectedFilter, setSelectedFilter] = useState<IFilter | undefined>();

  const criteriaList: ICriteria[] = useCriteriaList();

  const availableCriteriaList = criteriaList.filter((c) => !parentCriteria?.find((pc) => pc.key === c.key));

  useEffect(() => {
    if (availableCriteriaList.length) setSelectedCriteria(availableCriteriaList[0]);
  }, []);

  useEffect(() => {
    if (selectedCriteria)
      (async () => {
        let filters: IVBillBillsFiltersInput = computeFilters(baseFilters, parentCriteria, parentFilters);
        setIsLoading(true);
        const { resp, options } = await fetchStats(filters, selectedCriteria.grouping);
        setStats(resp);
        setEChartsOption(options);
        setIsLoading(false);
      })();
  }, [selectedCriteria, parentFilters, parentCriteria, baseFilters]);

  const showMore = availableCriteriaList.length > 1 && selectedCriteria && selectedFilter;

  const onClick = (event: any) => {
    // console.log("zzz event", event);
    if (event?.data?.id) {
      const currentFilter = event?.data?.id as IFilter;
      if (selectedCriteria && setFilters) {
        const filters = computeFilters(
          baseFilters,
          [...(parentCriteria || []), selectedCriteria],
          [...(parentFilters || []), currentFilter],
        );
        setFilters(filters);
      }
      setSelectedFilter(currentFilter);
    }
  };

  return (
    <Box>
      {false && (
        <Typography>
          <pre>baseFilters={JSON.stringify(baseFilters, null, 2)}</pre>
          {/* <pre>{JSON.stringify(criteriaList, null, 2)}</pre> */}
          <pre>parentCriteria={JSON.stringify(parentCriteria?.map((e) => e.label).join(", "), null, 2)}</pre>
          <pre>parentFilters={JSON.stringify(parentFilters, null, 2)}</pre>
          <pre>
            availableCriteriaList={JSON.stringify(availableCriteriaList?.map((e) => e.label).join(", "), null, 2)}
          </pre>
          <pre>selectedCriteria={JSON.stringify(selectedCriteria?.label, null, 2)}</pre>
          {/* <pre>{JSON.stringify(stats ?? {}, null, 2)}</pre> */}
        </Typography>
      )}

      <Box>
        <select
          onChange={(e) => {
            console.log("onChange", e.currentTarget.value);
            setSelectedCriteria(criteriaList.find((c) => c.key === e.currentTarget.value));
          }}
          value={selectedCriteria?.key}
        >
          <option>(Select Criteria)</option>
          {availableCriteriaList.map((criteria, cidx) => (
            <option value={criteria.key} key={criteria.key}>
              By {criteria.label}
            </option>
          ))}
        </select>
        {selectedCriteria && (
          <>
            {stats ? (
              <span style={{ float: "right" }}>
                {parentFilters?.length
                  ? parentCriteria?.map((c, i) => {
                      const filter = parentFilters[i];
                      return (
                        <span title={c.label} style={{ padding: "5px" }} key={i}>
                          {filter.label}
                        </span>
                      );
                    })
                  : "Total in batch"}{" "}
                {formatCurrency(+stats?.generalStats.amount)}
              </span>
            ) : null}
            <ReactECharts option={eChartsOption} onEvents={{ click: onClick }} showLoading={isLoading} />
          </>
        )}
      </Box>

      {showMore && !isLoading && (
        <CriteriaStatsSimulator
          parentCriteria={[...(parentCriteria || []), selectedCriteria]}
          parentFilters={[...(parentFilters || []), selectedFilter]}
          setFilters={setFilters}
          baseFilters={baseFilters}
        />
      )}
    </Box>
  );
};
