import { Box, IconButton, Paper, Typography } from "@mui/material";
import { NavigateBeforeOutlinedIcon, NavigateNextOutlinedIcon } from "components/common/icons";
import { IVBillKAutoocrContentProcessor, IVBillTextItemSchema, IVBillVBillFilesOcrQuery } from "generated/sdk.vbill";
import { isNil } from "lodash";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useSetState } from "react-use";
import { VBillFileStreamPath } from "routes/routes";
import { useVBillDomElementWidth } from "../../hooks";
import { ImageTextItem } from "../OCRImage/ImageTextItem";

interface IAttachmentsViewerProps {
  imageFilesData?: IVBillVBillFilesOcrQuery["files"];
  onChangePageBtnClick: (pageNr: number) => void;
  enableOcr?: boolean;
}

export const AttachmentsViewer: FunctionComponent<IAttachmentsViewerProps> = ({
  imageFilesData,
  enableOcr,
  onChangePageBtnClick,
}) => {
  const [imageVisible, setImageVisible] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageScale, setImageScale] = useSetState<{ xScale: number; yScale: number }>();
  const [textItems, setTextItems] = useState<IVBillTextItemSchema[]>([]);
  const { domElementRef, width: domElementWidth } = useVBillDomElementWidth();

  useEffect(() => {
    if (enableOcr && imageVisible && !!domElementWidth) {
      if (!imageRef.current || !domElementRef.current) {
        return;
      }
      const xScale = domElementRef.current.clientWidth / imageRef.current.naturalWidth;
      const yScale = domElementRef.current.clientHeight / imageRef.current.naturalHeight;
      const tesseractOcrTextItems =
        imageFilesData?.items[0].ocrTextItems.filter(
          (ocrTextItem) => ocrTextItem.processor === IVBillKAutoocrContentProcessor.Googledocumentai,
        ) ?? [];

      setTextItems(tesseractOcrTextItems);
      setImageScale({ xScale, yScale });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableOcr, imageVisible, domElementWidth, imageFilesData]);

  if (!imageFilesData?.items.length) {
    return null;
  }

  const { total, items } = imageFilesData;
  const { id, pageNumber } = items[0];
  const imageFileUrl = `${VBillFileStreamPath}?id=${id ?? ""}`;
  const showMainViewImage = !isNil(id);

  return (
    <Box>
      {(imageFilesData?.total ?? 0) > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
          <IconButton
            aria-label="prev"
            onClick={() => {
              onChangePageBtnClick(pageNumber - 1);
              setImageVisible(false);
            }}
            disabled={pageNumber === 1}
          >
            <NavigateBeforeOutlinedIcon />
          </IconButton>

          <Typography fontWeight="bold">
            {pageNumber} / {total}
          </Typography>

          <IconButton
            aria-label="next"
            onClick={() => {
              onChangePageBtnClick(pageNumber + 1);
              setImageVisible(false);
            }}
            disabled={pageNumber === total}
          >
            <NavigateNextOutlinedIcon />
          </IconButton>
        </Box>
      )}
      <Box sx={{ marginBottom: "20px" }} ref={domElementRef}>
        <Paper sx={{ position: "relative" }}>
          {showMainViewImage && (
            <img
              ref={imageRef}
              src={imageFileUrl}
              key={imageFileUrl}
              alt="main view"
              style={{ width: "100%" }}
              onLoad={() => setImageVisible(true)}
            />
          )}

          {enableOcr &&
            imageVisible &&
            textItems.map((textItem, index) => (
              <ImageTextItem
                key={`${textItem.bBox?.x0}-${textItem.bBox?.y0}-${textItem.bBox?.x1}-${textItem.bBox?.y1}-${index}`}
                item={textItem}
                scale={imageScale}
              />
            ))}
        </Paper>
      </Box>
    </Box>
  );
};
