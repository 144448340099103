import { Box } from "@mui/material";
import { VBillAdditionalMappingsFilter } from "components/pages/common/VBill/FilterComponents/VBillAdditionalMappingsFilter/VBillAdditionalMappingsFilter";
import { VBillAmountFilter } from "components/pages/common/VBill/FilterComponents/VBillAmountFilter/VBillAmountFilter";
import { VBillDateFilter } from "components/pages/common/VBill/FilterComponents/VBillDateFilter/VBillDateFilter";
import { VBillSearchFilter } from "components/pages/common/VBill/FilterComponents/VBillSearchFilter/VBillSearchFilter";
import {
  IStatusOption,
  VBillStatusFilter,
} from "components/pages/common/VBill/FilterComponents/VBillStatusFilter/VBillStatusFilter";
import { friendlyPaymentsGroupingStatusNames } from "components/pages/common/VBill/utils";
import {
  IVBillBillsFiltersMappingInput,
  IVBillIPaymentSearchFilterContext,
  IVBillIStoreIntDataType,
  IVBillPaymentsGroupingStatus,
} from "generated/graphql.vbill";
import { sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useStore } from "storeContainer";
import { usePaymentsLedgerFilters, useVBillPaymentsLedgerChildRoute } from "../hooks";

const allBaseFilterOptions: IStatusOption[] = Object.values(IVBillPaymentsGroupingStatus).map((value) => ({
  label: friendlyPaymentsGroupingStatusNames[value],
  value,
}));
const allFilterOptions = sortBy(
  allBaseFilterOptions,
  (option) =>
    option.value === IVBillPaymentsGroupingStatus.Error || option.value === IVBillPaymentsGroupingStatus.Voided,
);
// const processingFilterOptions: IStatusOption[] = Object.values(IVBillPaymentsGroupingStatus)
//   .filter((group) => group === IVBillPaymentsGroupingStatus.Draft || group === IVBillPaymentsGroupingStatus.Pending)
//   .map((value) => ({
//     label: friendlyPaymentsGroupingStatusNames[value],
//     value,
//   }));
const voidedFilterOptions: IStatusOption[] = Object.values(IVBillPaymentsGroupingStatus)
  .filter((group) => group === IVBillPaymentsGroupingStatus.Error || group === IVBillPaymentsGroupingStatus.Voided)
  .map((value) => ({
    label: friendlyPaymentsGroupingStatusNames[value],
    value,
  }));

export const PaymentsListFilters = observer(() => {
  const vBillPaymentsLedgerStore = useStore("VBillPaymentsLedgerStore");
  const [paymenstLedgerFilters, setPayLedgerFilters] = usePaymentsLedgerFilters();
  const {
    isAllChildRoute,
    // isProcessingChildRoute, isCompletedChildRoute
    isVoidedChildRoute,
  } = useVBillPaymentsLedgerChildRoute();

  const additionalMappingsSettingsData = vBillPaymentsLedgerStore.companiesSettings.data?.invoice;
  const additionalMappingsVendorFirst = useMemo(
    () =>
      sortBy(additionalMappingsSettingsData?.filter((mappp) => mappp.isContact || mappp.isGlAccount) ?? [], [
        "isContact",
      ]), // TODO:
    [additionalMappingsSettingsData],
  );

  const handleAdditionalMappingChange = async (value: IVBillBillsFiltersMappingInput) => {
    const currentMappings = paymenstLedgerFilters.filters?.mappings ?? [];
    const newMappings = currentMappings.find(({ key }) => key === value.key)
      ? currentMappings.map((mapping) => (mapping.key === value.key ? value : mapping))
      : [...currentMappings, value];
    const newMappingsExcludeEmptyValuesIn = newMappings.filter(({ valuesIn }) => valuesIn.length > 0);

    setPayLedgerFilters((prev) => ({
      filters: { ...prev.filters, mappings: newMappingsExcludeEmptyValuesIn },
      pagination: { page: 1, per_page: prev.pagination.per_page },
    }));
  };

  const handleStatusFilterChange = (value: string, checked?: boolean | undefined) => {
    setPayLedgerFilters((prev) => {
      const paymentStatusValues = checked
        ? [...(prev.filters?.paymentStatus ?? []), value]
        : [...(prev.filters?.paymentStatus ?? []).filter((val) => val !== value)];

      return {
        filters: {
          ...prev.filters,
          paymentStatus: paymentStatusValues.length
            ? (paymentStatusValues as IVBillPaymentsGroupingStatus[])
            : undefined,
        },
        pagination: { page: 1, per_page: prev.pagination.per_page },
      };
    });
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", padding: "10px 0" }}>
      <VBillSearchFilter
        term={paymenstLedgerFilters.filters?.search?.term}
        onTermChange={(term) => {
          setPayLedgerFilters((prev) => ({
            filters: {
              search: {
                term: term?.length ? term : "",
                context: IVBillIPaymentSearchFilterContext.Payment,
              },
            },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }));
        }}
      />

      {isAllChildRoute && (
        <VBillStatusFilter
          status={paymenstLedgerFilters.filters?.paymentStatus}
          multiSelect
          onStatusChange={handleStatusFilterChange}
          options={allFilterOptions}
        />
      )}

      {isVoidedChildRoute && (
        <VBillStatusFilter
          status={paymenstLedgerFilters.filters?.paymentStatus}
          multiSelect
          onStatusChange={handleStatusFilterChange}
          options={voidedFilterOptions}
        />
      )}

      {/* {isCompletedChildRoute && (
          <VBillStatusFilter
            status={paymenstLedgerFilters.filters?.paymentStatus}
            multiSelect
            onStatusChange={handleStatusFilterChange}
            options={completedFilterOptions}
          />
      )} */}

      <VBillAmountFilter
        amount={{
          amount_from: paymenstLedgerFilters.filters?.amountFrom,
          amount_to: paymenstLedgerFilters.filters?.amountTo,
        }}
        onAmountChange={({ amount_from, amount_to }) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, amountFrom: amount_from, amountTo: amount_to },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
      />

      <VBillDateFilter
        date={{
          date: paymenstLedgerFilters.filters?.dateFrom,
          date_to: paymenstLedgerFilters.filters?.dateTo,
        }}
        onDateChange={({ date, date_to }) =>
          setPayLedgerFilters((prev) => ({
            filters: { ...prev.filters, dateFrom: date, dateTo: date_to },
            pagination: { page: 1, per_page: prev.pagination.per_page },
          }))
        }
        btnText="Date"
      />

      {/* {!isOverDueChildRoute && (
        <VBillDueDateFilter
          dueDate={{
            date_due_from: paymenstLedgerFilters.filters?.date_due_from,
            date_due_to: paymenstLedgerFilters.filters?.date_due_to,
          }}
          onDueDateChange={({ date_due_from, date_due_to }) =>
            setPayLedgerFilters((prev) => ({
              filters: { ...prev.filters, date_due_from, date_due_to },
              pagination: { page: 1, per_page: prev.pagination.per_page },
            }))
          }
          btnText="Due Date"
        />
      )} */}

      {additionalMappingsVendorFirst.map(({ label, key, storeDataType }) => (
        <VBillAdditionalMappingsFilter
          key={key}
          storeType={storeDataType as unknown as IVBillIStoreIntDataType}
          storeKey={key}
          batchCompanyIds={paymenstLedgerFilters.filters?.companyIds ?? []}
          searchPlaceholder={`Search ${label ?? ""}`}
          inputLabel={label ?? ""}
          mapping={paymenstLedgerFilters.filters?.mappings?.find((mapping) => mapping.key === key)}
          onMappingChange={handleAdditionalMappingChange}
        />
      ))}
    </Box>
  );
});
