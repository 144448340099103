import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Modal, Paper, Typography } from "@mui/material";
import { DelegationGroupScopeEnum } from "generated/sdk";
import { IVBillIStoreIntDataType, IVBillVBillInvoiceQuery } from "generated/sdk.vbill";
import { useState } from "react";
import { useStore } from "storeContainer";
import { CloseIcon } from "../icons";

interface IClearVendorRulesButtonProps {
  scope: DelegationGroupScopeEnum;
  isRootDelegator: boolean;
  invoice?: IVBillVBillInvoiceQuery["invoice"] | null;
  refreshVendorRules: () => void;
}

export const ClearVendorRulesButton: React.FunctionComponent<IClearVendorRulesButtonProps> = ({
  scope,
  isRootDelegator,
  invoice,
  refreshVendorRules,
}) => {
  const approvalStore = useStore("ApprovalStore");
  const vBillStore = useStore("VBillStore");
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!invoice || !isRootDelegator) {
    return null;
  }

  const organizationId = invoice.coreOrgId;
  const vendorId = invoice.additionalMappings?.find(
    (additionalMapping) => additionalMapping.type === IVBillIStoreIntDataType.Contact,
  )?.value;

  if (!vendorId) {
    return null;
  }

  const deleteVendorRules = async () => {
    await approvalStore.deleteDelegationGroup({
      scope,
      organization_id: organizationId,
      vendor_id: vendorId,
    });

    await refreshVendorRules();

    if (vBillStore.invoice.reload) {
      vBillStore.invoice.reload({ markAsLoading: false });
    }

    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)} variant="outlined">
        Clear vendor rules
      </Button>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ClearVendorRulesModalContent deleteVendorRules={deleteVendorRules} closeModal={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};

interface IClearVendorRulesModalContentProps {
  deleteVendorRules: () => Promise<void>;
  closeModal: () => void;
}
export const ClearVendorRulesModalContent: React.FunctionComponent<IClearVendorRulesModalContentProps> = ({
  deleteVendorRules,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const wrappedDeleteVendorRules = () => {
    setIsLoading(true);
    deleteVendorRules().finally(() => setIsLoading(false));
  };

  return (
    <Paper sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h3">Clear vendor rules</Typography>
        <CloseIcon onClick={closeModal} />
      </Box>
      <Box sx={{ width: "400px" }}>
        <Alert severity="info">
          Proceeding will delete all delegation rules in this organization targeting this vendor.
        </Alert>
        {/* <Box sx={{ padding: "20px 10px" }}>
          <FormControl>
            <FormLabel>
              <Typography variant="body2" sx={{ mb: "5px" }}>
                After removal, update delegation trees for:
              </Typography>
            </FormLabel>
            <RadioGroup
              value={updateOption}
              onChange={(e) => setUpdateOption(e.target.value as unknown as UpdateOptions)}
            >
              <FormControlLabel
                value={UpdateOptions.THIS_ITEM}
                control={<Radio />}
                label={<Typography variant="body2">Only this item</Typography>}
              />
              <FormControlLabel
                value={UpdateOptions.ALL}
                control={<Radio />}
                label={<Typography variant="body2">All items currently in approval</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Box> */}
        <Box sx={{ marginTop: "20px" }}>
          <LoadingButton variant="contained" loading={isLoading} onClick={() => wrappedDeleteVendorRules()}>
            Delete rules
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
};
