import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, InputAdornment, SxProps, TextField, Theme, Tooltip } from "@mui/material";
import { SearchIcon } from "icons/svg/SearchIcon";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

interface IVBillSearchFilterProps {
  term?: string | null;
  onTermChange: (term?: string) => void;
  smallHeight?: boolean;
  sx?: SxProps<Theme>;
  placeholder?: string;
}

export const VBillSearchFilter = ({ term, onTermChange, smallHeight, sx, placeholder }: IVBillSearchFilterProps) => {
  const [textFieldValue, setTextFieldValue] = useState(term ?? "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetQueryFilters = useCallback(
    debounce((searchValue: string) => {
      onTermChange(searchValue.length ? searchValue : undefined);
    }, 500),
    [],
  );

  useEffect(() => {
    if (!term) {
      setTextFieldValue("");
    }
  }, [term]);

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    setTextFieldValue(searchValue);
    debouncedSetQueryFilters(searchValue);
  };

  const handleCloseIconClick = () => {
    setTextFieldValue("");
    onTermChange(undefined);
  };

  return (
    <Box sx={sx}>
      <Tooltip arrow title={placeholder ?? "Search by Name"}>
        <TextField
          placeholder={placeholder ?? "Search by Name"}
          id="vbill-ledger-search"
          name="search"
          value={textFieldValue}
          onChange={handleTextFieldChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {term?.length ? (
                  <IconButton onClick={handleCloseIconClick}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            sx: { ...(smallHeight ? { height: "30px" } : {}) },
          }}
        />
      </Tooltip>
    </Box>
  );
};
