import { TableCell, TableRow, Typography } from "@mui/material";
import { formatCurrency } from "common/helpers/utils";
import { lineItemAdditionalMappingsToFormLineFields } from "components/pages/VBill/utils";
import {
  IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn,
  IVBillLineItemSchema,
  IVBillVBillSettingsQuery,
} from "generated/sdk.vbill";
import { partition } from "lodash";
import { observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import { COLORS } from "themes/default";

const defaultTableCellSx = {
  padding: "8px 5px",
};

interface ISummaryLineItemProps {
  lineItem: IVBillLineItemSchema;
  index: number;
  vBillAdditionalMappingsSettings?: IVBillVBillSettingsQuery["settings"]["vBill"]["additionalMappings"];
}

export const SummaryLineItem = observer(function SummaryLineItem({
  lineItem,
  index,
  vBillAdditionalMappingsSettings,
}: ISummaryLineItemProps) {
  const [glAccMapping, addMappings] = useMemo(() => {
    const lineAdditionalMappings = (vBillAdditionalMappingsSettings?.line ?? []).filter(
      (additionalMapping) =>
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.Payment &&
        additionalMapping.showIn !== IVBillIIntegrationIntegrationSettingsVBillAdditionalMappingsEntryShowIn.None,
    );

    return partition(lineAdditionalMappings, (map) => map.isGlAccount);
  }, [vBillAdditionalMappingsSettings?.line]);

  const lineItemMappings = useMemo(() => lineItemAdditionalMappingsToFormLineFields(lineItem), [lineItem]);

  const { description, qty, rate, amount } = lineItem;

  return (
    <TableRow sx={{ cursor: "default" }}>
      <TableCell sx={{ ...defaultTableCellSx, width: "35px" }}>#{index + 1}</TableCell>
      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "200px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Description:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{description ?? "-"}</Typography>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Gl Account:</Typography>
        {glAccMapping.map((additionalMapping) => (
          <Fragment key={additionalMapping.key}>
            {lineItemMappings[additionalMapping.storeDataType] ? (
              <Typography>{`${lineItemMappings[additionalMapping.storeDataType]?.label ?? "-"}${
                lineItemMappings[additionalMapping.storeDataType]?.label2
                  ? ` - ${lineItemMappings[additionalMapping.storeDataType]?.label2}`
                  : ""
              }`}</Typography>
            ) : (
              "-"
            )}
          </Fragment>
        ))}
      </TableCell>
      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Additional Fields:</Typography>
        {addMappings.length
          ? addMappings.map(
              (additionalMapping) =>
                lineItemMappings[additionalMapping.storeDataType] && (
                  <Fragment key={additionalMapping.key}>
                    <>
                      <Typography sx={{ fontSize: "15px" }}>{additionalMapping.label}:</Typography>
                      <Typography sx={{ color: COLORS.textGray, marginBottom: "5px" }}>{`${
                        lineItemMappings[additionalMapping.storeDataType]?.label ?? "-"
                      }${
                        lineItemMappings[additionalMapping.storeDataType]?.label2
                          ? ` - ${lineItemMappings[additionalMapping.storeDataType]?.label2}`
                          : ""
                      }`}</Typography>
                    </>
                  </Fragment>
                ),
            )
          : "-"}
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "60px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Qty:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{qty ?? "1"}</Typography>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "60px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Rate:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{rate ?? "0"}</Typography>
      </TableCell>

      <TableCell sx={{ ...defaultTableCellSx, verticalAlign: "top", width: "80px" }}>
        <Typography sx={{ color: COLORS.textGray, marginBottom: "8px" }}>Total:</Typography>
        <Typography sx={{ wordWrap: "break-word" }}>{formatCurrency(Number(amount ?? 0))}</Typography>
      </TableCell>
    </TableRow>
  );
});
