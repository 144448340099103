import { Box, Button, FormControl, FormHelperText, SxProps, Theme, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "components/common/icons";
import { Spinner } from "components/common/Spinner/Spinner";
import { IVBillMultiSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsMultiSelectAutocomplete/VBillSuggestionsMultiSelectAutocomplete";
import { IVBillSingleSelectAutocompleteSuggestion } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocomplete";
import { VBillSuggestionsSingleSelectAutocompleteComp } from "components/pages/common/VBill/VBillSuggestionsAutocomplete/VBillSuggestionsSingleSelectAutocomplete/VBillSuggestionsSingleSelectAutocompleteComp";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { useStore } from "storeContainer";
import { COLORS } from "themes/default";
import { IInvoiceProps } from "../../types";

const expandIconStyles: SxProps<Theme> = { fontSize: "16px !important", marginRight: "4px" };

export const NewSelectCompany = observer(function NewSelectCompany({ invoice }: IInvoiceProps) {
  const organizationStore = useStore("OrganizationStore");
  const vBillStore = useStore("VBillStore");
  const {
    field: { value: formFieldValue, onChange: formFieldOnChange },
    fieldState: { error },
  } = useController({
    name: "companyId",
    rules: { required: "Company is required" },
  });
  const allCompanies = useMemo(
    () =>
      sortBy(organizationStore.organizationVBillEnabledCompanies.data?.items ?? [], (comp) => comp.name).map(
        ({ name, companyId }) => ({
          name,
          id: companyId,
        }),
      ),
    [organizationStore.organizationVBillEnabledCompanies.data?.items],
  );
  const [showUpdateInvoiceLoader, setShowUpdateInvoiceLoader] = useState(false);
  const [isAutocompleteDialogOpen, setIsAutocompleteDialogOpen] = useState(false);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<IVBillMultiSelectAutocompleteSuggestion[]>([]);
  const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState("");
  const selectedCompany = useMemo(
    () => allCompanies.find(({ id }) => id === formFieldValue),
    [allCompanies, formFieldValue],
  );
  useEffect(() => setAutocompleteSearchTerm(selectedCompany?.name ?? ""), [selectedCompany?.name]);
  useEffect(() => {
    const suggestionsSelectedFirst = sortBy(allCompanies, (comp) => comp.id !== formFieldValue);

    setAutocompleteSuggestions(suggestionsSelectedFirst);
  }, [allCompanies, formFieldValue]);

  const handleAutocompleteSuggestionClick = async (option: IVBillSingleSelectAutocompleteSuggestion) => {
    setIsAutocompleteDialogOpen(false);
    setShowUpdateInvoiceLoader(true);
    formFieldOnChange(option.id);
    const resp = await vBillStore.updateInvoiceCompany({
      updateData: { companyCoreId: option.id, invoiceIds: [invoice?.id ?? 0] },
    });

    if (!resp) {
      setAutocompleteSearchTerm("");
      formFieldOnChange(null);
      vBillStore.settings.flush();
    }

    setShowUpdateInvoiceLoader(false);
  };

  return (
    <Box>
      <Typography sx={{ fontSize: 16, marginBottom: "10px", fontWeight: 500 }} color={COLORS.logoBlue}>
        Company:
      </Typography>

      <Button
        variant="outlined"
        onClick={() => setIsAutocompleteDialogOpen(true)}
        endIcon={
          showUpdateInvoiceLoader ? (
            <Spinner />
          ) : isAutocompleteDialogOpen ? (
            <ExpandLessIcon sx={expandIconStyles} />
          ) : (
            <ExpandMoreIcon sx={expandIconStyles} />
          )
        }
        sx={{
          width: "100%",
          justifyContent: "space-between",
          color: COLORS.newPrimaryColor,
          ...(error ? { borderColor: "rgb(176, 0, 32) !important" } : {}),
        }}
      >
        {selectedCompany ? selectedCompany.name : "Select company"}
      </Button>

      {error && (
        <FormControl error fullWidth>
          <FormHelperText sx={{ margin: 0, lineHeight: "15px" }}>{error.message}</FormHelperText>
        </FormControl>
      )}

      {isAutocompleteDialogOpen && (
        <VBillSuggestionsSingleSelectAutocompleteComp
          suggestionsComponent="dialog"
          suggestions={autocompleteSuggestions}
          title="Select company"
          onSuggestionClick={handleAutocompleteSuggestionClick}
          onClose={() => setIsAutocompleteDialogOpen(false)}
          searchTerm={autocompleteSearchTerm}
          onSearchTermChange={setAutocompleteSearchTerm}
          searchPlaceholder="Search company"
        />
      )}
    </Box>
  );
});
